import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Menu from "../menu";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Typography } from "@mui/material";
import { useParams } from "react-router";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    background: theme.palette.grey.A200,
    minWidth: "100vw",
    width: "100%",
    height: "100vh",
  },
}));

export default function ErrorPage(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const classes = useStyles();
  const { state, code } = props;
  const { estate, ecode } = useParams();

  return (
    <Grid container display="flex" flexDirection="row" className={classes.root}>
      <Grid item container display="flex" flexDirection="column" sx={{ maxWidth: 75, background: "background.default" }}>
        <Menu />
      </Grid>
      <Grid container item m="auto" display="inline-flex" justifyContent="center" sx={{ color: "grey.A700"}} xs={11}>
        <SentimentVeryDissatisfiedIcon
          sx={{
            color: "grey.A700",
            height: 300,
            maxHeight: 300,
            minWidth: 450,
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography component="span" sx={{ fontSize: 140, maxHeight:165}}>
            {(state?state:estate)}
          </Typography>
          <Typography component="span" sx={{ fontSize: 30,  maxWidth:450 }}>
            {t("httpState."+(state?state:estate))}
          </Typography>
          <Typography component="span" sx={{ fontSize: 20,  maxWidth:450 }}>
            {(code?t("errorPage.code")+code:ecode?t("errorPage.code")+ecode:"")}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

