/* eslint-disable no-throw-literal */
import { doc, getDocs, collection, getDoc, updateDoc, query, where, addDoc } from "firebase/firestore";
import { db } from "..";
import { convertStringToDate } from "./actions";

export const updateContractAction = async (contract) => {
  const custRef = doc(db, "customers", contract.customerId);
  const ref = doc(db, "contracts", contract.id);
  delete contract.id;
  contract.customerId = custRef;
  await updateDoc(ref, contract);
};

export const createContractAction = async (contract) => {
  let latestContract = await getLatestContractAction(contract.customerId); 
  if(latestContract){

    let latestEndDate = convertStringToDate(latestContract.data().endDate);
    let actualStartDate = convertStringToDate(contract.startDate);
    let actualEndDate = convertStringToDate(contract.endDate);
    if(latestEndDate && actualStartDate<=latestEndDate){
      throw {gulyaError: { errorCode: "contract.overlapWithLatestError", errorSpecificData: {latestEnd: latestEndDate} }};
    } else if(actualEndDate && actualStartDate>=actualEndDate){
      throw {gulyaError: { errorCode: "contract.startAfterEndError", errorSpecificData: {} }};
    } else if(!latestEndDate){
      throw {gulyaError: { errorCode: "contract.openPeriodError", errorSpecificData: {} }};
    }
  }
  const custRef = doc(db, "customers", contract.customerId);
  const ref = collection(db, "contracts");
  delete contract.id;
  contract.customerId = custRef;
  await addDoc(ref, contract);
};

export const getContractByIdAction = async (id) => {
  const ref = doc(db, "contracts", id);
  const contract = await getDoc(ref);
  if (contract.exists()) {
    let data = contract.data();
    data.id = contract.id;
    return data;
  }
  return null;
};

export const getAllContractsAction = async () => {
  let queryResultArray = [];
  const queryResult = await getDocs(collection(db, "contracts"));
  queryResult.forEach((contract) => {
    let result = contract.data();
    result.id = contract.id;
    result.customerId = contract.data().customerId.id;
    queryResultArray.push(result);
  });
  return queryResultArray;
};

export const getContractsStatus = async (customerId) => {
  let isActive = false;
  let isSuspended = false;
  const queryResult = await getAllContractsOfCustomerAction(customerId);
  queryResult.forEach((contract) => {
    let startDate = convertStringToDate(contract.data().startDate);
    let endDate = convertStringToDate(contract.data().endDate);
    let today = new Date();
    if (today >= startDate && (today <= endDate || !endDate)) {
      isActive = true;
      if (contract.data().isSuspended) {
        isSuspended = true;
      }
    }
  });
  return isSuspended ? 2 : isActive ? 0 : 1;
  //0 active - 1 inactive - 2 suspended
};

const getLatestContractAction = async (customerId) => {
  const queryResult = await getAllContractsOfCustomerAction(customerId);
  let result = null;
  let found = false;
  queryResult.forEach((contract) => {
    if (found) {
      return;
    }
    let endDate = convertStringToDate(contract.data().endDate);
    if (endDate == null) {
      result = contract;
      found = true;
    }

    if (result == null) {
      result = contract;
    } else {
      let resultEndDate = convertStringToDate(result.data().endDate);
      if(resultEndDate < endDate){
        result = contract;
      }
    }
  });
  return result
};

const getAllContractsOfCustomerAction = async (customerId) => {
  const custRef = doc(db, "customers", customerId);
  const ref = collection(db, "contracts");
  const q = query(ref, where("customerId", "==", custRef));
  return await getDocs(q);
};
