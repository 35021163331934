import { functions } from "..";
import { httpsCallable } from "firebase/functions";
import { getAllCustomersAction } from "./customerActions";

export const changeCustomerOfCurrentAdminAction = async (id, customerId) => {
  const setAdminCustomer = httpsCallable(functions, "setAdminCustomer");
  await setAdminCustomer({ id: id, customerId: customerId, admin: true });
};

export const updateUserAction = async (userInfo) => {
  const updateUser = httpsCallable(functions, "updateUser");
  await updateUser(userInfo);
};

export const suspendUserAction = async (userInfo) => {
  const suspendAccount = httpsCallable(functions, "suspendAccount");
  await suspendAccount({ id: userInfo.id });
};

export const reinstateUserAction = async (userInfo) => {
  const reinstateAccount = httpsCallable(functions, "reinstateAccount");
  await reinstateAccount({ id: userInfo.id });
};

export const updateEmailVerifiedAction = async (uid, isVerified) => {
  const updateEmailVerified = httpsCallable(functions, "updateEmailVerified");
  await updateEmailVerified({ id: uid, isVerified: isVerified });
};

export const resetPasswordToDefaultAction = async (uid) => {
  const resetPasswordToDefault = httpsCallable(functions, "resetPasswordToDefault");
  await resetPasswordToDefault({ id: uid });
  await updateEmailVerifiedAction(uid, false);
};

export const createUserAction = async (user) => {
  if (user.isAdmin) {
    let customers = await getAllCustomersAction();
    if (customers.length !== 0) {
      user.customerId = customers[0].id;
    }
  }
  const createUser = httpsCallable(functions, "createUser");
  let result = await createUser(user);
  return result.data;
};

export const getAllUsersAction = async () => {
  const allUsers = httpsCallable(functions, "getAllUsers");
  let queryResult = await allUsers();
  return queryResult.data;
};

export const getAllAdminsAction = async () => {
  const allAdmins = httpsCallable(functions, "getAllAdmins");
  let queryResult = await allAdmins();
  return queryResult.data;
};

export const getActiveUserCount = async (customerId) => {
  let allUsers = await getAllUsersAction();
  let count = 0;
  allUsers.forEach((user) => {
    if (user.customerId === customerId && !user.isAdmin && !user.isSuspended) {
      count++;
    }
  });
  return count;
};

export const deleteUserAction = async (user) => {
  if (user.isAdmin) {
    const deleteAdmin = httpsCallable(functions, "deleteAdmin");
    await deleteAdmin(user.id);
  } else {
    const deleteUser = httpsCallable(functions, "deleteUser");
    await deleteUser(user.id);
  }
  return;
};

export const kickUserOutAction = async (userId) => {
  const kickUserOut = httpsCallable(functions, "kickUserOut");
  await kickUserOut(userId);
  return;
};

export const getUserByIdAction = async (userId) => {
  const getUserById = httpsCallable(functions, "getUserById");
  let result = await getUserById(userId);
  return result.data;
};
