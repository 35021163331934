import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import Menu from "../common/menu";
import FilterList from "../common/filterList";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GenericButtonIcon from "../common/genericButtonIcon";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { getAllObjectsOfEntityAction } from "../../actions/actions";
import { Backdrop } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from "./map";
import { Typography, Box } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useMediaQuery } from "@mui/material";
import DetailsErrorPage from "../common/pages/detailsErrorPage";

export default function MapPage(props) {
  const { entityName, pageTitle, addLabel, addUrl, searchLabel, detailButton } = props;
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const { id } = useParams();

  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selected, setSelected] = useState();
  const [filterText, setFilterText] = useState("");

  const [allObjects, setAllobjects] = useState([]);
  const [isBackdropOpen, setBackdropOpen] = useState(true);
  const [mobileModeShowDetails, toggleMobileModeShowDetails] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: "center",
      fontFamily: ["Futura"].join(","),
      minHeight: "100vh",
      maxWidth: "100vw",
      backgroundColor: theme.palette.background.default,
    },
    listView: {
      background: theme.palette.grey.A100,
      paddingRight: "1px",
      paddingLeft: "1px",
      minHeight: isSmallScreen || isMediumScreen ? "calc(100vh - 38px)" : "100vh",
      width: "100%",
    },
    mainView: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey.A200,
    },
  }));
  const classes = useStyles();

  const handleSelectionChange = (node, index, triggeredByInit) => {
    if (!triggeredByInit) {
      toggleMobileModeShowDetails(true);
    }
    setSelectedIndex(index);
    setSelected(node);
    navigate("/" + entityName + "/" + node.id);
  };

  const load = () => {
    getAllObjectsOfEntityAction(entityName)
      .then((result) => {
        setAllobjects(result);
        setBackdropOpen(false);
      })
      .catch((e) => {
        navigate("/error/500/MAP-000-" + entityName.toUpperCase());
      });
  };

  useEffect(() => {
    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilteredItems = () => {
    return allObjects.filter((node, i) => {
      return node.name.toLowerCase().includes(filterText.toLowerCase());
    });
  };

  useEffect(() => {
    if (!id && allObjects.length !== 0) {
      handleSelectionChange(allObjects[0], 0, true);
    }

    if (id && allObjects.length !== 0) {
      let index = 0;
      let results = [];
      getFilteredItems().forEach((node, i) => {
        if (node.id === id) {
          index = i;
          results.push(node);
        }
      });
      if (results.length === 1) {
        handleSelectionChange(results[0], index, true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allObjects]);

  useEffect(() => {
    document.title = "GULYA.CH - " + t(pageTitle) + (selected  && selected.name ? " (" + selected.name + ")" : "");
  }, [pageTitle, t, selected]);

  const handleFilterChange = (filterText) => {
    setFilterText(filterText);
  };

  const handleReload = (...targets) => {
    setBackdropOpen(true);
    load();
  };

  const generateListElements = () => {
    let filteredItems = getFilteredItems();

    if (filteredItems.length === 0) {
      return "";
    }

    //injecting detail button with internal props
    let result = filteredItems.map((node, index) => {
      return React.cloneElement(detailButton, {
        key: node.id,
        data: node,
        index: index,
        selected: selectedIndex === index,
        onClick: (node, index) => handleSelectionChange(node, index),
        reload: (...targets) => handleReload(...targets),
      });
    });
    return result;
  };

  const renderWrapper = (status) => {
    switch (status) {
      case Status.LOADING:
        return "loading";
      case Status.FAILURE:
        return "error";
      case Status.SUCCESS:
        return <Map />;
      default:
        return <DetailsErrorPage />
    }
  };
  return (
    <Grid container display="flex" flexDirection="row" className={classes.root}>
      <Grid item container display="flex" flexDirection="column" xs={12} sm={12} md={4} lg={3} xl={3}>
        <Box
          flexDirection="column"
          sx={{
            height: 36,
            display: { xs: mobileModeShowDetails ? "none" : "flex", md: "none" },
            backgroundColor: "primary.main",
            mb: "1px",
            mr: "1px",
            color: "text.primary",
          }}
        >
          <Typography component={"span"} variant="h6" gutterBottom sx={{ mt: "3px" }}>
            {t(pageTitle)}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          sx={{ width: "100%", display: { xs: mobileModeShowDetails ? "none" : "flex", md: "flex" } }}
        >
          <Box display="flex" flexDirection="column" sx={{ maxWidth: 75 }}>
            <Menu />
          </Box>
          <Box display="flex" flexDirection="column" className={classes.listView}>
            <GenericButtonIcon buttonText={t(addLabel)} buttonNav={addUrl} orientation="left" height="small">
              <AddCircleIcon />
            </GenericButtonIcon>
            <Divider />
            <FilterList onSelectionChange={handleSelectionChange} onFilterChange={handleFilterChange} searchLabel={t(searchLabel)}>
              {generateListElements()}
            </FilterList>
          </Box>
        </Box>
      </Grid>
      <Grid item container display="flex" flexDirection="column" xs={12} sm={12} md={8} lg={9} xl={9} className={classes.mainView}>
        <Box sx={{ display: { xs: mobileModeShowDetails ? "block" : "none", md: "block" } }}>
          <Grid item container display="flex" flexDirection="column" sx={{ minHeight: "100vh", width: "100%" }}>
            <Wrapper apiKey={"AIzaSyA_l-4XOu3vtXSlDxJc3BargP7YiEmi5SY"} render={renderWrapper}>
              <Map center={{ lat: 47.3769, lng: 8.5417 }} zoom={11} height="100vh" />
            </Wrapper>
            <Box sx={{ display: { xs: mobileModeShowDetails ? "block" : "none", md: "none" } }}>
              <GenericButtonIcon
                buttonText={t("mobileMode.backToList")}
                onClick={(event) => {
                  toggleMobileModeShowDetails(false);
                  window.scrollTo(0, 0);
                }}
                orientation="left"
                color="success"
              >
                <MenuOpenIcon />
              </GenericButtonIcon>
            </Box>
            <Backdrop sx={{ color: "text.primary", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isBackdropOpen}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
