import * as React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import SingleRowDetailIconButton from "../common/detailViewComponents/singleRowDetailIconButton";
import DetailViewBottomBar from "../common/bottomBars/detailViewBottomBar";
import DetailsErrorPage from "../common/pages/detailsErrorPage";
import ConfirmationDialog from "../common/dialogs/confirmationDialog";
import { useState } from "react";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import MultiRowDetailIconButton from "../common/detailViewComponents/multiRowDetailIconButton";
import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import TextSnippetSharpIcon from "@mui/icons-material/TextSnippetSharp";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import WarningIcon from "@mui/icons-material/Warning";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { getInvoicesStatus } from "../../actions/invoiceActions";
import { getActiveUserCount } from "../../actions/userInfoActions";
import { getContractsStatus } from "../../actions/contractActions";
import { Grid } from "@mui/material";

export default function CustomerDetails(props) {
  const { data, mobileModeShowDetails } = props;
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [invoicesStatus, setInvoicesStatus] = useState(0);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [contractsStatus, setContractsStatus] = useState(0);

  useEffect(() => {
    getInvoicesStatus(data.id).then((result) => setInvoicesStatus(result));
    getActiveUserCount(data.id).then((result) => setActiveUserCount(result));
    getContractsStatus(data.id).then((result) => setContractsStatus(result));
  }, [data]);

  const getDeleteConfirmation = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  if (!data) {
    return <DetailsErrorPage />;
  }

  const renderOwner = () => {
    if (!data.owner) {
      return "";
    }

    let areAllFieldsNull =
      (data.owner.lastName == null || data.owner.lastName === "") &&
      (data.owner.firstName == null || data.owner.firstName === "") &&
      (data.owner.email == null || data.owner.email === "") &&
      (data.owner.phone == null || data.owner.phone === "");

    if (areAllFieldsNull) {
      return "";
    }

    return (
      <MultiRowDetailIconButton icon={<SettingsAccessibilityIcon />}>
        {[(data.owner.lastName + "").toUpperCase() + " " + data.owner.firstName, data.owner.email, data.owner.phone]}
      </MultiRowDetailIconButton>
    );
  };

  const renderContactPerson = () => {
    if (!data.contactPerson) {
      return "";
    }

    return (
      <MultiRowDetailIconButton icon={<CallIcon />}>
        {[
          (data.contactPerson.lastName + "").toUpperCase() + " " + data.contactPerson.firstName,
          data.contactPerson.email,
          data.contactPerson.phone,
        ]}
      </MultiRowDetailIconButton>
    );
  };

  const renderCompanyAddress = () => {
    if (!data.companyAddress || data.companyAddress.caSameAsMa) {
      return "";
    }

    return (
      <MultiRowDetailIconButton icon={<HomeIcon />}>
        {[
          data.companyAddress.street + " " + data.companyAddress.number,
          data.companyAddress.zip + " " + data.companyAddress.city,
          t(data.companyAddress.countryCode),
        ]}
      </MultiRowDetailIconButton>
    );
  };

  const renderMailingAddress = () => {
    if (!data.mailingAddress) {
      return "";
    }

    return (
      <MultiRowDetailIconButton icon={<MailIcon />}>
        {[
          data.mailingAddress.addressLine1,
          data.mailingAddress.addressline2,
          data.mailingAddress.street + " " + data.mailingAddress.number,
          data.mailingAddress.zip + " " + data.mailingAddress.city,
          t(data.mailingAddress.countryCode),
        ]}
      </MultiRowDetailIconButton>
    );
  };

  return (
    <React.Fragment key={"CDET"}>
      <Typography component={"span"} sx={{ fontSize: 30, color: "text.primary", textAlign: "left", ml: 1, mb: 0 }}>
        {t("customerDetails.subTitle")}
      </Typography>
      <Typography component={"span"} sx={{ fontSize: 16, color: "error.light", textAlign: "left", ml: 1, mb: 1 }}>
        {invoicesStatus === 3 ? (
          <WarningIcon sx={{ mr: 1, fontSize: 18, verticalAlign: "middle" }} />
        ) : (
          <Box sx={{ height: 24, display: "block" }} />
        )}
        {invoicesStatus === 3 ? t("customerDetails.unpaidInvoiceWarning") : ""}
      </Typography>
      <Grid item container display="flex" flexDirection="row" sx={{maxHeight:mobileModeShowDetails?"calc(100vh - 75px - 172px)":"calc(100vh - 75px - 60px)", overflowY: "auto"}} xs={12}>
        <SingleRowDetailIconButton text={data.name}>
          <AssignmentIndIcon />
        </SingleRowDetailIconButton>
        {renderOwner()}
        {renderContactPerson()}
        {renderCompanyAddress()}
        {renderMailingAddress()}
        <SingleRowDetailIconButton
          buttonText={t("customerDetails.manageContracts")}
          buttonNav={data.id ? "/customers/contracts/" + data.id : "/customers/contracts/"}
          buttonIcon={<ArrowCircleRightIcon />}
          isPassive={contractsStatus === 0 ? false : true}
          text={
            contractsStatus === 1
              ? t("customerDetails.notActive")
              : contractsStatus === 2
              ? t("customerDetails.suspended")
              : t("customerDetails.active")
          }
        >
          <TextSnippetSharpIcon />
        </SingleRowDetailIconButton>
        <SingleRowDetailIconButton
          buttonText={t("customerDetails.goToUsers")}
          buttonNav={data.id ? "/customers/users/" + data.id : "/customers/users/"}
          buttonIcon={<ArrowCircleRightIcon />}
          isPassive={activeUserCount === 0 ? true : false}
          text={
            activeUserCount === 0
              ? t("customerDetails.noUsers")
              : activeUserCount === 1
              ? activeUserCount + t("customerDetails.singularUserNo")
              : activeUserCount + t("customerDetails.pluralUserNo")
          }
        >
          <ContactPageIcon />
        </SingleRowDetailIconButton>
        <SingleRowDetailIconButton
          buttonText={t("customerDetails.goToBills")}
          buttonNav={data.id ? "/invoices/" + data.id : "/invoices/"}
          buttonIcon={<ArrowCircleRightIcon />}
          isPassive={!invoicesStatus || invoicesStatus === 0 ? true : false}
          text={
            invoicesStatus === 0
              ? t("customerDetails.noInvoices")
              : invoicesStatus === 1
              ? t("customerDetails.noOpenInvoices")
              : invoicesStatus === 2
              ? t("customerDetails.hasOpenInvoices")
              : t("customerDetails.hasUnpaidInvoices")
          }
        >
          <MonetizationOnIcon />
        </SingleRowDetailIconButton>
      </Grid>
      <DetailViewBottomBar
        deleteText={t("customerDetails.delete")}
        onDeleteClick={getDeleteConfirmation}
        editText={t("customerDetails.edit")}
        editNav={"/edit/customer/" + data.id}
      />
      <ConfirmationDialog
        title={t("customerDetails.confirmDeleteTitle")}
        text={t("customerDetails.confirmDeleteText")}
        open={isConfirmDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={() => props.onDelete()}
      />
    </React.Fragment>
  );
}
