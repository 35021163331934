import * as React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SingleRowDetailIconColorStripe from "../common/detailViewComponents/singleRowDetailIconColorStripe";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import FilterList from "../common/filterList";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GenericButtonIcon from "../common/genericButtonIcon";
import GroupMembersListButton from "./groupMembersListButton";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GroupMemberAddDialog from "./groupMemberAddDialog";
import { useNavigate } from "react-router-dom";
import AssignmentViewBottomBar from "../common/bottomBars/assignmentViewBottomBar";
import { unassignGroupMemberAction } from "../../actions/groupActions";

export default function GroupMembersDetails(props) {
  const { data, reload } = props;
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [filterText, setFilterText] = useState("");

  let beaconCount = 0;

  if (data.members) {
    let beaconsFound = data.members.filter((node, i) => {
      return node.hasBeacon === true;
    });
    beaconCount = beaconsFound.length;
  }

  const handleFilterChange = (filterText) => {
    setFilterText(filterText);
  };

  const getFilteredMembers = () => {
    if (!data.members) {
      return [];
    }
    return data.members.filter((node, i) => {
      return node.name.toLowerCase().includes(filterText.toLowerCase());
    });
  };

  const removeGroupMember = (member) => {
    unassignGroupMemberAction(member.id)
      .then(() => {
        reload();
      })
      .catch((e) => {
        navigate("/error/500/GMD-000");
      });
  };

  const mapStructure = () => {
    let filteredItems = getFilteredMembers();

    if (filteredItems.length === 0) {
      return "";
    }

    return filteredItems.map((node, index) => (
      <GroupMembersListButton node={node} index={index} groupId={data.id} groupColor={data.color} onClick={() => removeGroupMember(node)} />
    ));
  };

  const handleAddMemberClick = (value) => {
    setDialogOpen(true);
  };

  const handleAddMemberDialogClose = (value) => {
    setDialogOpen(false);
  };

  return (
    <React.Fragment key={"GMDT"}>
      <Typography component={"span"} sx={{ fontSize: 30, color: "text.primary", textAlign: "left", ml: 1, mb: 0 }}>
        {t("groupMembersDetails.title")}
      </Typography>
      <Typography component={"span"} sx={{ fontSize: 16, color: "text.primary", textAlign: "left", ml: 1, mb: 1 }}>
        {beaconCount === 0 ? (
          <InfoOutlinedIcon sx={{ mr: 1, fontSize: 18, verticalAlign: "middle" }} />
        ) : (
          <NotificationsActiveIcon sx={{ mr: 1, fontSize: 18, verticalAlign: "middle" }} />
        )}
        {beaconCount === 0
          ? t("groupDetails.noBeacons")
          : beaconCount === 1
          ? beaconCount + t("groupDetails.oneBeacon")
          : beaconCount + t("groupDetails.multipleBeacons")}
      </Typography>
      <Divider key={"GMDTD1"}/>
      <SingleRowDetailIconColorStripe
        text={t("groupMembersDetails.groupPrefix") + (data ? data.name : "") + t("groupMembersDetails.groupSuffix")}
        stripeColor={data ? data.color : ""}
      >
        <GroupWorkIcon />
      </SingleRowDetailIconColorStripe>
      <Divider key={"GMDTD2"}/>

      <GenericButtonIcon
        buttonText={t("groupMembersDetails.addLabel")}
        onClick={(event) => handleAddMemberClick(event)}
        orientation="left"
        height="small"
      >
        <AddCircleIcon />
      </GenericButtonIcon>
      <Divider key={"GMDTD3"}/>
      <FilterList onFilterChange={handleFilterChange} searchLabel={t("groupMembersDetails.searchLabel")}>
        {mapStructure()}
      </FilterList>

      <AssignmentViewBottomBar navBack={"/groups/" + (data ? data.id : "")} />
      <GroupMemberAddDialog open={isDialogOpen} onClose={handleAddMemberDialogClose} group={data} reload={reload} />
    </React.Fragment>
  );
}
