import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  mainArea: {
    color: theme.palette.text.primary,
    background: theme.palette.grey.A200,
    minWidth: "100vw",
    width: "100%",
    minHeight: "100vh",
    height: "100%",
  },
}));

export default function Authentication(props) {
  const { target } = props;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const classes = useStyles();

  useEffect(() => {
    const timer = setTimeout(() => navigate("/login"), 30000);
    const auth = getAuth();
    const unlisten = onAuthStateChanged(auth, (user) => {
      if (user) {
        clearTimeout(timer);
        navigate(target);
      } else {
        clearTimeout(timer);
        navigate("/login");
      }
    });
    return () => {
      unlisten();
      clearTimeout(timer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection="row" className={classes.mainArea}>
      <Box flexGrow={1} m="auto" display="inline-flex" justifyContent="center" sx={{ color: "grey.A700" }}>
        <CircularProgress color="secondary" />
        <Typography component="span" sx={{ fontSize: 30, maxWidth: 450 }}>
          {t("auth.authenticating")}
        </Typography>
      </Box>
    </Box>
  );
}
