import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import GenericButtonIcon from "../genericButtonIcon";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from "../../map/map";
import PublicIcon from "@mui/icons-material/Public";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LandscapeIcon from "@mui/icons-material/Landscape";
import IconButton from "@mui/material/IconButton";
import { NavLink } from "react-router-dom";
import DetailsErrorPage from "../pages/detailsErrorPage";

const useStyles = makeStyles((theme) => ({
  listItemText: {
    color: theme.palette.text.primary,
    verticalAlign: "middle",
    display: "inline-flex",
    textAlign: "left",
  },
  listItemPassiveText: {
    color: theme.palette.text.disabled,
    verticalAlign: "middle",
    display: "inline-flex",
    textAlign: "left",
  },
  listItemStatusText: {
    color: theme.palette.text.primary,
    fontSize: 12,
    verticalAlign: "middle",
    display: "inline-flex",
  },
  listItemPassiveStatusText: {
    color: theme.palette.text.disabled,
    fontSize: 12,
    verticalAlign: "middle",
    display: "inline-flex",
  },
}));

export default function DoubleRowDetailIconButtonMap(props) {
  const classes = useStyles();
  const { locationData } = props;
  const { t, i18n } = useTranslation(); // eslint-disable-line

  const renderWrapper = (status) => {
    switch (status) {
      case Status.LOADING:
        return "loading";
      case Status.FAILURE:
        return "error";
      case Status.SUCCESS:
        return <Map />;
      default:
        return <DetailsErrorPage />;
    }
  };

  const getMap = () => {
    if (!locationData) {
      return "";
    }
    return (
      <React.Fragment key={"MAP"}>
        <Grid item xs={12} sx={{ mb: 2, paddingRight: 2, paddingLeft: 2 }}>   
        <Wrapper apiKey={"AIzaSyA_l-4XOu3vtXSlDxJc3BargP7YiEmi5SY"} render={renderWrapper}>
            <Map center={{ lat: 47.3769, lng: 8.5417 }} zoom={11} height={300} />
          </Wrapper>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      sx={{ backgroundColor: "grey.A400", border: 1, borderColor: "grey.A200" }}
      key={Math.floor(Math.random() * 100000)}
    >
      <Grid item xs={2} sm={1}>
        <PublicIcon sx={{ width: 56, height: 56, fontSize: 56, mt: 2, mb: 2 }} />
      </Grid>
      <Grid container item xs={8} sm={5} sx={{ mt: 1, mb: 1 }}>
        <Grid container item display="flex" flexDirection="column" spacing={0} sx={{ width: "100%", textAlign: "left" }}>
          <Box>
            <Typography
              component={"span"}
              className={!locationData ? classes.listItemPassiveText : classes.listItemText}
              variant="h6"
              gutterBottom
            >
              {!locationData ? t("doubleRowDetailIconButtonMap.noLocation") : t("doubleRowDetailIconButtonMap.location")}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <Grid container item xs={7}>
              <Typography
                align="left"
                component={"span"}
                className={!locationData ? classes.listItemPassiveStatusText : classes.listItemStatusText}
              >
                <Box sx={{ mr: 1 }}>{!locationData ? "" : <MyLocationIcon />}</Box>
                {!locationData
                  ? ""
                  : locationData.latitude +
                    "° " +
                    (locationData.latitude > 0 ? t("latitude.north") : t("latitude.south")) +
                    ", " +
                    locationData.longitude +
                    "° " +
                    (locationData.longitude > 0 ? t("longitude.east") : t("longitude.west"))}
              </Typography>
            </Grid>
            <Grid container item xs={5} sx={{ textAlign: "left" }}>
              <Typography
                align="left"
                component={"span"}
                className={!locationData ? classes.listItemPassiveStatusText : classes.listItemStatusText}
              >
                <Box sx={{ mr: 1 }}>{!locationData ? "" : <LandscapeIcon />}</Box>
                {!locationData ? "" : locationData.altitude + " m"}
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={0} sm={1}></Grid>
      <Grid item xs={1} sm={5} sx={{ paddingRight: 2 }}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <GenericButtonIcon buttonText={t("doubleRowDetailIconButtonMap.goToMaps")} buttonNav={"/map/"} orientation="right">
            <ArrowCircleRightIcon />
          </GenericButtonIcon>
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          <IconButton component={NavLink} to={"/map/"}>
            <ArrowCircleRightIcon style={{ width: 36, height: 36, fontSize: 36 }} />
          </IconButton>
        </Box>
      </Grid>
      {getMap()}
    </Grid>
  );
}
