import * as React from "react";
import Grid from "@mui/material/Grid";
import GenericButtonIcon from "../genericButtonIcon";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";

export default function EditViewBottomBar(props) {
  const { background } = props;
  return (
    <Grid container spacing={0} sx={{ width: "100%", mt: "auto", backgroundColor: background?background:"grey.A200" }}>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={12} md={5} sx={{mb:"1px"}}>
        {props.onAbortClick ? (
          <GenericButtonIcon 
            buttonText={props.abortText} 
            onClick={(event) => props.onAbortClick(event)} 
            orientation="left">
            <CancelIcon />
          </GenericButtonIcon>
        ) : (
          <GenericButtonIcon 
            buttonText={props.abortText} 
            buttonNav={props.abortNav} 
            orientation="left">
            <CancelIcon />
          </GenericButtonIcon>
        )}
      </Grid>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={12} md={5} sx={{mb:"1px"}}>
        {props.onSaveClick ? (
          <GenericButtonIcon
            buttonText={props.saveText}
            onClick={(event) => props.onSaveClick(event)}
            orientation="left"
            color="primary"
            disabled={props.isSaveDisabled}
          >
            <SaveIcon />
          </GenericButtonIcon>
        ) : (
          <GenericButtonIcon
            buttonText={props.saveText}
            buttonNav={props.saveNav}
            orientation="left"
            color="primary"
            disabled={props.isSaveDisabled}
            type="submit"
          >
            <SaveIcon />
          </GenericButtonIcon>
        )}
      </Grid>
    </Grid>
  );
}
