import * as React from "react";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import Login from "./components/login/login";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import GenericEditMode from "./components/common/pages/genericEditMode";
import { getCustomerDataMap } from "./components/customers/customerDataMap";
import { getAnimalDataMap } from "./components/animals/animalDataMap";
import { getGroupDataMap } from "./components/groups/groupDataMap";
import GroupMembersDetails from "./components/groupMembers/groupMembersDetails";
import GroupDetails from "./components/groups/groupDetails";
import GroupDetailButton from "./components/groups/groupDetailButton";
import CustomerDetails from "./components/customers/customerDetails";
import CustomerDetailButton from "./components/customers/customerDetailButton";
import GenericTablePage from "./components/common/pages/genericTablePage";
import AnimalDetails from "./components/animals/animalDetails";
import AnimalDetailButton from "./components/animals/animalDetailButton";
import MapPage from "./components/map/mapPage";
import GenericMainPage from "./components/common/pages/genericMainPage";
import { themeDark, themeBright } from "./theme";
import ErrorPage from "./components/common/pages/errorPage";
import { getContractDataMap, getContractStatus } from "./components/contracts/contractDataMap";
import { getDeviceDataMap } from "./components/devices/deviceDataMap";
import { getInvoiceDataMap } from "./components/invoices/invoiceDataMap";
import { getUserDataMap, getUserStatus } from "./components/login/userDataMap";
import LoginError from "./components/login/loginError";
import { getInvoiceStatus } from "./components/invoices/invoiceDataMap";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { useEffect } from "react";
import Authentication from "./components/login/authentication";
import { useLocation } from "react-router";
import { getContractsStatus } from "./actions/contractActions";
import GenericPlainPage from "./components/common/pages/genericPlainPage";
import AdministrationDetailView from "./components/login/administrationDetailView";
import DeviceScannerDetailView from "./components/devices/deviceScannerDetailView";
import PwChangePage from "./components/login/pwChangePage";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useMediaQuery } from "@mui/material";

export default function App(props) {
  const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");
  const [authUser, setAuthUser] = useState(null);
  const [userClaim, setUserClaim] = useState(null);
  const [contractStatus, setContractStatus] = useState(null);

  useEffect(() => {
    const unlisten = onAuthStateChanged(getAuth(), (authUser) => {
      if (authUser) {
        setAuthUser(authUser);
        authUser
          .getIdTokenResult()
          .then((token) => {
            setUserClaim(token.claims);
            if (!token.claims.admin) {
              getContractsStatus(token.claims.customerId).then((result) => {
                setContractStatus(result);
              });
            }
          })
          .catch((e) => {
            return <ErrorPage state="500" code="APP-000" />;
          });
      } else {
        getAuth().signOut();
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  const PrivateRoute = (props) => {
    const { rolesAccepted } = props;
    const location = useLocation();

    if (!authUser) {
      return <Authentication target={location.pathname} />;
    }

    if (authUser.emailVerified === false && location.pathname !== "/change/password/") {
      return <Navigate to="/change/password/" />;
    }

    if (userClaim && !userClaim.admin && !rolesAccepted.includes("user")) {
      return <ErrorPage state="403" />;
    }

    if (userClaim) {
      if (contractStatus === 0 || (userClaim.admin && contractStatus !== 0)) {
        return <Outlet />;
      } else {
        if (contractStatus === 1) {
          getAuth().signOut();
          return <Navigate to="/login/fail/inactiveCustomer" />;
        } else if (contractStatus === 2) {
          getAuth().signOut();
          return <Navigate to="/login/fail/suspendedCustomer" />;
        }
      }
    }

    return <Outlet />;
  };

  return (
    <ThemeProvider theme={isDarkModeEnabled?createTheme(themeDark):createTheme(themeBright)}>
      <PayPalScriptProvider
        options={{
          "client-id": "test",
          components: "buttons",
          currency: "CHF",
          //          intent: "capture",
          //          "data-client-token": "abc123xyz==",
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/" element={<Animals />} />
            </Route>
            <Route path="/animals" element={<PrivateRoute rolesAccepted={["user", "admin"]} path="/animals" />}>
              <Route path="/animals" index element={<Animals />} />
            </Route>
            <Route path="/create/animal" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/create/animal" element={<AnimalEditMode />} />
            </Route>
            <Route path="/edit/animal/:id" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/edit/animal/:id" element={<AnimalEditMode />} render={(props) => <AnimalEditMode {...props} />} />
            </Route>
            <Route path="/animals/:id" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/animals/:id" element={<Animals />} render={(props) => <Animals {...props} />} />
            </Route>
            <Route path="/groups/members" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/groups/members" element={<GroupMembers />} />
            </Route>
            <Route path="/groups/members/:id" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/groups/members/:id" element={<GroupMembers />} render={(props) => <GroupMembers {...props} />} />
            </Route>
            <Route path="/groups" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/groups" element={<Groups />} />
            </Route>
            <Route path="/groups/:id" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/groups/:id" element={<Groups />} render={(props) => <Groups {...props} />} />
            </Route>
            <Route path="/create/customer" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/create/customer" element={<CustomerEditMode />} />
            </Route>
            <Route path="/edit/customer/:id" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/edit/customer/:id" element={<CustomerEditMode />} render={(props) => <CustomerEditMode {...props} />} />
            </Route>
            <Route path="/customers/contracts/:id" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/customers/contracts/:id" element={<CustomerContracts />} render={(props) => <CustomerContracts {...props} />} />
            </Route>
            <Route path="/customers/users/:id" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/customers/users/:id" element={<CustomerUsers />} render={(props) => <CustomerUsers {...props} />} />
            </Route>
            <Route path="/customers/:id" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/customers/:id" element={<Customers />} render={(props) => <Customers {...props} />} />
            </Route>
            <Route path="/customers" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/customers" element={<Customers />} />
            </Route>
            <Route path="/create/contract/:customerId" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route
                path="/create/contract/:customerId"
                element={<ContractEditMode />}
                render={(props) => <ContractEditMode {...props} />}
              />
            </Route>
            <Route path="/create/user/:customerId" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/create/user/:customerId" element={<UserEditMode />} render={(props) => <UserEditMode {...props} />} />
            </Route>
            <Route path="/create/admin/" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/create/admin/" element={<AdminEditMode />} render={(props) => <AdminEditMode {...props} />} />
            </Route>
            <Route path="/edit/contract/:id" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/edit/contract/:id" element={<ContractEditMode />} render={(props) => <ContractEditMode {...props} />} />
            </Route>
            <Route path="/edit/user/:id" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/edit/user/:id" element={<UserEditMode />} render={(props) => <UserEditMode {...props} />} />
            </Route>
            <Route path="/create/group" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/create/group" element={<GroupEditMode />} />
            </Route>
            <Route path="/edit/group/:id" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/edit/group/:id" element={<GroupEditMode />} render={(props) => <GroupEditMode {...props} />} />
            </Route>
            <Route path="/devices" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/devices" element={<Devices />} />
            </Route>
            <Route path="/devices/:id" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/devices/:id" element={<Devices />} render={(props) => <Devices {...props} />} />
            </Route>
            <Route path="/scan/devices/:id" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/scan/devices/:id" element={<DeviceScanner />} />
            </Route>
            <Route path="/invoices" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/invoices" element={<Invoices />} />
            </Route>
            <Route path="/invoices/:id" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/invoices/:id" element={<Invoices />} render={(props) => <Invoices {...props} />} />
            </Route>
            <Route path="/create/invoice/:customerId" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/create/invoice/:customerId" element={<InvoiceEditMode />} render={(props) => <InvoiceEditMode {...props} />} />
            </Route>
            <Route path="/edit/invoice/:id" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/edit/invoice/:id" element={<InvoiceEditMode />} render={(props) => <InvoiceEditMode {...props} />} />
            </Route>
            <Route path="/view/invoice/:id" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/view/invoice/:id" element={<InvoiceViewMode />} render={(props) => <InvoiceViewMode {...props} />} />
            </Route>
            <Route path="/map" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/map" element={<Map />} />
            </Route>
            <Route path="/map/:id" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/map/:id" element={<Map />} render={(props) => <Map {...props} />} />
            </Route>
            <Route path="/admin" element={<PrivateRoute rolesAccepted={["admin"]} />}>
              <Route path="/admin" element={<Administration />} />
            </Route>
            <Route path="/change/password/" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/change/password/" element={<PwChangePage />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/login/fail/:reason" element={<LoginError />} render={(props) => <LoginError {...props} />} />
            <Route path="/error/:estate/:ecode" element={<PrivateRoute rolesAccepted={["user", "admin"]} />}>
              <Route path="/error/:estate/:ecode" element={<ErrorPage />} render={(props) => <ErrorPage {...props} />} />
            </Route>
            <Route path="*" element={<PrivateRoute />}>
              <Route path="*" element={<ErrorPage state="404" />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </PayPalScriptProvider>
    </ThemeProvider>
  );
}

const Administration = (props) => (
  <GenericPlainPage pageTitle="administration.title" pageSubTitle="administration.subTitle" detailView={<AdministrationDetailView />} />
);

const CustomerEditMode = (props) => (
  <GenericEditMode
    dataMap={getCustomerDataMap}
    modifyTitle="customerEditMode.modifyTitle"
    createTitle="customerEditMode.createTitle"
    abortButtonText="customerEditMode.abort"
    saveButtonText="customerEditMode.saveAndClose"
    confirmAbortTitle="customerEditMode.confirmAbortTitle"
    confirmAbortText="customerEditMode.confirmAbortText"
    entityName="customers"
  />
);

const ContractEditMode = (props) => (
  <GenericEditMode
    dataMap={getContractDataMap}
    modifyTitle="contractEditMode.modifyTitle"
    createTitle="contractEditMode.createTitle"
    abortButtonText="contractEditMode.abort"
    saveButtonText="contractEditMode.saveAndClose"
    confirmAbortTitle="contractEditMode.confirmAbortTitle"
    confirmAbortText="contractEditMode.confirmAbortText"
    entityName="customers/contracts"
  />
);

const UserEditMode = (props) => (
  <GenericEditMode
    dataMap={getUserDataMap}
    modifyTitle="userEditMode.modifyTitle"
    createTitle="userEditMode.createTitle"
    abortButtonText="userEditMode.abort"
    saveButtonText="userEditMode.saveAndClose"
    confirmAbortTitle="userEditMode.confirmAbortTitle"
    confirmAbortText="userEditMode.confirmAbortText"
    entityName="customers/users"
  />
);

const AdminEditMode = (props) => (
  <GenericEditMode
    dataMap={getUserDataMap}
    modifyTitle="userEditMode.modifyTitle"
    createTitle="userEditMode.createTitle"
    abortButtonText="userEditMode.abort"
    saveButtonText="userEditMode.saveAndClose"
    confirmAbortTitle="userEditMode.confirmAbortTitle"
    confirmAbortText="userEditMode.confirmAbortText"
    entityName="admin"
  />
);

const InvoiceEditMode = (props) => (
  <GenericEditMode
    dataMap={getInvoiceDataMap}
    modifyTitle="invoiceEditMode.modifyTitle"
    createTitle="invoiceEditMode.createTitle"
    abortButtonText="invoiceEditMode.abort"
    saveButtonText="invoiceEditMode.saveAndClose"
    confirmAbortTitle="invoiceEditMode.confirmAbortTitle"
    confirmAbortText="invoiceEditMode.confirmAbortText"
    entityName="invoices"
  />
);

const InvoiceViewMode = (props) => (
  <GenericEditMode
    dataMap={getInvoiceDataMap}
    viewTitle="invoiceEditMode.viewTitle"
    modifyTitle="invoiceEditMode.modifyTitle"
    createTitle="invoiceEditMode.createTitle"
    abortButtonText="invoiceEditMode.abort"
    saveButtonText="invoiceEditMode.saveAndClose"
    confirmAbortTitle="invoiceEditMode.confirmAbortTitle"
    confirmAbortText="invoiceEditMode.confirmAbortText"
    entityName="invoices"
    viewMode="true"
    paymentBottomBar="true"
  />
);

const AnimalEditMode = (props) => (
  <GenericEditMode
    dataMap={getAnimalDataMap}
    modifyTitle="animalEditMode.modifyTitle"
    createTitle="animalEditMode.createTitle"
    abortButtonText="animalEditMode.abort"
    saveButtonText="animalEditMode.saveAndClose"
    confirmAbortTitle="animalEditMode.confirmAbortTitle"
    confirmAbortText="animalEditMode.confirmAbortText"
    entityName="animals"
  />
);

const GroupEditMode = (props) => (
  <GenericEditMode
    dataMap={getGroupDataMap}
    modifyTitle="groupEditMode.modifyTitle"
    createTitle="groupEditMode.createTitle"
    abortButtonText="groupEditMode.abort"
    saveButtonText="groupEditMode.saveAndClose"
    confirmAbortTitle="groupEditMode.confirmAbortTitle"
    confirmAbortText="groupEditMode.confirmAbortText"
    entityName="groups"
  />
);

const Groups = (props) => (
  <GenericMainPage
    pageTitle="groupDetails.title"
    pageSubTitle="groupDetails.subTitle"
    addLabel="groups.addLabel"
    addUrl="/create/group/"
    searchLabel="groups.searchLabel"
    detailButton={<GroupDetailButton />}
    detailView={<GroupDetails />}
    entityName="groups"
  />
);

const GroupMembers = (props) => (
  <GenericMainPage
    pageTitle="groupMembersDetails.title"
    pageSubTitle="groupMembersDetails.subTitle"
    addLabel="groupMembersDetails.addLabel"
    addUrl="/create/group/"
    searchLabel="groups.searchLabel"
    detailButton={<GroupDetailButton />}
    detailView={<GroupMembersDetails />}
    entityName="groups/members"
  />
);

const Customers = (props) => (
  <GenericMainPage
    pageTitle="customerDetails.title"
    pageSubTitle="customerDetails.subTitle"
    addLabel="customers.addLabel"
    addUrl="/create/customer/"
    searchLabel="customers.searchLabel"
    detailButton={<CustomerDetailButton />}
    detailView={<CustomerDetails />}
    entityName="customers"
  />
);

const CustomerContracts = (props) => (
  <GenericTablePage
    pageTitle="customerContracts.title"
    pageSubTitle="customerContracts.subTitle"
    addLabel="customers.addLabel"
    addUrl="/create/customer/"
    searchLabel="customers.searchLabel"
    detailButton={<CustomerDetailButton />}
    dataMap={getContractDataMap}
    goBackText="contracts.goBack"
    goBackNav="/customers/"
    manageText="contracts.add"
    manageNav="/create/contract/"
    filterEntityName="customers"
    tableEntityName="customers/contracts"
    getObjectStatus={(object) => getContractStatus(object)}
  />
);

const CustomerUsers = (props) => (
  <GenericTablePage
    pageTitle="customerUsers.title"
    pageSubTitle="customerUsers.subTitle"
    addLabel="customers.addLabel"
    addUrl="/create/customer/"
    searchLabel="customers.searchLabel"
    detailButton={<CustomerDetailButton />}
    dataMap={getUserDataMap}
    goBackText="contracts.goBack"
    goBackNav="/customers/"
    manageText="users.add"
    manageNav="/create/user/"
    filterEntityName="customers"
    tableEntityName="customers/users"
    getObjectStatus={(object) => getUserStatus(object)}
  />
);

const Devices = (props) => (
  <GenericTablePage
    pageTitle="devices.title"
    pageSubTitle="devices.subTitle"
    addLabel="customers.addLabel"
    addUrl="/create/customer/"
    searchLabel="customers.searchLabel"
    detailButton={<CustomerDetailButton />}
    dataMap={getDeviceDataMap}
    manageText="devices.addDevice"
    manageNav="/scan/devices/"
    filterEntityName="customers"
    tableEntityName="devices"
  />
);
const DeviceScanner = (props) => (
  <GenericPlainPage pageTitle="deviceScanner.title" pageSubTitle="deviceScanner.subTitle" detailView={<DeviceScannerDetailView />} />
);

const Invoices = (props) => (
  <GenericTablePage
    pageTitle="invoices.title"
    pageSubTitle="invoices.subTitle"
    addLabel="customers.addLabel"
    addUrl="/create/customer/"
    searchLabel="customers.searchLabel"
    detailButton={<CustomerDetailButton />}
    dataMap={getInvoiceDataMap}
    manageText="invoices.createInvoice"
    manageNav="/create/invoice/"
    filterEntityName="customers"
    tableEntityName="invoices"
    getObjectStatus={(object) => getInvoiceStatus(object)}
  />
);

const Animals = (props) => (
  <GenericMainPage
    pageTitle="animalDetails.title"
    pageSubTitle="animalDetails.subTitle"
    addLabel="animals.addLabel"
    addUrl="/create/animal/"
    searchLabel="animals.searchLabel"
    detailButton={<AnimalDetailButton />}
    detailView={<AnimalDetails />}
    entityName="animals"
  />
);

const Map = (props) => (
  <MapPage
    pageTitle="map.title"
    addLabel="animals.addLabel"
    addUrl="/create/animal/"
    searchLabel="animals.searchLabel"
    detailButton={<AnimalDetailButton />}
    entityName="map"
  />
);
