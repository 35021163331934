import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import { Button, Tooltip } from "@mui/material";
import { ReactComponent as GulyaLogo } from "../../assets/logo.svg";
import { ReactComponent as GulyaLogoBlack } from "../../assets/logoBlack.svg";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import PublicIcon from "@mui/icons-material/Public";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { NavLink } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 75,
    minHeight: 75,
  },
  iconStyle: {
    minWidth: 56,
    minHeight: 56,
    color: theme.palette.text.primary,
  },
}));

export default function Menu(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const classes = useStyles();
  const navigate = useNavigate();
  const [isAdmin, setAdmin] = useState(false);
  const [customerId, setCustomerId] = useState();
  const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleLogout = () => {
    getAuth()
      .signOut()
      .then(function () {
        navigate("/login");
      })
      .catch((e) => {
        navigate("/error/500/MNU-000");
      });
  };

  useEffect(() => {
    getAuth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        setAdmin(token.claims.admin);
        setCustomerId(token.claims.customerId);
      })
      .catch((e) => {
        navigate("/error/500/MNU-001");
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  return (
    <React.Fragment>
      <Grid item container display="flex" flexDirection="column">
        <Button
          component={NavLink}
          to={isAdmin ? "/admin" : "/"}
          className={classes.button}
          sx={{ backgroundColor: "primary.main" }}
          aria-label={t("menu.animals")}
        >
          {isDarkModeEnabled?<GulyaLogo width={75} height={75}/>:<GulyaLogoBlack width={75} height={75}/>}
        </Button>
        <Divider />
        {customerId ? (
          <React.Fragment>
            <Tooltip title={t("menu.animals")} placement="right">
              <Button component={NavLink} to={"/animals"} className={classes.button} variant="text" aria-label={t("menu.animals")}>
                <TripOriginIcon className={classes.iconStyle} />
              </Button>
            </Tooltip>
            <Divider />
            <Tooltip title={t("menu.groups")} placement="right">
              <Button component={NavLink} to={"/groups"} className={classes.button} variant="text" aria-label={t("menu.groups")}>
                <GroupWorkIcon className={classes.iconStyle} />
              </Button>
            </Tooltip>
            <Divider />
            <Tooltip title={t("menu.map")} placement="right">
              <Button component={NavLink} to={"/map"} className={classes.button} variant="text" aria-label={t("menu.map")}>
                <PublicIcon className={classes.iconStyle} />
              </Button>
            </Tooltip>
          </React.Fragment>
        ) : (
          ""
        )}
        {isAdmin ? (
          <React.Fragment>
            <Divider />
            <Tooltip title={t("menu.devices")} placement="right">
              <Button component={NavLink} to={"/devices"} className={classes.button} variant="text" aria-label={t("menu.devices")}>
                <CircleNotificationsRoundedIcon className={classes.iconStyle} />
              </Button>
            </Tooltip>
            <Divider />
            <Tooltip title={t("menu.customers")} placement="right">
              <Button component={NavLink} to={"/customers"} className={classes.button} variant="text" aria-label={t("menu.customers")}>
                <SupervisedUserCircleIcon className={classes.iconStyle} />
              </Button>
            </Tooltip>
          </React.Fragment>
        ) : (
          ""
        )}
        <Divider />
        <Tooltip title={t("menu.billing")} placement="right">
          <Button component={NavLink} to={"/invoices"} className={classes.button} variant="text" aria-label={t("menu.billing")}>
            <MonetizationOnRoundedIcon className={classes.iconStyle} />
          </Button>
        </Tooltip>
      </Grid>
      <Grid item display="flex" flexDirection="column" sx={{ mt: "auto", backgroundColor: "background.default" }}>
        <Button
          disableElevation
          sx={{ backgroundColor: "background.default", color:"text.primary", fontSize: 10, maxHeight: 20 }}
          variant="contained"
          onClick={() => changeLanguage("de")}
        >
          deutsch
        </Button>
        <Divider />
        <Button
          disableElevation
          sx={{ backgroundColor: "background.default", color:"text.primary", fontSize: 10, maxHeight: 20 }}
          variant="contained"
          onClick={() => changeLanguage("en")}
        >
          english
        </Button>
        <Divider />
        <Button
          disableElevation
          sx={{ backgroundColor: "background.default", color:"text.primary", fontSize: 10, maxHeight: 20 }}
          variant="contained"
          onClick={() => changeLanguage("hu")}
        >
          magyar
        </Button>
        <Divider />
        <Tooltip title={t("menu.logout")} placement="right">
          <Button className={classes.button} variant="text" aria-label={t("menu.logout")} onClick={() => handleLogout()}>
            <LogoutRoundedIcon className={classes.iconStyle} />
          </Button>
        </Tooltip>
      </Grid>
    </React.Fragment>
  );
}
