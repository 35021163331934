import * as React from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import GenericButtonIcon from "../genericButtonIcon";
import Grid from "@mui/material/Grid";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { DialogContent } from "@mui/material";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { Typography } from "@mui/material";
import { updateInvoiceAction } from "../../../actions/invoiceActions";
import { useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useMediaQuery } from "@mui/material";

export default function InvoicePaymentDialog(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const { onClose, open, invoice } = props;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const [isApproved, setApproved] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const calculateAmount = (table) => {
    let total = 0;
    if (table) {
      table.forEach((invoiceItem, index) => {
        total += invoiceItem.unitPrice * invoiceItem.quantity * (invoiceItem.vat + 1.0);
      });
      return isNaN(total) || total === 0 ? 0 : Math.round((total + Number.EPSILON) * 100) / 100;
    }
    return total;
  };

  const getFormattedAmount = (table) => {
    let amount = calculateAmount(table);
    return isNaN(amount) || amount === 0 ? "- CHF" : amount.toLocaleString(getLocale(), { minimumFractionDigits: 2 }) + " CHF";
  };

  const getLocale = () => {
    let lang = i18n.language || (typeof window !== "undefined" && window.localStorage.i18nextLng) || "DE";
    lang = lang.toUpperCase();
    if (lang === "EN") {
      return "en-US";
    }
    if (lang === "HU") {
      return "hu-HU";
    }
    return "de-CH";
  };

  // Custom component to wrap the PayPalButtons and handle currency changes
  const ButtonWrapper = ({ currency, invoice, showSpinner }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const amount = calculateAmount(invoice.table);

    useEffect(() => {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
          currency: currency,
        },
      });
    }, [currency, showSpinner]);

    return (
      <>
        {showSpinner && isPending && <div className="spinner" />}
        <PayPalButtons
          style={{ layout: "vertical" }}
          disabled={false}
          forceReRender={[amount, currency, { layout: "horizontal" }]}
          fundingSource={undefined}
          createOrder={async (data, actions) => {
            return actions.order
              .create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: currency,
                      value: amount,
                    },
                  },
                ],
              })
              .then((orderId) => {
                if (!invoice.payRemark) {
                  invoice.payRemark = { history: "" };
                }
                invoice.payRemark.history += "Order " + orderId + " created on " + new Date().toISOString() + "///";
                updateInvoiceAction(invoice);
                return orderId;
              });
          }}
          onApprove={async function (data, actions) {
            return actions.order.capture().then(function () {
              if (!invoice.payRemark) {
                invoice.payRemark = { history: "" };
              }
              invoice.payRemark.history += "Order " + data.orderID + " approved on " + new Date().toISOString() + "///";
              invoice.payRemark.approval = data;
              invoice.payDate = new Date().toISOString().split("T")[0];
              setApproved(true);
              updateInvoiceAction(invoice);
            });
          }}
        />
      </>
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen={isSmallScreen}
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
          color: "text.primary",
          boxShadow: "none",
          maxWidth: 800,
          width: 550,
          borderRadius: 0,
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={4} alignItems="center" justify="center">
          {isApproved ? (
           <>
           <Grid item xs={12} alignItems="center" justify="center">
             <Typography variant="h6" sx={{ textAlign: "center" }}>
             <CheckCircleIcon sx={{ width: 130, height: 130, fontSize: 130, mt: 2}}/>
             </Typography>
           </Grid>
           <Grid item xs={12} alignItems="center" justify="center">
           <Typography variant="h6" sx={{ textAlign: "center" }}>
               {t("invoice.paymentSuccesful")}
             </Typography>
           </Grid>
           <Grid item xs={12}>
             <GenericButtonIcon buttonText={t("invoice.paymentDialogClose")} buttonNav="/invoices/" orientation="left">
               <ArrowCircleLeftIcon />
             </GenericButtonIcon>
           </Grid>
         </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  {t("invoice.paymentDialogText")}
                </Typography>
              </Grid>
              <Grid item xs={12} alignItems="center" justify="center">
                <Typography variant="h2" sx={{ textAlign: "center", mt: 2, mb: 2 }}>
                  {getFormattedAmount(invoice.table)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ButtonWrapper currency={"CHF"} invoice={invoice} showSpinner={true} />
              </Grid>
              <Grid item xs={12}>
                <GenericButtonIcon buttonText={t("confirmDialog.close")} onClick={(event) => handleClose(event)} orientation="left">
                  <ArrowCircleLeftIcon />
                </GenericButtonIcon>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
