import * as React from "react";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as GulyaLogo } from "../../assets/logo.svg";
import { ReactComponent as GulyaLogoBlack } from "../../assets/logoBlack.svg";
import { SvgIcon } from "@mui/material";
import { useMediaQuery } from "@mui/material";

export default function GroupDetailButton(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const { data, index, selected, onClick } = props;
  const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");

  const getSecondary = () => {
    let memberCountText = "";
    let iconPrimary = (
      <SvgIcon>{isDarkModeEnabled ? <GulyaLogo width={20} height={20} /> : <GulyaLogoBlack width={20} height={20} />}</SvgIcon>
    );
    if (!data.members || data.members.length === 0) {
      memberCountText = t("groupDetails.noMembers");
      iconPrimary = <InfoOutlinedIcon fontSize="small" sx={{ mr: 1 }} />;
    } else if (data.members.length === 1) {
      memberCountText = data.members.length + " " + t("groupDetails.memberCountSigular");
    } else {
      memberCountText = data.members.length + " " + t("groupDetails.memberCountPlural");
    }

    return (
      <Typography component="span" variant="body2" sx={{ verticalAlign: "middle", display: "inline-flex", mr: 1 }}>
        {iconPrimary}
        {memberCountText}
      </Typography>
    );
  };

  return (
    <React.Fragment key={"GDB" + data.id}>
      <ListItem alignItems="flex-start" selected={selected} onClick={() => onClick(data, index)} key={data.id}>
        <ListItemAvatar>
          <Avatar
            alt={data.name}
            variant="square"
            sx={{
              backgroundColor: data.color,
            }}
          >
            {""}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={data.name} secondary={getSecondary()} />
      </ListItem>
      <Divider component="li" />
    </React.Fragment>
  );
}
