import * as React from "react";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";

export default function AllItemsDetailButton(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  let node = {
    id: "all",
  };

  return (
    <React.Fragment key={"AIDB"}>
      <ListItem selected={props.selected} onClick={(event) => props.onClick(node, props.index)} key={node.id} >
        <ListItemText
          primary={
            <Typography component="span" gutterBottom variant="h6" sx={{ mt:2 }}>
              {t("generic.allItems")}
            </Typography>
          }
        />
      </ListItem>
      <Divider component="li" key={node.id + "d"} />
    </React.Fragment>
  );
}
