import * as React from "react";
import Grid from "@mui/material/Grid";
import GenericButtonIcon from "../genericButtonIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export default function DetailViewBottomBar(props) {
  return (
    <Grid
      container
      spacing={0}
      sx={{ width: "100%", mt: "auto", backgroundColor: "grey.A400" }}
    >
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={12} md={5} sx={{mb:"1px"}}>
        <GenericButtonIcon
          buttonText={props.deleteText}
          onClick={(event) => props.onDeleteClick(event)}
          orientation="left"
        >
          <DeleteIcon />
        </GenericButtonIcon>
      </Grid>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={12} md={5} sx={{mb:"1px"}}>
        <GenericButtonIcon
          buttonText={props.editText}
          buttonNav={props.editNav}
          orientation="left"
          color="primary"
        >
          <EditIcon />
        </GenericButtonIcon>
      </Grid>
    </Grid>
  );
}
