import * as React from "react";
import Grid from "@mui/material/Grid";
import GenericButtonIcon from "../genericButtonIcon";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function TableViewBottomBar(props) {
  return (
    <Grid container spacing={0} sx={{ width: "100%", mt: "auto", backgroundColor: "grey.A400" }}>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={12} md={5} sx={{mb:"1px"}}>
        {props.onAbortClick ? (
          <GenericButtonIcon 
            buttonText={props.abortText} 
            onClick={(event) => props.onAbortClick(event)} 
            orientation="left">
            <ArrowCircleLeftIcon />
          </GenericButtonIcon>
        ) : (
          <GenericButtonIcon 
            buttonText={props.abortText} 
            buttonNav={props.abortNav} 
            orientation="left">
            <ArrowCircleLeftIcon />
          </GenericButtonIcon>
        )}
      </Grid>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={12} md={5} sx={{mb:"1px"}}>
        {props.onSaveClick ? (
          <GenericButtonIcon
            buttonText={props.saveText}
            onClick={(event) => props.onSaveClick(event)}
            orientation="left"
            color="primary"
            disabled={props.isSaveDisabled}
          >
            <AddCircleIcon />
          </GenericButtonIcon>
        ) : (
          <GenericButtonIcon
            buttonText={props.saveText}
            buttonNav={props.saveNav}
            orientation="left"
            color="primary"
            disabled={props.isSaveDisabled}
          >
            <AddCircleIcon />
          </GenericButtonIcon>
        )}
      </Grid>
    </Grid>
  );
}
