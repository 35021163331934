import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Menu from "../menu";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";


export default function GenericPlainPage(props) {
  const { pageTitle, pageSubTitle, detailView } = props;
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: "center",
      fontFamily: ["Futura"].join(","),
      minHeight: isSmallScreen || isMediumScreen ? "calc(100vh - 38px)" : "100vh",
      maxWidth: "100vw",
      backgroundColor: theme.palette.background.default,
    },
    mainView: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey.A200,
      minHeight: "100vh",
      width: "100%",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    document.title = "GULYA.CH - " + t(pageSubTitle);
  }, [pageSubTitle, t]);

  return (
    <Grid container display="flex" flexDirection="column" className={classes.root}>
      <Grid container item display="flex" flexDirection="column" xs={12} sm={12} md={12} lg={11} xl={9}>
        <Box
          flexDirection="column"
          sx={{
            height: 36,
            display: { xs: "flex", md: "none" },
            backgroundColor: "primary.main",
            mb: "1px",
            mr: "1px",
            color: "text.primary",
          }}
        >
          <Typography component={"span"} variant="h6" gutterBottom sx={{ mt: "3px" }}>
            {t(pageTitle)}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="column" sx={{ maxWidth: 75 }}>
            <Menu />
          </Box>
          <Box display="flex" flexDirection="column" className={classes.mainView}>
            <Typography component={"span"} sx={{ fontSize: 29, textAlign: "left", ml: 1, mb: 4 }}>
              {t(pageSubTitle)}
            </Typography>
            {detailView}
          </Box>
        </Box>
      </Grid>{" "}
    </Grid>
  );
}
