/* eslint-disable no-throw-literal */
import { doc, getDocs, collection, getDoc, updateDoc, deleteField, query, where, addDoc } from "firebase/firestore";
import { db } from "..";
import { getAnimalsByDeviceIdAction, getAnimalsByGroupIdAction } from "./animalActions";
import { getAuth } from "firebase/auth";

export const getAllGroupsAction = async () => {
  let queryResultArray = [];
  const token = await getAuth().currentUser.getIdTokenResult();
  const custRef = doc(db, "customers", token.claims.customerId);
  const ref = collection(db, "groups");
  const q = query(ref, where("customerId", "==", custRef));
  const queryResult = await getDocs(q);
  queryResult.forEach((group) => {
    let result = {
      id: group.id,
      name: group.data().name,
      color: group.data().color,
      customerId: group.data().customerId.id,
    };
    queryResultArray.push(result);
  });

  let resultWithMembers = [];
  await Promise.all(
    queryResultArray.map(async (group) => {
      let animals = await getAnimalsByGroupIdAction(group.id);
      if (animals.length !== 0) {
        group.members = animals;
      }
      resultWithMembers.push(group);
    })
  );
  return resultWithMembers;
};

export const getGroupByIdAction = async (id) => {
  const ref = doc(db, "groups", id);
  const group = await getDoc(ref);
  if (group.exists()) {
    const token = await getAuth().currentUser.getIdTokenResult();
    if (token.claims.customerId !== group.data().customerId.id) {
      throw "Invalid access to someone else's animal.";
    }
    return {
      id: group.id,
      name: group.data().name,
      color: group.data().color,
      customerId: group.data().customerId.id,
    };
  }
  return null;
};

export const assignGroupMemberAction = async (groupId, animalId) => {
  const ref = doc(db, "animals", animalId);
  const groupRef = doc(db, "groups", groupId);
  await updateDoc(ref, {
    groupId: groupRef,
  });
};

export const unassignGroupMemberAction = async (id) => {
  const ref = doc(db, "animals", id);
  await updateDoc(ref, {
    groupId: deleteField(),
  });
};

export const createGroupAction = async (group) => {
  const token = await getAuth().currentUser.getIdTokenResult();
  const custRef = doc(db, "customers", token.claims.customerId);
  const ref = collection(db, "groups");
  await addDoc(ref, {
    name: group.name,
    color: group.color,
    customerId: custRef,
  });
};

export const updateGroupAction = async (group) => {
  const token = await getAuth().currentUser.getIdTokenResult();
  if (token.claims.customerId !== group.customerId) {
    throw "Invalid access to someone else's animal.";
  }
  const ref = doc(db, "groups", group.id);
  await updateDoc(ref, {
    name: group.name,
    color: group.color,
  });
};

export const getGroupOfDeviceByIdAction = async (id) => {
  let animals = await getAnimalsByDeviceIdAction(id);
  if (animals.length !== 0) {
    if (animals[0].group) {
      return await getGroupByIdAction(animals[0].group.id);
    }
  }
  return null;
};
