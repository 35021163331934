import * as React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import GenericButtonIcon from "../genericButtonIcon";
import { DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import Typography from "@mui/material/Typography";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useMediaQuery } from "@mui/material";

export default function GoBackDialog(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const { onClose, open, onConfirm } = props;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onClose();
    onConfirm();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen={isSmallScreen}
      PaperProps={{
        style: {
          backgroundColor: "background.default",
          color: "text.primary",
          boxShadow: "none",
          maxWidth: 800,
          width: 550,
          borderRadius: 0,
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={4} alignItems="center" justify="center">
          <Grid item xs={0} sm={1}></Grid>
          <Grid item xs={12} sm={4}>
            <ContactSupportIcon sx={{ fontSize: 130 }} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Grid container>
              <Grid item xs={12}>
                <Typography component="span" variant="h4" sx={{ verticalAlign: "middle", display: "inline" }}>
                  {props.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="span" variant="body2" sx={{ verticalAlign: "middle", display: "inline" }}>
                  {props.text}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericButtonIcon
              buttonText={t("goBackDialog.close")}
              onClick={(event) => handleClose(event)}
              orientation="left"
              height="small"
            >
              <ArrowCircleLeftIcon />
            </GenericButtonIcon>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericButtonIcon
              buttonText={t("goBackDialog.confirm")}
              onClick={(event) => handleConfirm(event)}
              orientation="right"
              color="error"
              height="small"
            >
              <ArrowCircleRightIcon />
            </GenericButtonIcon>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
