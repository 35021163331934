import * as React from "react";
import { useTranslation } from "react-i18next";
import Divider from '@mui/material/Divider';
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Battery90Icon from "@mui/icons-material/Battery90";
import Battery80Icon from "@mui/icons-material/Battery80";
import Battery60Icon from "@mui/icons-material/Battery60";
import Battery50Icon from "@mui/icons-material/Battery50";
import Battery30Icon from "@mui/icons-material/Battery30";
import Battery20Icon from "@mui/icons-material/Battery20";
import BatteryAlertIcon from "@mui/icons-material/BatteryAlert";
import BatteryUnknownIcon from "@mui/icons-material/BatteryUnknown";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";


export default function DeviceDetailButton(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const { data, index, selected, onClick } = props;
  

  const getSecondary = () => {
      let iconPrimary = (<HelpOutlineIcon fontSize="small" sx={{mr:1}}/>);
      let statusTextPrimary =t("deviceDetailButton.noData");

      if(data){
        iconPrimary = getBatteryIcon();
        statusTextPrimary =  t("deviceDetailButton.batteryLevel") + data.batteryStatus + "%";
      }
      
      return (
        <Typography
          component="span"
          variant="body2"
          sx={{verticalAlign: 'middle',display: 'inline-flex'}}
        >
          {iconPrimary}
          {statusTextPrimary}
        </Typography>  
      )
   };

   const getBatteryIcon = () => {
    if (data.batteryStatus >= 100) {
      return <BatteryFullIcon />;
    } else if (data.batteryStatus >= 90) {
      return <Battery90Icon />;
    } else if (data.batteryStatus >= 80) {
      return <Battery80Icon />;
    } else if (data.batteryStatus >= 60) {
      return <Battery60Icon />;
    } else if (data.batteryStatus >= 50) {
      return <Battery50Icon />;
    } else if (data.batteryStatus >= 30) {
      return <Battery30Icon />;
    } else if (data.batteryStatus >= 20) {
      return <Battery20Icon />;
    } else if (data.batteryStatus >= 0) {
      return <BatteryAlertIcon />;
    }

    return <BatteryUnknownIcon />;
  };

   const getGroupcolor = () => {
    return data.group?data.group.color:null;
  };
  const formatDeviceName = (input) => {
    let result = "";
    for (let i = 0; i < input.length; i++) {
      if (i % 2 === 0 && i !== 0) {
        result += ":";
      }
      result += input[i];
    }
    return result.toUpperCase();
  };

  return (
    <React.Fragment key={"DDB"+data.id}>
      <ListItem
        alignItems="flex-start"
        selected={selected}
        onClick={(event) => onClick(data, index)}
        key={data.id}
      >
        <ListItemAvatar>
          <Avatar 
            alt={data.name} 
            variant="square"
            sx={{
              backgroundColor:getGroupcolor()
            }}
          >
            {data.isBeacon ? (
            <NotificationsActiveIcon />
          ) : (
            <NotificationsIcon />
          )}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={data.isBeacon
                ? t("deviceDetailButton.beacon") + formatDeviceName(data.name)
                : t("deviceDetailButton.node") + formatDeviceName(data.name)}
          secondary={getSecondary()}
        />
      </ListItem>
      <Divider component="li" key={data.id+"d"}/>
    </React.Fragment>
  );
}
