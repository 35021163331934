import * as React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import GenericButtonIcon from "../genericButtonIcon";
import IconButton from "@mui/material/IconButton";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  listItemText: {
    color: theme.palette.text.primary,
    verticalAlign: "middle",
    display: "inline-flex",
    textAlign: "left",
  },
  listItemPassiveText: {
    color: theme.palette.text.disabled,
    verticalAlign: "middle",
    display: "inline-flex",
    textAlign: "left",
  },
  listItemStatusText: {
    color: theme.palette.text.primary,
    fontSize: 12,
    verticalAlign: "middle",
    display: "inline-flex",
  },
  listItemPassiveStatusText: {
    color: theme.palette.text.disabled,
    fontSize: 12,
    verticalAlign: "middle",
    display: "inline-flex",
  },
}));

export default function DoubleRowDetailIconButton(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      sx={{ backgroundColor: "grey.A400", border: 1, borderColor: "grey.A200" }}
      key={Math.floor(Math.random() * 100000)}
    >
      <Grid item xs={2} sm={1}>
        {React.cloneElement(props.children, {
          style: { width: 56, height: 56, fontSize: 56 },
        })}
      </Grid>
      <Grid container item xs={8} sm={5} alignItems="left" sx={{ mt: 1, mb: 1 }}>
        <Grid container direction="column" spacing={0} sx={{ width: "100%" }} alignItems="left">
          <Grid container item xs={1} alignItems="left">
            <Typography
              component={"span"}
              className={props.isPassive ? classes.listItemPassiveText : classes.listItemText}
              variant="h6"
              gutterBottom
            >
              {props.text}
            </Typography>
          </Grid>
          <Grid container item xs={1} alignItems="left">
            <Typography
              align="left"
              component={"span"}
              className={props.isPassive ? classes.listItemPassiveStatusText : classes.listItemStatusText}
            >
              <Box sx={{ mr: 1 }}>{props.statusIcon}</Box>
              {props.statusText}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={0} sm={1}></Grid>
      <Grid item xs={1} sm={5} sx={{ paddingRight: 2 }}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <GenericButtonIcon
            buttonText={props.buttonText}
            buttonNav={props.buttonNav}
            onClick={(event) => props.onClick(event)}
            orientation="right"
          >
            {props.buttonIcon}
          </GenericButtonIcon>
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
        {props.buttonNav ? (
            <IconButton component={NavLink} to={props.buttonNav} disabled={props.disabled}>
              {props.buttonIcon
                ? React.cloneElement(props.buttonIcon, {
                    style: { width: 36, height: 36, fontSize: 36 },
                  })
                : ""}
            </IconButton>
          ) : (
            <IconButton onClick={props.onClick} disabled={props.disabled}>
              {props.buttonIcon
                ? React.cloneElement(props.buttonIcon, {
                    style: { width: 36, height: 36, fontSize: 36 },
                  })
                : ""}
            </IconButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
