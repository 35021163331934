import * as React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { getAllObjectsOfEntityAction } from "../../actions/actions";
import { Backdrop } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import { changeCustomerOfCurrentAdminAction, getAllAdminsAction } from "../../actions/userInfoActions";
import { getAuth } from "firebase/auth";
import { Box } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableRow } from "@mui/material";
import { Table } from "@mui/material";
import { getUserDataMap, getUserStatus } from "./userDataMap";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import GenericButtonIcon from "../common/genericButtonIcon";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function AdministrationDetailView(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const navigate = useNavigate();

  const [allCustomers, setAllCustomers] = useState([]);
  const [userClaims, setUserClaims] = useState();
  const [isBackdropOpen, setBackdropOpen] = useState(true);
  const [allTableObjects, setAllTableObjects] = useState([]);

  const load = () => {
    getAuth()
      .currentUser.getIdTokenResult()
      .then((result) => {
        setUserClaims(result.claims);
      })
      .catch((e) => {
        navigate("/error/500/ADM-000");
      });

    getAllObjectsOfEntityAction("customers")
      .then((result) => {
        setAllCustomers(result);
      })
      .catch((e) => {
        navigate("/error/500/ADM-001");
      });
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getAllAdminsAction()
      .then((result) => {
        setAllTableObjects(result);
        setBackdropOpen(false);
      })
      .catch((e) => {
        navigate("/error/500/ADM-002");
      });
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  };

  const handleCustomerViewChange = (event) => {
    setBackdropOpen(true);
    changeCustomerOfCurrentAdminAction(getAuth().currentUser.uid, event.target.value)
      .then(() => {
        getAuth()
          .currentUser.getIdToken(true)
          .then(() => {
            load();
            setBackdropOpen(false);
          });
      })
      .catch((e) => {
        navigate("/error/500/ADM-003");
      });
  };

  const generateCustomerMenuItems = () => {
    return allCustomers.map((customer) => {
      return (
        <MenuItem key={customer.id} value={customer.id}>
          {customer.name}
        </MenuItem>
      );
    });
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //copied from generic table page////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const generateTableHead = () => {
    let cells = getUserDataMap().fieldData.map((section, index) => {
      return section.fields.map((field, fieldIndex) => {
        if (field.type && field.type === "text") {
          return <TableCell align="left">{t(field.label)}</TableCell>;
        }
        return <TableCell align="center">{t(field.label)}</TableCell>;
      });
    });
    if (getUserDataMap().actions) {
      cells.push(<TableCell align="center">{t("tableView.actionsHeader")}</TableCell>);
    }
    return <TableRow>{cells}</TableRow>;
  };

  const getBackroundColor = (object) => {
    if (!getUserStatus) {
      return;
    }
    let status = getUserStatus(object);
    switch (status) {
      case 0: //gray
        return;
      case 1: //gold
        return "primary.main";
      case 2: //blue
        return "success.main";
      case 3: //red
        return "error.main";
      default:
        return;
    }
  };

  const isActionVisible = (object, statuses) => {
    if (object.id === getAuth().currentUser.uid) {
      return false;
    }
    if (!getUserStatus) {
      return true;
    }
    if (!statuses) {
      return true;
    }

    let status = getUserStatus(object);
    return statuses.includes(status);
  };

  const generateTableBody = () => {
    let tableData = allTableObjects;

    if (tableData.length === 0) {
      let columnCount = 0;
      getUserDataMap().fieldData.forEach((section, index) => {
        section.fields.forEach((field, fieldIndex) => {
          columnCount++;
        });
      });
      if (getUserDataMap().actions) {
        columnCount++;
      }

      return (
        <TableRow key={"errrow"}>
          <TableCell key={"err"} colSpan={columnCount} align="center">
            <Box
              sx={{
                mt: 5,
                mr: 2,
                ml: 2,
                color: "grey.A700",
              }}
            >
              <InfoOutlinedIcon sx={{ fontSize: 200 }} />
            </Box>
            <Box sx={{ m: 2, color: "grey.A700", fontSize: 30 }}>{t("generic.filterNoItems")}</Box>
          </TableCell>
        </TableRow>
      );
    }

    return tableData.map((object, tbIndex) => {
      let cells = getUserDataMap().fieldData.map((section, index) => {
        return section.fields.map((field, fieldIndex) => {
          if (field.type && field.type === "date") {
            if (object[field.name]) {
              return (
                <TableCell key={"tc" + index + fieldIndex} align="center" sx={{ backgroundColor: getBackroundColor(object) }}>
                  {object[field.name]}
                </TableCell>
              );
            }
          }
          if (field.type && field.type === "text") {
            if (object[field.name]) {
              return (
                <TableCell key={"tc" + index + fieldIndex} align="left" sx={{ backgroundColor: getBackroundColor(object) }}>
                  {object[field.name]}
                </TableCell>
              );
            }
          }
          if (field.name === "id") {
            return (
              <Tooltip title={t(object[field.name])} placement="top">
                <TableCell key={"tc" + index + fieldIndex} align="left" sx={{ backgroundColor: getBackroundColor(object) }}>
                  {object[field.name].substring(0, 6) + "..."}
                </TableCell>
              </Tooltip>
            );
          }
          return (
            <TableCell key={"tc" + index + fieldIndex} align="center" sx={{ backgroundColor: getBackroundColor(object) }}>
              {t(object[field.name])}
            </TableCell>
          );
        });
      });

      if (getUserDataMap().actions) {
        let actionButtons = getUserDataMap().actions.map((action, index) => {
          if (!isActionVisible(object, action.statuses)) {
            return "";
          }
          return (
            <Tooltip title={t(action.label)} placement="bottom">
              <IconButton
                size="small"
                onClick={(event) => {
                  if (action.action) {
                    setBackdropOpen(true);
                    action
                      .action(object)
                      .then(() => {
                        setBackdropOpen(false);
                        load();
                      })
                      .catch((e) => {
                        navigate("/error/500/ADM-003");
                      });
                  } else if (action.navAction) {
                    navigate(action.navAction(object));
                  }
                }}
                sx={{ color: "text.primary" }}
              >
                {action.icon}
              </IconButton>
            </Tooltip>
          );
        });
        cells.push(
          <TableCell key={"tc" + tbIndex} align="center" sx={{ backgroundColor: getBackroundColor(object) }}>
            <Box display="flex" flexDirection="row" justifyContent="center">
              {actionButtons}
            </Box>
          </TableCell>
        );
      }
      return <TableRow key={"r" + tbIndex} hover>{cells}</TableRow>;
    });
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ backgroundColor: "grey.A400", mb: 1, textAlign: "left" }} display="flex" flexDirextion="row">
        <Typography component={"span"} variant="h7" sx={{ color: "text.primary", textAlign: "left", ml: 1, mr: 2, mt: 1, mb: 1 }}>
          {t("administration.selectCustomer")}
        </Typography>
        <Select
          labelId="custSelect"
          id="custselect"
          value={userClaims && allCustomers.length !== 0 ? userClaims.customerId : ""}
          onChange={(event) => handleCustomerViewChange(event)}
          sx={{ width: "100%", maxHeight: 56, mt: 1, mb: 1 }}
        >
          {generateCustomerMenuItems()}
        </Select>
      </Box>
      <Box sx={{ maxWidth: "100vw" }} display="flex" flexDirection="column">
        <Typography component={"span"} variant="h6" sx={{ color: "text.primary", textAlign: "left", ml: 1, mr: 2, mt: 1 }}>
          {t("administration.administrators")}
        </Typography>
        <Box sx={{ backgroundColor: "grey.A400", mb: 1, textAlign: "left" }} display="flex" flexDirextion="row">
          <Box sx={{ m: 1 }}>
            <GenericButtonIcon buttonText={t("administration.create")} buttonNav={"/create/admin/"} orientation="left" color="primary">
              <AddCircleIcon />
            </GenericButtonIcon>
          </Box>
        </Box>
        <TableContainer size="small">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>{generateTableHead()}</TableHead>
            <TableBody>{generateTableBody()}</TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Backdrop sx={{ color: "text.primary", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isBackdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}
