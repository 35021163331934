import * as React from "react";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";

export const plainDeviceObject = {
  id: null,
  name: null,
  customerId: null,
  lastActiveDate: null,
  batteryStatus: null,
  isBeacon: null,
};

export const getDeviceDataMap = () => {
  return {
    fieldData: [
      {
        icon: <CircleNotificationsIcon />,
        fields: [
          {
            name: "name",
            label: "device.name",
            helperText: "device.nameHelperText",
            validation: {},
          },
          {
            type: "dateTime",
            name: "lastActiveDate",
            label: "device.lastActiveDate",
            helperText: "device.lastActiveDateHelperText",
            validation: {},
          },
          {
            name: "batteryStatus",
            label: "device.batteryStatus",
            helperText: "device.batteryStatusHelperText",
            validation: {},
          },
          {
            name: "isBeacon",
            label: "device.isBeacon",
            helperText: "device.isBeaconHelperText",
            validation: {},
          },
        ],
      },
    ],
  };
};
