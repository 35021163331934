import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "../menu";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import EditItem from "../editItem";
import EditViewBottomBar from "../bottomBars/editViewBottomBar";
import { useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import GoBackDialog from "../dialogs/goBackDialog";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { updateObjectAction, addObjectAction } from "../../../actions/actions";
import { getCustomerByIdAction } from "../../../actions/customerActions";
import { getObjectByIdAction } from "../../../actions/actions";
import DetailsErrorPage from "./detailsErrorPage";
import { Backdrop } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Grid } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ErrorDialog from "../dialogs/errorDialog";
import InvoicePaymentBottomBar from "../bottomBars/invoicePaymentBottomBar";
import InvoicePaymentDialog from "../dialogs/invoicePaymentDialog";
import GenericEditModeFields from "./genericEditModeFields";
import GenericEditModeTable from "./genericEditModeTable";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    fontFamily: ["Futura"].join(","),
    minHeight: "100vh",
    maxWidth: "100vw",
    backgroundColor: theme.palette.background.default,
    "& .MuiInput-icon": {
      color: theme.palette.text.primary,
    },
  },
  mainArea: {
    background: theme.palette.grey.A100,
    minHeight: "100vh",
    width: "100%",
  },
}));

export default function GenericEditMode(props) {
  const { id, customerId } = useParams();
  const {
    dataMap,
    modifyTitle,
    createTitle,
    abortButtonText,
    saveButtonText,
    confirmAbortTitle,
    confirmAbortText,
    entityName,
    viewMode,
    viewTitle,
    paymentBottomBar,
  } = props;
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const classes = useStyles();
  const navigate = useNavigate();

  const createValidityMap = () => {
    let map = {};
    dataMap().fieldData.forEach((section) => {
      section.fields.forEach((field) => {
        map[field.name] = null;
      });
    });
    return map;
  };

  const [object, setObject] = useState({});

  const [customer, setCustomer] = useState({});
  const [isBackdropOpen, setBackdropOpen] = useState(true);
  const [validityMap, setValidityMap] = useState(createValidityMap());
  const [isModified, setModified] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isErrorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogText, setErrorDialogText] = useState();
  const [wasSubmitClicked, setSubmitClicked] = useState(false);

  const createDummyRow = (columns) => {
    let dummyRow = {};
    let dummyRowValidityMap = {};
    columns.forEach((column, cindex) => {
      if (!column.readOnly) {
        dummyRow[column.name] = "";
        dummyRowValidityMap[column.name] = null;
      } else {
        if (column.default) {
          dummyRow[column.name] = column.default;
        }
      }
    });
    dummyRow.isDummy = true;
    if (!validityMap.table) {
      validityMap.table = [];
    }

    validityMap.table.push(dummyRowValidityMap);
    return dummyRow;
  };

  const load = () => {
    if (id) {
      getObjectByIdAction(entityName, id)
        .then((result) => {
          if (result != null) {
            setObject(result);
          }
          if (!customerId) {
            setBackdropOpen(false);
          }
        })
        .catch((e) => {
          navigate("/error/500/GEM-000-" + entityName.toUpperCase().replace("/", "-"));
        });
    } else if (customerId) {
      getCustomerByIdAction(customerId)
        .then((result) => {
          if (result != null) {
            setCustomer(result);
          }
          setBackdropOpen(false);
        })
        .catch((e) => {
          navigate("/error/500/GEM-004-" + entityName.toUpperCase().replace("/", "-"));
        });
    } else {
      setDefaultValues();
      setBackdropOpen(false);
      return <DetailsErrorPage />;
    }
  };

  const setDefaultValues = () => {
    dataMap().fieldData.forEach((section, sectionIndex) => {
      section.fields.forEach((field, fieldIndex) => {
        if (field.default) {
          setDefaultValue(field.name, field.default);
        }
      });
    });
  };

  const setDefaultValue = (fieldName, fieldValue) => {
    let fieldNameArray = fieldName.split(".");
    if (fieldNameArray.length === 1) {
      object[fieldName] = fieldValue;
    } else {
      if (!object[fieldNameArray[0]]) {
        object[fieldNameArray[0]] = {};
      }
      object[fieldNameArray[0]][fieldNameArray[1]] = fieldValue;
    }
  };

  const isFieldHidden = (fieldName) => {
    let result;
    dataMap().fieldData.map((section, sectionIndex) => {
      section.fields.forEach((field, fieldIndex) => {
        if (field.name === fieldName && field.displayDependency) {
          let dependencyValue = getFieldValue(field.displayDependency);
          if (dependencyValue) {
            result = dependencyValue;
            return;
          }
        }
      });
    });
    return result ? true : false;
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmationDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmationDialogOpen = () => {
    if (isModified) {
      setConfirmDialogOpen(true);
    } else {
      handleGoBack();
    }
  };

  const handlePymentDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleGoBack = () => {
    if (object.isAdmin) {
      navigate("/admin");
    } else if (["customers/contracts", "invoices"].includes(entityName)) {
      navigate("/" + entityName + "/" + (customerId ? customerId : ""));
    } else {
      navigate("/" + entityName + "/" + (id ? id : ""));
    }
  };

  const handleSubmit = async (event) => {
    setBackdropOpen(true);
    event.preventDefault();
    setSubmitClicked(true);

    deleteEmptyTableRows();

    let formValid = validateAll();

    if (!formValid) {
      setBackdropOpen(false);
      if (dataMap().table) {
        object.table.push(createDummyRow(dataMap().table.columns));
      }
      return;
    }

    if (id) {
      updateObjectAction(entityName, object)
        .then(() => {
          load();
          handleGoBack();
        })
        .catch((e) => {
          navigate("/error/500/GEM-001-" + entityName.toUpperCase().replace("/", "-"));
        });
    } else {
      if (!customerId && ["customers/contracts", "customers/users", "invoices"].includes(entityName)) {
        navigate("/error/500/GEM-002-" + entityName.toUpperCase().replace("/", "-"));
        return;
      }
      if (entityName === "admin") {
        object.isAdmin = true;
      }
      object.customerId = customerId;
      addObjectAction(entityName, object)
        .then(() => {
          load();
          handleGoBack();
        })
        .catch((e) => {
          if (e.gulyaError) {
            setBackdropOpen(false);
            handleBusinessError(e.gulyaError);
          } else {
            navigate("/error/500/GEM-003-" + entityName.toUpperCase().replace("/", "-"));
          }
        });
    }
  };

  const deleteEmptyTableRows = () => {
    if (!object.table) {
      return;
    }

    let rowIndexesRemoved = [];
    let tableWithoutEmptyRows = object.table.filter((row, rindex) => {
      if (row.isDummy) {
        rowIndexesRemoved.push(rindex);
        return false;
      }

      let toKeep = false;
      for (var prop in row) {
        for (var col in dataMap().table.columns) {
          if (dataMap().table.columns[col].name === prop && !dataMap().table.columns[col].readOnly && row[prop] !== "") {
            toKeep = true;
          }
        }
      }

      if (!toKeep) {
        rowIndexesRemoved.push(rindex);
      }
      return toKeep;
    });

    object.table = tableWithoutEmptyRows;

    let newValidityMapTable = validityMap.table.filter((row, rindex) => {
      return !rowIndexesRemoved.includes(rindex);
    });
    validityMap.table = newValidityMapTable;
  };

  const handleBusinessError = (error) => {
    if (error.errorCode === "contract.overlapWithLatestError") {
      setErrorDialogText(t("contract.overlapWithLatestError") + error.errorSpecificData.latestEnd.toLocaleDateString());
    } else if (error.errorCode === "contract.startAfterEndError") {
      setErrorDialogText(t("contract.startAfterEndError"));
    } else if (error.errorCode === "contract.openPeriodError") {
      setErrorDialogText(t("contract.openPeriodError"));
    } else {
      setErrorDialogText(t("generic.unknownError"));
    }
    setErrorDialogOpen(true);
  };

  const handleErrorDialogClose = (error) => {
    setErrorDialogOpen(false);
  };

  const isFormValid = () => {
    if (!wasSubmitClicked) {
      return true;
    }
    let formValid = true;
    dataMap().fieldData.forEach((section) => {
      section.fields.forEach((field) => {
        let fieldValue = validityMap[field.name];
        formValid = formValid && fieldValue === true;
      });
    });

    return formValid;
  };

  const validateField = (value, validationRules) => {
    let isFieldValid = true;
    if (validationRules != null) {
      if (validationRules.mandatory) {
        if (!value || value.trim() === "") {
          isFieldValid = false;
        }
      }
      if (validationRules.numeric) {
        if (isNaN(value)) {
          isFieldValid = false;
        }
      }
      if (validationRules.email) {
        if (validationRules.mandatory && !value) {
          return false;
        } else if (!validationRules.mandatory && !value) {
          return true;
        }
        let isMailInvalid = !value
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        if (isMailInvalid === true) {
          isFieldValid = false;
        }
      }
    }
    return isFieldValid;
  };

  const validateAll = () => {
    let isFormValid = true;
    dataMap().fieldData.forEach((section) => {
      section.fields.forEach((field) => {
        let isFieldValid = isFieldHidden(field.name) || validateField(getFieldValue(field.name), field.validation);
        isFormValid = isFormValid && isFieldValid;
        setValidityMap((prevState) => ({
          ...prevState,
          [field.name]: isFieldValid,
        }));
      });
    });
    if (dataMap().table && object.table) {
      let validityMapTable = validityMap.table;
      if (object.table.length === 0) {
        return false;
      }
      object.table.forEach((row, rindex) => {
        if (!row.isDummy) {
          dataMap().table.columns.forEach((column, cindex) => {
            if (!column.readOnly) {
              let isCellValid = validateField(row[column.name], column.validation);
              isFormValid = isFormValid && isCellValid;
              validityMapTable[rindex][column.name] = isCellValid;
            }
          });
        }
      });
      setValidityMap((prevState) => ({
        ...prevState,
        table: validityMapTable,
      }));
    }
    return isFormValid;
  };

  const getFieldValue = (fieldName) => {
    let fieldNameArray = fieldName.split(".");
    if (fieldNameArray.length === 1) {
      return object[fieldName];
    } else {
      if (!object[fieldNameArray[0]]) {
        return null;
      }
      return object[fieldNameArray[0]][fieldNameArray[1]];
    }
  };

  const getCustomerHeader = () => {
    if (!customerId || !["customers/contracts", "customers/users", "invoices"].includes(entityName)) {
      return;
    }

    return (
      <React.Fragment key={"GEMSHEAD"}>
        <EditItem icon={<AssignmentIndIcon sx={{ fontSize: 60, mr: 2, mt: 2, color: "text.primary" }} />}>
          <Typography sx={{ fontSize: 25, color: "text.primary" }} align="left">
            {customer.name}
          </Typography>
        </EditItem>
        <Divider sx={{ borderColor: "grey.A100" }} />
      </React.Fragment>
    );
  };

  const getStatusMessage = () => {
    if (!dataMap().functions || !dataMap().functions.getViewModeStatusMessage) {
      return <br />;
    }
    let message = dataMap().functions.getViewModeStatusMessage(object);
    if (!message) {
      return <br />;
    }

    return (
      <Typography
        component={"span"}
        sx={{ fontSize: 16, color: message.type === "error" ? "error.light" : "text.primary", textAlign: "left", ml: 1, mb: 1 }}
      >
        {message.icon}{message.label}
      </Typography>
    );
  };

  return (
    <Grid container display="flex" flexDirection="row" className={classes.root}>
      <Grid item container display="flex" flexDirection="row" xs={12} sm={12} md={12} lg={11} xl={9}>
        <Box display="flex" flexDirection="row" sx={{ width: "100%" }}>
          <Box flexDirection="column" sx={{ maxWidth: 75, display: { xs: "none", md: "flex" } }}>
            <Menu />
          </Box>
          <Box display="flex" flexDirection="column" className={classes.mainArea} component="form" onSubmit={handleSubmit} noValidate>
            <Typography component={"span"} sx={{ fontSize: 29, textAlign: "left", ml: 1, mb: 0, color: "text.primary" }}>
              {viewMode ? t(viewTitle) : (id ? t(modifyTitle) : t(createTitle)) + (isModified ? "*" : "")}
            </Typography>
            {viewMode ? (
              getStatusMessage()
            ) : (
              <Typography component={"span"} sx={{ fontSize: 16, color: "error.light", textAlign: "left", ml: 1, mb: 1 }}>
                {!isFormValid() ? (
                  <WarningIcon sx={{ mr: 1, fontSize: 18, verticalAlign: "middle" }} />
                ) : (
                  <Box sx={{ height: 24, display: "block" }} />
                )}
                {!isFormValid() ? t("editMode.invalidForm") : ""}
              </Typography>
            )}
            {getCustomerHeader()}
            <GenericEditModeFields
              id={id}
              object={object}
              dataMap={dataMap}
              validityMap={validityMap}
              viewMode={viewMode}
              setObject={setObject}
              setModified={setModified}
              validateField={validateField}
              setValidityMap={setValidityMap}
              isFieldHidden={isFieldHidden}
              getFieldValue={getFieldValue}
            />
            <GenericEditModeTable
              object={object}
              dataMap={dataMap}
              validityMap={validityMap}
              viewMode={viewMode}
              createDummyRow={createDummyRow}
              isFieldHidden={isFieldHidden}
              setModified={setModified}
              validateField={validateField}
              setValidityMap={setValidityMap}
              setObject={setObject}
            />
            {paymentBottomBar ? (
              <React.Fragment key={"GEMPTMBRPMNT"}>
                <InvoicePaymentBottomBar onAbortClick={handlePymentDialogOpen} paymentEnabled={!object.revokeDate && !object.payDate} />
                <InvoicePaymentDialog open={isConfirmDialogOpen} onClose={handleConfirmationDialogClose} invoice={object} />
              </React.Fragment>
            ) : (
              <React.Fragment key={"GEMPTMBR"}>
                <EditViewBottomBar
                  abortText={t(abortButtonText)}
                  onAbortClick={handleConfirmationDialogOpen}
                  saveText={t(saveButtonText)}
                  isSaveDisabled={!isFormValid()}
                />
                <GoBackDialog
                  title={t(confirmAbortTitle)}
                  text={t(confirmAbortText)}
                  open={isConfirmDialogOpen}
                  onClose={handleConfirmationDialogClose}
                  onConfirm={handleGoBack}
                />
              </React.Fragment>
            )}
          </Box>
          <Backdrop sx={{ color: "text.primary", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isBackdropOpen}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <ErrorDialog open={isErrorDialogOpen} errorText={errorDialogText} onClose={() => handleErrorDialogClose()} />
        </Box>
      </Grid>
    </Grid>
  );
}
