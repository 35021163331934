import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import EditItem from "../editItem";
import { TextField } from "@mui/material";
import GenericButtonIcon from "../genericButtonIcon";
import { Avatar } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const useStyles = makeStyles((theme) => ({
  viewModeFieldText: {
    color: theme.palette.text.primary,
    verticalAlign: "middle",
    display: "inline-flex",
    textAlign: "left",
  },
}));

export default function GenericEditModeFields(props) {
  const { id, object, dataMap, validityMap, viewMode, setObject, setModified, validateField, setValidityMap, isFieldHidden, getFieldValue } = props;
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const classes = useStyles();

  const handleBlur = (targetValue, validationRules, fieldName) => {
    let isFieldValid = validateField(targetValue, validationRules);
    setValidityMap((prevState) => ({
      ...prevState,
      [fieldName]: isFieldValid,
    }));

    let fieldNameArray = fieldName.split(".");
    if (fieldNameArray.length === 1) {
      object[fieldName] = targetValue;
    } else {
      if (!object[fieldNameArray[0]]) {
        object[fieldNameArray[0]] = {};
      }
      object[fieldNameArray[0]][fieldNameArray[1]] = targetValue;
    }
  };

  const handleChange = (targetValue, validationRules, fieldName) => {
    setModified(true);
    handleBlur(targetValue, validationRules, fieldName);
  };

  const getIcon = (icon) => {
    if (icon === "animalAvatar") {
      return (
        <Avatar
          variant="square"
          sx={{
            height: 60,
            width: 60,
            mt: 2,
            ml: 1,
            backgroundColor: object.group ? object.group.color : "",
          }}
        >
          {object.abbreviation ? object.abbreviation : ""}
        </Avatar>
      );
    } else if (icon === "groupAvatar") {
      return (
        <Avatar
          variant="square"
          sx={{
            height: 60,
            width: 60,
            mt: 2,
            ml: 1,
            backgroundColor: object.color,
          }}
        >
          {" "}
        </Avatar>
      );
    }

    return icon;
  };

  const getTextFieldValue = (fieldName) => {
    let fieldNameArray = fieldName.split(".");

    if (fieldNameArray.length === 1) {
      return object[fieldName] ? object[fieldName] : "";
    } else {
      if (object[fieldNameArray[0]]) {
        return object[fieldNameArray[0]][fieldNameArray[1]] ? object[fieldNameArray[0]][fieldNameArray[1]] : "";
      }
      return "";
    }
  };

  const createTextField = (field) => {
    let opts = {};
    if (field.type === "date") {
      opts.InputLabelProps = { shrink: true };
      opts.type = "date";
    } else if (field.type === "amount") {
      opts.InputProps = field.validation.limit ? { maxLength: field.validation.limit, endAdornment: "CHF" } : { endAdornment: "CHF" };
    } else if (field.type === "select") {
      opts.select = true;
    } else {
      opts.disabled = id && field.readOnlyOnEdit;
      opts.inputProps = field.validation.limit ? { maxLength: field.validation.limit } : {};
    }
    return (
      <TextField
        required={field.validation.mandatory ? field.validation.mandatory : false}
        label={t(field.label)}
        name={field.name}
        value={getTextFieldValue(field.name)}
        helperText={t(field.helperText)}
        variant="standard"
        onChange={(event) => handleChange(event.target.value, field.validation, field.name)}
        onBlur={(event) => handleBlur(event.target.value, field.validation, field.name)}
        error={validityMap[field.name] == null ? false : !validityMap[field.name]}
        key={field.name}
        fullWidth
        sx={isFieldHidden(field.name) ? { display: "none" } : {}}
        {...opts}
      >
        {field.options
          ? field.options.map((option) => (
              <MenuItem key={option} value={option}>
                {t(option)}
              </MenuItem>
            ))
          : ""}
      </TextField>
    );
  };

  const generateFields = () => {
    let result = dataMap().fieldData.map((section, sectionIndex) => {
      let fieldsTodisplay = section.fields.filter((field, fieldIndex) => !field.hideOnForm);

      let textFields = fieldsTodisplay.map((field, fieldIndex) => {
        if (viewMode) {
          return (
            <Grid container spacing={0} sx={{ width: "100%", mt: "auto" }}>
              <Grid item xs={6} sm={4} sx={{ textAlign: "left" }}>
                <Typography component={"span"} className={classes.viewModeFieldText} variant="p" gutterBottom>
                  {t(field.label) + ": "}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography component={"span"} className={classes.viewModeFieldText} variant="p" gutterBottom>
                  {getTextFieldValue(field.name) === "" ? "-" : getTextFieldValue(field.name)}
                </Typography>
              </Grid>
            </Grid>
          );
        } else {
          if (field.type === "button") {
            return (
              <GenericButtonIcon
                buttonText={t(field.label)}
                onClick={(event) => {
                  var letters = "0123456789ABCDEF";
                  var color = "#";
                  for (var i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                  }
                  setModified(true);
                  //this is a hack to force update
                  let objectCopy = JSON.parse(JSON.stringify(object));
                  objectCopy.color = color;
                  setObject(objectCopy);
                }}
                orientation="left"
              >
                {field.icon}
              </GenericButtonIcon>
            );
          } else if (field.type === "checkbox") {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    disableTypography
                    checked={getFieldValue(field.name) ? true : false}
                    onChange={(event) => handleChange(event.target.checked, field.validation, field.name)}
                    sx={isFieldHidden(field.name) ? { display: "none" } : {}}
                  />
                }
                sx={{ color: "text.primary" }}
                label={t(field.name)}
              />
            );
          } else {
            return createTextField(field);
          }
        }
      });
      return (
        <React.Fragment key={"GEMS" + sectionIndex}>
          <EditItem icon={getIcon(section.icon)} viewMode={viewMode ? true : false}>
            {textFields}
          </EditItem>
          <Divider sx={{ borderColor: "grey.A100" }} />
        </React.Fragment>
      );
    });
    return result;
  };

  return generateFields();
}
