import {
    doc,
    getDocs,
    collection,
    getDoc,
    query,
    where
  } from "firebase/firestore";
  import { db } from "..";
  import { getGroupOfDeviceByIdAction } from "./groupActions";
  import { getAuth } from "firebase/auth";

  export const getAllDevicesAction = async () => {
    let queryResultArray = [];
    const token = await getAuth().currentUser.getIdTokenResult();
    const custRef = doc(db, "customers", token.claims.customerId);
    const ref = collection(db, "devices");
    const q = query(ref, where("customerId", "==", custRef));
    const queryResult = await getDocs(q);
    queryResult.forEach((device) => {
      let result = {
        id: device.id,
        name: device.data().name,
        customerId: device.data().customerId.id,
        lastActiveDate: device.data().lastActiveDate,
        batteryStatus: device.data().batteryStatus,
        isBeacon: device.data().isBeacon,
      };
      queryResultArray.push(result);
    });
  
    let resultWithGroups = [];
    await Promise.all(
      queryResultArray.map(async (device) => {
        let deviceGroup = await getGroupOfDeviceByIdAction(device.id);
        if (deviceGroup) {
          device.group = deviceGroup;
        }
        resultWithGroups.push(device);
      })
    );
  
    return resultWithGroups;
  };
  
  
  
  export const getDeviceByIdAction = async (id) => {
    const ref = doc(db, "devices", id);
    const device = await getDoc(ref);
    if (device.exists()) {
      return {
        id: device.id,
        name: device.data().name,
        customerId: device.data().customerId.id,
        lastActiveDate: device.data().lastActiveDate,
        batteryStatus: device.data().batteryStatus,
        isBeacon: device.data().isBeacon,
      };
    }
    return null;
  };