import * as React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import GroupIcon from "@mui/icons-material/GroupWork";
import SingleRowDetailIconButton from "../common/detailViewComponents/singleRowDetailIconButton";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import DetailViewBottomBar from "../common/bottomBars/detailViewBottomBar";
import ConfirmationDialog from "../common/dialogs/confirmationDialog";
import DoubleRowDetailIconButton from "../common/detailViewComponents/doubleRowDetailIconButton";
import DoubleRowDetailIconButtonMap from "../common/detailViewComponents/doubleRowDetailIconButtonMap";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Battery90Icon from "@mui/icons-material/Battery90";
import Battery80Icon from "@mui/icons-material/Battery80";
import Battery60Icon from "@mui/icons-material/Battery60";
import Battery50Icon from "@mui/icons-material/Battery50";
import Battery30Icon from "@mui/icons-material/Battery30";
import Battery20Icon from "@mui/icons-material/Battery20";
import BatteryAlertIcon from "@mui/icons-material/BatteryAlert";
import BatteryUnknownIcon from "@mui/icons-material/BatteryUnknown";
import { animalUnassignDeviceAction } from "../../actions/animalActions";
import AssignDeviceDialog from "./assignDeviceDialog";
import { ReactComponent as GulyaLogo } from "../../assets/logo.svg";
import { ReactComponent as GulyaLogoBlack } from "../../assets/logoBlack.svg";
import { SvgIcon } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useMediaQuery } from "@mui/material";

export default function AnimalDetails(props) {
  const { data, reload } = props;
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isDeviceDialogOpen, setDeviceDialogOpen] = useState(false);
  const navigate = useNavigate();
  const deviceDialogRef = useRef();
  const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");

  const getDeleteConfirmation = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleDeviceDialogOpen = () => {
    setDeviceDialogOpen(true);
  };

  const handleDeviceDialogClose = () => {
    setDeviceDialogOpen(false);
  };

  const handleRemoveDevice = () => {
    animalUnassignDeviceAction(data.id)
      .then(() => {
        reload();
        deviceDialogRef.current.reload();
      })
      .catch((e) => {
        navigate("/error/500/ADE-000");
      });
  };

  const getBatteryIcon = () => {
    if (data.device.batteryStatus >= 100) {
      return <BatteryFullIcon />;
    } else if (data.device.batteryStatus >= 90) {
      return <Battery90Icon />;
    } else if (data.device.batteryStatus >= 80) {
      return <Battery80Icon />;
    } else if (data.device.batteryStatus >= 60) {
      return <Battery60Icon />;
    } else if (data.device.batteryStatus >= 50) {
      return <Battery50Icon />;
    } else if (data.device.batteryStatus >= 30) {
      return <Battery30Icon />;
    } else if (data.device.batteryStatus >= 20) {
      return <Battery20Icon />;
    } else if (data.device.batteryStatus >= 0) {
      return <BatteryAlertIcon />;
    }

    return <BatteryUnknownIcon />;
  };

  const formatDeviceName = (input) => {
    let result = "";
    for (let i = 0; i < input.length; i++) {
      if (i % 2 === 0 && i !== 0) {
        result += ":";
      }
      result += input[i];
    }
    return result.toUpperCase();
  };

  const handleNavigate = (target) => {
    navigate(target, { state: { isRedirect: true } });
  };

  return (
    <React.Fragment key={"ADE" + data.id}>
      <Typography component={"span"} sx={{ fontSize: 29, textAlign: "left", ml: 1, mb: 4 }}>
        {t("animalDetails.subTitle")}
      </Typography>
      <SingleRowDetailIconButton text={data.name}>
        <SvgIcon>{isDarkModeEnabled ? <GulyaLogo /> : <GulyaLogoBlack />}</SvgIcon>
      </SingleRowDetailIconButton>
      <SingleRowDetailIconButton
        buttonText={t("goto.group")}
        onClick={() => handleNavigate(data.group ? "/groups/" + data.group.id : "/groups/")}
        buttonIcon={<ArrowCircleRightIcon />}
        isPassive={!data.group ? true : false}
        color="error"
        text={data.group ? t("animalDetails.groupPrefix") + data.group.name + t("animalDetails.groupSuffix") : t("animalDetails.noGroup")}
      >
        <GroupIcon />
      </SingleRowDetailIconButton>
      <DoubleRowDetailIconButton
        isPassive={data.device ? false : true}
        buttonText={data.device ? t("animalDetails.removeDevice") : t("animalDetails.selectDevice")}
        onClick={data.device ? (event) => handleRemoveDevice(event) : (event) => handleDeviceDialogOpen(event)}
        buttonIcon={data.device ? <RemoveCircleIcon /> : <AddCircleIcon />}
        text={
          data.device
            ? (data.device.isBeacon ? t("animalDetails.beacon") : t("animalDetails.node")) + formatDeviceName(data.device.name)
            : t("animalDetails.noDeviceTitle")
        }
        statusIcon={data.device ? getBatteryIcon() : <InfoOutlinedIcon />}
        statusText={data.device ? t("animalDetails.batteryLevel") + data.device.batteryStatus + "%" : t("animalDetails.pleaseAddDevice")}
      >
        {data.device ? data.device.isBeacon ? <NotificationsActiveIcon /> : <NotificationsIcon /> : <NotificationsOffOutlinedIcon />}
      </DoubleRowDetailIconButton>
      <DoubleRowDetailIconButtonMap
        locationData={data.lastLocation}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        locationData={{ latitude: 33.03123, longitude: 33.00321, altitude: 1234 }}
      />
      <DetailViewBottomBar
        deleteText={t("animalDetails.delete")}
        onDeleteClick={getDeleteConfirmation}
        editText={t("animalDetails.edit")}
        editNav={"/edit/animal/" + data.id}
      />
      <ConfirmationDialog
        title={t("animalDetails.confirmDeleteTitle")}
        text={t("animalDetails.confirmDeleteText")}
        open={isConfirmDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={() => props.onDelete()}
      />
      <AssignDeviceDialog ref={deviceDialogRef} open={isDeviceDialogOpen} onClose={handleDeviceDialogClose} animal={data} reload={reload} />
    </React.Fragment>
  );
}
