import { doc, deleteDoc } from "firebase/firestore";
import { db } from "..";
import {
  getAllGroupsAction,
  getGroupByIdAction,
  updateGroupAction,
  createGroupAction,
  unassignGroupMemberAction,
} from "./groupActions";
import { getAllInvoicesAction, getInvoiceByIdAction, updateInvoiceAction, createInvoiceAction } from "./invoiceActions";
import { getAllContractsAction, getContractByIdAction, updateContractAction, createContractAction } from "./contractActions";
import { getAllCustomersAction, getCustomerByIdAction, updateCustomerAction, createCustomerAction } from "./customerActions";
import {
  getAllAnimalsAction,
  getAnimalByIdAction,
  updateAnimalAction,
  createAnimalAction,
  getAnimalsByGroupIdAction,
} from "./animalActions";
import { getAllUsersAction, createUserAction, getUserByIdAction, updateUserAction } from "./userInfoActions";
import { getAllDevicesAction } from "./deviceActions";

export const getAllObjectsOfEntityAction = async (targetType) => {
  switch (targetType) {
    case "animals":
    case "map":
      return await getAllAnimalsAction(); //filter on actual customer id
    case "customers":
      return await getAllCustomersAction();
    case "customers/contracts":
      return await getAllContractsAction();
    case "customers/users":
      return await getAllUsersAction();
    case "devices":
      return await getAllDevicesAction(); //filter on actual customer id
    case "invoices":
      return await getAllInvoicesAction();
    case "groups":
    case "groups/members":
      return await getAllGroupsAction(); //filter on actual customer id
    default:
      return null;
  }
};

export const getObjectByIdAction = async (targetType, id) => {
  switch (targetType) {
    case "animals":
      return await getAnimalByIdAction(id); //filter on actual customer id
    case "groups":
      return await getGroupByIdAction(id); //filter on actuel customer id
    case "customers":
      return await getCustomerByIdAction(id);
    case "customers/contracts":
      return await getContractByIdAction(id);
    case "customers/users":
      return await getUserByIdAction(id);
    case "invoices":
      return await getInvoiceByIdAction(id);
    default:
      return null;
  }
};

export const updateObjectAction = async (targetType, object) => {
  switch (targetType) {
    case "animals":
      return await updateAnimalAction(object); //filter on actual customer id
    case "groups":
      return await updateGroupAction(object); //filter on actual customer id
    case "customers":
      return await updateCustomerAction(object);
    case "customers/contracts":
      return await updateContractAction(object);
    case "customers/users":
      return await updateUserAction(object);
    case "invoices":
      return await updateInvoiceAction(object);
    default:
      return null;
  }
};

export const addObjectAction = async (targetType, object) => {
  switch (targetType) {
    case "animals":
      return await createAnimalAction(object);
    case "groups":
      return await createGroupAction(object);
    case "customers":
      return await createCustomerAction(object);
    case "customers/contracts":
      return await createContractAction(object);
    case "customers/users":
    case "admin":
      return await createUserAction(object);
    case "invoices":
      return await createInvoiceAction(object);
    default:
      return null;
  }
};

export const deleteObjectAction = async (id, targetType) => {
  switch (targetType) {
    case "animals":
      return await deleteDoc(doc(db, targetType, id));
    case "groups":
      let members = await getAnimalsByGroupIdAction(id);
      members.forEach((animal) => {
        unassignGroupMemberAction(animal.id);
      });
      return await deleteDoc(doc(db, targetType, id));
    case "customers":
    // return await createCustomerAction(object);
    break;
    default:
      return null;
  }
};

export const convertStringToDate = (date) => {
  if (date == null) {
    return null;
  }
  var parts = date.split("-");
  return new Date(parts[0], parts[1] - 1, parts[2]);
};
