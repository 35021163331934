/* eslint-disable no-throw-literal */
import {
  doc,
  getDocs,
  collection,
  getDoc,
  updateDoc,
  deleteField,
  query,
  where,
  addDoc
} from "firebase/firestore";
import { db } from "..";
import { getGroupByIdAction } from "./groupActions";
import { getDeviceByIdAction } from "./deviceActions";
import { getAuth } from "firebase/auth";

export const getAnimalByIdAction = async (id) => {
  const ref = doc(db, "animals", id);
  const animal = await getDoc(ref);
  if (animal.exists()) {
    const token = await getAuth().currentUser.getIdTokenResult();
    if (token.claims.customerId !== animal.data().customerId.id) {
      throw "Invalid access to someone else's animal.";
    }
    let animalToReturn = {
      id: animal.id,
      name: animal.data().name,
      abbreviation: animal.data().abbreviation,
      customerId: animal.data().customerId.id,
    };
    if (animal.data().groupId) {
      let group = await getGroupByIdAction(animal.data().groupId.id);
      if (!group) {
        throw "Linked group was not found:" + animal.group.id;
      }
      animalToReturn.group = group;
    }
    return animalToReturn;
  }
  return null;
};

export const updateAnimalAction = async (animal) => {
  const token = await getAuth().currentUser.getIdTokenResult();
  if (token.claims.customerId !== animal.customerId) {
    throw "Invalid access to someone else's animal.";
  }
  const ref = doc(db, "animals", animal.id);
  await updateDoc(ref, {
    name: animal.name,
    abbreviation: animal.abbreviation,
  });
};

export const getAllAnimalsAction = async () => {
  let queryResultArray = [];
  const token = await getAuth().currentUser.getIdTokenResult();
  const custRef = doc(db, "customers", token.claims.customerId);
  const ref = collection(db, "animals");
  const q = query(ref, where("customerId", "==", custRef));
  const queryResult = await getDocs(q);
  queryResult.forEach((doc) => {
    let animal = {};
    animal.id = doc.id;
    animal.name = doc.data().name;
    animal.abbreviation = doc.data().abbreviation;
    animal.customerId = doc.data().customerId.id;
    if (doc.data().deviceId != null) {
      animal.device = {
        id: doc.data().deviceId.id,
      };
    }
    if (doc.data().groupId != null) {
      animal.group = {
        id: doc.data().groupId.id,
      };
    }
    queryResultArray.push(animal);
  });
  let resultWithdevicesAndGroups = [];
  await Promise.all(
    queryResultArray.map(async (animal) => {
      if (animal.device && animal.device.id) {
        let device = await getDeviceByIdAction(animal.device.id);
        if (!device) {
          throw "Linked device was not found:" + animal.device.id;
        }
        animal.device = device;
      }
      if (animal.group && animal.group.id) {
        let group = await getGroupByIdAction(animal.group.id);
        if (!group) {
          throw "Linked group was not found:" + animal.group.id;
        }
        animal.group = group;
      }
      resultWithdevicesAndGroups.push(animal);
    })
  );
  return resultWithdevicesAndGroups;
};

export const getAnimalsByGroupIdAction = async (id) => {
  let queryResultArray = [];
  const ref = collection(db, "animals");
  const targetRef = doc(db, "groups", id);
  const q = query(ref, where("groupId", "==", targetRef));
  const queryResult = await getDocs(q);
  queryResult.forEach((animalResult) => {
    let animal = {};
    animal.id = animalResult.id;
    animal.name = animalResult.data().name;
    animal.abbreviation = animalResult.data().abbreviation;
    animal.customerId = animalResult.data().customerId.id;
    if (animalResult.data().deviceId != null) {
      animal.device = {
        id: animalResult.data().deviceId.id,
      };
    }
    if (animalResult.data().groupId != null) {
      animal.group = {
        id: animalResult.data().groupId.id,
      };
    }
    queryResultArray.push(animal);
  });

  let resultWithdevicesAndGroups = [];
  await Promise.all(
    queryResultArray.map(async (animal) => {
      if (animal.device && animal.device.id) {
        let device = await getDeviceByIdAction(animal.device.id);
        if (!device) {
          throw "Linked device was not found:" + animal.device.id;
        }
        animal.device = device;
      }
      if (animal.group && animal.group.id) {
        let group = await getGroupByIdAction(animal.group.id);
        if (!group) {
          throw "Linked group was not found:" + animal.group.id;
        }
        animal.group = group;
      }
      resultWithdevicesAndGroups.push(animal);
    })
  );
  return resultWithdevicesAndGroups;
};

export const getAnimalsByDeviceIdAction = async (id) => {
  let queryResultArray = [];
  const ref = collection(db, "animals");
  const targetRef = doc(db, "devices", id);
  const q = query(ref, where("deviceId", "==", targetRef));
  const queryResult = await getDocs(q);
  queryResult.forEach((doc) => {
    let animal = {};
    animal.id = doc.id;
    animal.name = doc.data().name;
    animal.abbreviation = doc.data().abbreviation;
    animal.customerId = doc.data().customerId.id;
    if (doc.data().deviceId != null) {
      animal.device = {
        id: doc.data().deviceId.id,
      };
    }
    if (doc.data().groupId != null) {
      animal.group = {
        id: doc.data().groupId.id,
      };
    }
    queryResultArray.push(animal);
  });
  return queryResultArray;
};

export const animalUnassignDeviceAction = async (id) => {
  const ref = doc(db, "animals", id);
  await updateDoc(ref, {
    deviceId: deleteField(),
  });
};

export const animalAssignDeviceAction = async (animalId, deviceId) => {
  let animalsWithTheDevice = await getAnimalsByDeviceIdAction(deviceId);
  await Promise.all(
    animalsWithTheDevice.map(async (animal) => {
      await animalUnassignDeviceAction(animal.id);
    })
  );

  const ref = doc(db, "animals", animalId);
  const deviceRef = doc(db, "devices", deviceId);
  await updateDoc(ref, {
    deviceId: deviceRef,
  });
};

export const createAnimalAction = async (animal) => {
  const token = await getAuth().currentUser.getIdTokenResult();
    const custRef = doc(db, "customers", token.claims.customerId);
    const ref = collection(db, "animals");
    await addDoc(ref, {
      name: animal.name,
      abbreviation: animal.abbreviation,
      customerId: custRef,
    });
  };