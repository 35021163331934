import * as React from "react";
import { useTranslation } from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import FilterList from "../common/filterList";
import { useState } from "react";
import DeviceDetailButton from "../devices/deviceDetailButton";
import Divider from "@mui/material/Divider";
import GenericButtonIcon from "../common/genericButtonIcon";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { getAllObjectsOfEntityAction } from "../../actions/actions";
import { animalAssignDeviceAction } from "../../actions/animalActions";
import { useEffect } from "react";
import { DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useImperativeHandle } from "react";

function AssignDeviceDialog(props, ref) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const navigate = useNavigate();
  const { onClose, reload, open, animal } = props;

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selected, setSelected] = useState();
  const [filterText, setFilterText] = useState("");
  const [allDevices, setAllDevices] = useState();

  const load = () => {
    getAllObjectsOfEntityAction("devices")
      .then((result) => {
        setAllDevices(result);
      })
      .catch((e) => {
        navigate("/error/500/ADD-000");
      });
  };

  useEffect(() => {
    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    reload() {
      load();
    },
  }));

  useEffect(() => {
    let filteredItems = getFilteredItems();
    if (filteredItems.length !== 0) {
      handleSelectionChange(filteredItems[0], 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDevices]);

  const handleClose = () => {
    onClose();
  };

  const handleSelectionChange = (node, index) => {
    setSelectedIndex(index);
    setSelected(node);
  };

  const handleFilterChange = (filterText) => {
    setFilterText(filterText);
  };

  const assignDeviceToAnimal = () => {
    animalAssignDeviceAction(animal.id, selected.id)
      .then((result) => {
        onClose();
        reload();
        load();
      })
      .catch((e) => {
        navigate("/error/500/ADD-001");
      });
  };

  const getFilteredItems = () => {
    if (!allDevices) {
      return [];
    }
    return allDevices.filter((node, i) => {
      return node.name.toLowerCase().includes(filterText.toLowerCase());
    });
  };

  const generateListItems = () => {
    let filteredItems = getFilteredItems();
    if (filteredItems.length === 0) {
      return "";
    }

    return filteredItems.map((node, index) => (
      <DeviceDetailButton
        key={node.id}
        data={node}
        index={index}
        selected={selectedIndex === index}
        onClick={(event) => handleSelectionChange(event, index)}
      />
    ));
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          boxShadow: "none",
          height: 600,
          maxWidth: 800,
          width: 500,
          borderRadius: 0,
        },
      }}
    >
      <DialogTitle>
        <FormatListNumberedIcon sx={{ fontSize: 30, mr: 2 }} />
        {t("animalAssignDeviceDialog.title")}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: 0 }}>
        <FilterList
          onSelectionChange={handleSelectionChange}
          onFilterChange={handleFilterChange}
          searchLabel={t("animalAssignDeviceDialog.searchLabel")}
        >
          {generateListItems()}
        </FilterList>
      </DialogContent>
      <GenericButtonIcon
        buttonText={t("animalAssignDeviceDialog.close")}
        onClick={(event) => handleClose(event)}
        orientation="left"
        height="small"
      >
        <CancelIcon />
      </GenericButtonIcon>
      <Divider />
      <GenericButtonIcon
        buttonText={t("animalAssignDeviceDialog.add")}
        onClick={(event) => assignDeviceToAnimal(event)}
        orientation="left"
        color="primary"
        disabled={selectedIndex < 0}
        height="small"
      >
        <AddCircleIcon />
      </GenericButtonIcon>
    </Dialog>
  );
}

export default React.forwardRef(AssignDeviceDialog);
