import * as React from "react";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ListItemAvatar } from "@mui/material";
import { Avatar } from "@mui/material";
import { getContractsStatus } from "../../actions/contractActions";
import { useState } from "react";
import { useEffect } from "react";
import { getInvoicesStatus } from "../../actions/invoiceActions";

export default function CustomerDetailButton(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const { selected, index, data, onClick } = props;
  const [avatarColor, setAvatarColor] = useState("secondary.light");

  useEffect(() => {
    getCustomerStatusColor().then((color) => setAvatarColor(color));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getAddress = () => {
    let address = data.companyAddress;
    if (!data.companyAddress || data.companyAddress.caSameAsMa) {
      address = data.mailingAddress;
    }

    if (!address) {
      return "";
    }
    return (
      (address.countryCode ? address.countryCode.split(".")[1].toUpperCase() : address.countryCode) +
      "-" +
      address.zip +
      " " +
      address.city +
      ", " +
      address.street +
      " " +
      address.number
    );
  };

  const getCustomerStatusColor = async () => {
    let invoicesStatus = await getInvoicesStatus(data.id);
    if (invoicesStatus === 3) {
      return "error.main";
    }

    let status = await getContractsStatus(data.id);
    if (status === 0) {
      return "primary.main";
    }
    if (status === 1) {
      return "secondary.light";
    }
    if (status === 2) {
      return "success.main";
    }
    return null;
  };

  return (
    <React.Fragment key={"CDB" + data.id}>
      <ListItem alignItems="flex-start" selected={selected} onClick={(event) => onClick(data, index)} key={data.id}>
        <ListItemAvatar>
          <Avatar
            alt={data.name}
            variant="square"
            sx={{
              backgroundColor: avatarColor,
            }}
          >
            {""}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={data.name} secondary={getAddress()} />
      </ListItem>
      <Divider component="li" key={data.id + "d"} />
    </React.Fragment>
  );
}
