import {
  doc,
  getDocs,
  collection,
  getDoc,
  updateDoc,
  query,

  addDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "..";

export const updateCustomerAction = async (customer) => {
  const ref = doc(db, "customers", customer.id);
  delete customer.id;
  await updateDoc(ref, customer);
};

export const createCustomerAction = async (customer) => {
  const ref = collection(db, "customers");
  delete customer.id;
  delete customer.customerId;
  await addDoc(ref, customer);
};

export const getCustomerByIdAction = async (id) => {
  const ref = doc(db, "customers", id);
  const customer = await getDoc(ref);
  if (customer.exists()) {
    let data = customer.data();
    data.id = customer.id;
    return data;
  }
  return null;
};

export const getAllCustomersAction = async () => {
  let queryResultArray = [];
  const q = query(collection(db, "customers"), orderBy("name", "asc"));
  const queryResult = await getDocs(q);
  queryResult.forEach((customer) => {
    let result = customer.data();
    result.id = customer.id;
    queryResultArray.push(result);
  });

  return queryResultArray;
};
