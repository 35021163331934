import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  extrasmall: {
    maxHeight: 30,
  },
  small: {
    maxHeight: 36,
  },
  large: {
    minHeight: 56,
  },
}));

export default function GenericButtonIcon(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const classes = useStyles();
  let buttonClass = {};
  let fontSize = null;
  const { onClick, height, color, buttonText, buttonNav, children, orientation, disabled, type, sx } = props;

  if (height && height.toLowerCase() === "extrasmall") {
    buttonClass = classes.extrasmall;
    fontSize = 12;
  } else if (height && height.toLowerCase() === "small") {
    buttonClass = classes.small;
  } else {
    buttonClass = classes.large;
    fontSize = 16;
  }

  if (buttonNav && children) {
    return (
      <Button
        disableElevation
        color={color ? color : "secondary"}
        className={buttonClass}
        variant="contained"
        disabled={disabled}
        style={fontSize ? { fontSize: fontSize } : {}}
        startIcon={orientation && orientation === "left" ? children : ""}
        endIcon={orientation && orientation === "right" ? children : ""}
        component={NavLink}
        to={buttonNav}
        sx={sx}
      >
        {buttonText}
      </Button>
    );
  } else if (onClick  && children) {
    return (
      <Button
        disableElevation
        color={color ? color : "secondary"}
        className={buttonClass}
        variant="contained"
        disabled={disabled}
        style={fontSize ? { fontSize: fontSize } : {}}
        startIcon={orientation && orientation === "left" ? props.children : ""}
        endIcon={orientation && orientation === "right" ? children : ""}
        onClick={(event) => onClick(event)}
        sx={sx}
      >
        {buttonText}
      </Button>
    );
  } else if (type != null) {
    return (
      <Button
        disableElevation
        color={color ? color : "secondary"}
        className={buttonClass}
        variant="contained"
        disabled={disabled}
        style={fontSize ? { fontSize: fontSize } : {}}
        startIcon={orientation && orientation === "left" ? children : ""}
        endIcon={orientation && orientation === "right" ? children : ""}
        type={type}
        sx={sx}
      >
        {buttonText}
      </Button>
    );
  }

  return "";
}
