import * as React from "react";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Grid from "@mui/material/Grid";
import GenericButtonIcon from "../common/genericButtonIcon";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Box } from "@mui/material";
import { IconButton } from "@mui/material";

export default function GroupMembersListButton(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line

  const getGroupcolor = () => {
    if (props.groupColor) {
      return props.groupColor;
    }
    return "secondary.light";
  };

  return (
    <React.Fragment key={"GMLB" + props.node.id}>
      <ListItem alignItems="flex-start" key={props.node.id}>
        <Grid container spacing={0} sx={{ width: "100%" }}>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item xs={3} md={1}>
              <Avatar
                alt={props.node.name}
                variant="square"
                sx={{
                  backgroundColor: getGroupcolor(),
                }}
              >
                {props.node.abbreviation}
              </Avatar>
            </Grid>
            <Grid item xs={7} md={6}>
              <Typography component={"span"} variant="h6" gutterBottom>
                {props.node.name}
                {props.node.hasBeacon ? <NotificationsActiveIcon sx={{ fontSize: 18, ml: 1, verticalAlign: "middle" }} /> : ""}
              </Typography>
            </Grid>
            <Grid item xs={2} md={5}>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <GenericButtonIcon
                  buttonText={t("groupsMembersDetails.removeFromGroup")}
                  onClick={(event) => props.onClick(event)}
                  orientation="right"
                >
                  <RemoveCircleIcon />
                </GenericButtonIcon>
              </Box>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <IconButton onClick={(event) => props.onClick(event)}>
                  <RemoveCircleIcon style={{ width: 36, height: 36, fontSize: 36 }} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <Divider component="li" key={"GMLBDIV" + props.node.id}/>
    </React.Fragment>
  );
}
