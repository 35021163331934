import * as React from "react";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import StatusIcon from "@mui/icons-material/AccessTime";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function AnimalDetailButton(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const {data, index, selected, onClick} = props;

  const getSecondary = () => {
    let iconPrimary = <HelpOutlineIcon fontSize="small" sx={{ mr: 1 }} />;
    let hasUpdateInfo = false;
    let statusTextPrimary = t("animalDetailButton.secondaryNoActivities");
    if (data.device) {
      const lastActiveDate = data.device.lastActiveDate.toDate();

      var monthsBetween = Math.abs(new Date() - lastActiveDate) / 2.628e9;
      if (monthsBetween < 4) {
        statusTextPrimary =
          t("animalDetailButton.secondaryUpdatedStart") + Math.round(monthsBetween) + t("animalDetailButton.secondaryUpdatedMonthsAgo");
        hasUpdateInfo = true;
      }
      if (monthsBetween < 2) {
        statusTextPrimary = t("animalDetailButton.secondaryUpdatedAMonthAgo");
        hasUpdateInfo = true;
      }

      var daysBetween = Math.abs(new Date() - lastActiveDate) / 8.64e7;
      if (daysBetween < 14) {
        statusTextPrimary = t("animalDetailButton.secondaryUpdatedAWeekAgo");
        hasUpdateInfo = true;
      }
      if (daysBetween < 7) {
        statusTextPrimary =
          t("animalDetailButton.secondaryUpdatedStart") + Math.round(daysBetween) + t("animalDetailButton.secondaryUpdatedDaysAgo");
        hasUpdateInfo = true;
      }

      var hoursBetween = Math.abs(new Date() - lastActiveDate) / 3.6e6;
      if (hoursBetween < 48) {
        statusTextPrimary = t("animalDetailButton.secondaryUpdatedADayAgo");
        hasUpdateInfo = true;
      }
      if (hoursBetween < 24) {
        statusTextPrimary =
          t("animalDetailButton.secondaryUpdatedStart") + Math.round(hoursBetween) + t("animalDetailButton.secondaryUpdatedHoursAgo");
        hasUpdateInfo = true;
      }

      var minutesBetween = Math.abs(new Date() - lastActiveDate) / 6e4;
      if (minutesBetween < 120) {
        statusTextPrimary = t("animalDetailButton.secondaryUpdatedAnHourAgo");
        hasUpdateInfo = true;
      }
      if (minutesBetween < 60) {
        statusTextPrimary =
          t("animalDetailButton.secondaryUpdatedStart") + Math.round(minutesBetween) + t("animalDetailButton.secondaryUpdatedMinutesAgo");
        hasUpdateInfo = true;
      }
      if (minutesBetween < 1) {
        statusTextPrimary = t("animalDetailButton.secondaryUpdatedLessThanAMinute");
        hasUpdateInfo = true;
      }

      if (hasUpdateInfo) {
        iconPrimary = <StatusIcon fontSize="small" sx={{ mr: 1 }} />;
      }
    }

    return (
      <Typography component="span" variant="body2" sx={{ verticalAlign: "middle", display: "inline-flex" }}>
        {iconPrimary}
        {statusTextPrimary}
      </Typography>
    );
  };

  const getGroupcolor = () => {
    if (data.group) {
      return data.group.color;
    }
    return "secondary.light";
  };

  return (
    <React.Fragment key={"ADB"+data.id}>
      <ListItem
        alignItems="flex-start"
        selected={selected}
        onClick={(event) => onClick(data, index)}
        key={data.id}
      >
        <ListItemAvatar>
          <Avatar
            alt={data.name}
            variant="square"
            sx={{
              backgroundColor: getGroupcolor(),
            }}
          >
            {data.abbreviation}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={data.name} secondary={getSecondary()} />
      </ListItem>
      <Divider component="li" key={data.id + "d"} />
    </React.Fragment>
  );
}
