import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { Provider } from "react-redux";
import store from "./store";

const firebaseConfig = {
  apiKey: "AIzaSyA9bekhOSDOKqIyRoB4he2cYwBfBk9hPcc",
  authDomain: "gulya-ch-frontend.firebaseapp.com",
  databaseURL: "https://gulya-ch-frontend-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "gulya-ch-frontend",
  storageBucket: "gulya-ch-frontend.appspot.com",
  messagingSenderId: "31453771344",
  appId: "1:31453771344:web:6eca79fed8be37d86c0355",
  measurementId: "G-R8D8T0SF9F",
};

const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore();
export const functions = getFunctions(firebaseApp, 'europe-west6');
/*
export const functions = getFunctions();
connectFunctionsEmulator(functions, "localhost", 5001); */

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
