import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles((theme) => ({
  mainArea: {
    color: theme.palette.text.primary,
    background: theme.palette.grey.A200,
    height: "100%",
    minWidth: 500,
  },
}));

export default function DetailsErrorPage() {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.mainArea}
      flexGrow={1}
    >
      <Box m="auto" display="flex"
      flexDirection="column" sx={{color:"grey.A700", fontSize:20}}>
        <ErrorOutlineIcon
          sx={{
            color: "grey.A700",
            background: "grey.A200",
            height: "100%",
            maxHeight: 300,
            minWidth: 500,
          }}
        />
        {t("generic.detailsError")}
      </Box>
    </Box>
  );
}
