import * as React from "react";
//import * as Bluetooth from "react-bluetooth";
import { useTranslation } from "react-i18next";
import SingleRowDetailIconButton from "../common/detailViewComponents/singleRowDetailIconButton";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import SearchIcon from "@mui/icons-material/Search";

//https://github.com/EvanBacon/react-bluetooth
//https://www.npmjs.com/package/react-bluetooth
//https://web.dev/bluetooth/

//
//AT 52840 121116226261181281205370247130163618022085
//AT 52840 16170237148101041101452288917016423811011868


export default function DeviceScannerDetailView(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const [device, setDevice] = React.useState();

  const getAnyDevice = async () => {
    navigator.bluetooth
      .requestDevice({ acceptAllDevices: true, optionalServices: ["battery_service"] })
      .then((device) => {
        setDevice(device);
        console.log(device);
        device.addEventListener("gattserverdisconnected", console.log("disconnected"));
        console.log("GATT Connect");
        return device.gatt.connect();
      })
      .then((server) => {
        console.log("Getting Battery Service…");
        return server.getPrimaryService("battery_service");
      })
      .then((service) => {
        console.log("Getting Battery Level Characteristic");
        return service.getCharacteristic("battery_level");
      })
      .then((characteristic) => {
        console.log("Set up event listener for when characteristic value changes.");
        characteristic.addEventListener("characteristicvaluechanged", console.log("Battery level changed"));
        console.log("Reading Battery Level…");
        return characteristic.readValue();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <SingleRowDetailIconButton
        buttonText={t("deviceScanner.scan")}
        onClick={(event) => getAnyDevice()}
        buttonIcon={<SearchIcon />}
        text={navigator.bluetooth ? t("deviceScanner.scanHint") : t("deviceScanner.noScan")}
        disabled={navigator.bluetooth ? false : true}
        isPassive={navigator.bluetooth ? false : true}
      >
        <BluetoothIcon />
      </SingleRowDetailIconButton>
      {device ? device.name : ""}
      {"   "}
      {device ? Buffer.from(device.id, "base64").toString('hex').toUpperCase().replace(/(.{2})/g,"$1:") : ""}
    </React.Fragment>
  );
}
