import { getDocs, collection, query } from "firebase/firestore";
import { db } from "..";

export const getConfigAction = async () => {
  let queryResultArray = [];
  const ref = collection(db, "config");
  const q = query(ref);
  const queryResult = await getDocs(q);
  queryResult.forEach((config) => {
    queryResultArray.push(config.data());
  });

  if (queryResultArray.length!==1) {
    throw "More than one config record!";
  }

  return queryResultArray[0];
};
