import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { themeLogin } from "../../theme";
import { ReactComponent as GulyaLogo } from "../../assets/logo.svg";
import { useParams } from "react-router";
import { useEffect } from "react";

function Copyright(props) {
  return (
    <Typography component={"span"} variant="body2" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://gulya.ch/">
        gulya.ch
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  langSelector: {
    maxHeight: "15pt",
    maxWidth: "20pt",
    fontSize: "10pt",
    color: "white",
    "&&": {
      marginRight: 10,
      textTransform: "lowercase",
      borderRadius: 10,
    },
  },
}));

export default function LoginError(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const theme = createTheme(themeLogin);
  const navigate = useNavigate();
  const { reason } = useParams();

  useEffect(() => {
    document.title = "GULYA.CH - "+ t("login.pageTitle");
  }, [t]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const getErrorText = () => {
    if (!reason) {
      navigate("/login");
    }

    return "login." + reason + "Failure";
  };

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main">
        <Grid container justifyContent="flex-end">
          <Button className={classes.langSelector} variant="contained" onClick={() => changeLanguage("de")}>
            de
          </Button>
          <Button className={classes.langSelector} variant="contained" onClick={() => changeLanguage("en")}>
            en
          </Button>
          <Button className={classes.langSelector} variant="contained" onClick={() => changeLanguage("hu")}>
            hu
          </Button>
        </Grid>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <GulyaLogo style={{maxHeight:"30vh"}}/>
          <Typography
            variant="h5"
            sx={{
              mt: 2,
            }}
          >
            {t("login.welcome")}
          </Typography>
          <Box sx={{ mt: 1, maxWidth:"50%"}}>
            <Box textAlign="center" sx={{ mt: 4, mb:4 }}>
              <Typography variant="p" >{t(getErrorText())}</Typography>
            </Box>
            <Button color="secondary" fullWidth variant="contained" sx={{ color: "gulyagold.main" }} onClick={() => navigate("/login")}>
              {t("login.back")}
            </Button>
            <Grid container justifyContent="center">
              <Copyright sx={{ mt: 0, mb: 4 }} />
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
