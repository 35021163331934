import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Grid } from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 0,
  backgroundColor: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
  },
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.text.primary,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: theme.palette.text.primary,
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
    fontSize: 15,
  },
}));

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: 12,
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 0,
    },
  },
}));

export default function FilterList(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const setEmpty = () => {
    return (
      <Grid container display="flex" flexDirection="column" flexGrow={1}>
        <Grid item
          sx={{
            mt: 5,
            mr: 2,
            ml: 2,
            color: "grey.A700",
            justifyContent: "center",
            textAlign: "center"
          }}
        >
          <InfoOutlinedIcon sx={{ fontSize: 60 }} />
        </Grid>
        <Grid item m="auto" display="flex" flexDirection="column" sx={{ mt: 2, mr: 2, ml: 2, color: "grey.A700", fontSize: 16, textAlign: "center" }}>
          {t("generic.filterNoItems")}
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment key={"FLI"}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={props.searchLabel}
          inputProps={{ "aria-label": props.searchLabel }}
          onChange={(event) => {
            props.onFilterChange(event.target.value);
          }}
        />
      </Search>
      <List component="nav" style={{ maxHeight: "100vh", overflow: "auto", width:"100%", paddingTop:1 }}>
        {!props.children ? setEmpty() : ""}
        {props.children}
      </List>
    </React.Fragment>
  );
}
