import * as React from "react";
import Grid from "@mui/material/Grid";
import GenericButtonIcon from "../genericButtonIcon";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import PaymentIcon from "@mui/icons-material/Payment";
import { useTranslation } from "react-i18next";

export default function InvoicePaymentBottomBar(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  return (
    <Grid container spacing={0} sx={{ width: "100%", mt: "auto", backgroundColor: "grey.A200" }}>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={12} md={5} sx={{ mb: "1px" }}>
        <GenericButtonIcon buttonText={t("invoiceViewMode.goBack")} buttonNav={"/invoices/"} orientation="left">
          <ArrowCircleLeftIcon />
        </GenericButtonIcon>
      </Grid>
      <Grid item xs={0} md={1}></Grid>
      <Grid item xs={12} md={5} sx={{ mb: "1px" }}>
        {props.paymentEnabled ? (
          <GenericButtonIcon
            buttonText={t("invoiceViewMode.pay")}
            onClick={(event) => props.onAbortClick(event)} 
            orientation="left"
            color="primary"
          >
            <PaymentIcon />
          </GenericButtonIcon>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}
