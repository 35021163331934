import * as React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import GenericButtonIcon from "../genericButtonIcon";
import Grid from "@mui/material/Grid";
import ReportIcon from '@mui/icons-material/Report';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Typography from "@mui/material/Typography";
import { DialogContent } from "@mui/material";
import { useMediaQuery } from "@mui/material";

export default function ErrorDialog(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const { onClose, open, errorText } = props;
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const handleClose = (event) => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen={isSmallScreen}
      PaperProps={{
        style: {
          backgroundColor: "background.default",
          color: "text.primary",
          boxShadow: "none",
          maxWidth: 800,
          width: 550,
          borderRadius: 0,
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={4} alignItems="center" justify="center">
          <Grid item xs={12} sm={3}>
            <ReportIcon sx={{ fontSize: 130, textAlign:"center" }} />
          </Grid>
          <Grid item xs={0} sm={1}></Grid>
          <Grid item xs={12} sm={8}>
            <Grid container item>
              <Grid item xs={12}>
                <Typography component="span" variant="h4" sx={{ verticalAlign: "middle", display: "inline" }}>
                  {t("errorDialog.title")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="span" variant="body2" sx={{ verticalAlign: "middle", display: "inline" }}>
                  {errorText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <GenericButtonIcon
              buttonText={t("errorDialog.close")}
              onClick={(event) => handleClose(event)}
              orientation="left"
              color="primary"
            >
              <ThumbUpAltIcon />
            </GenericButtonIcon>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
