import * as React from "react";
import TextSnippetSharpIcon from "@mui/icons-material/TextSnippetSharp";
import DangerousIcon from "@mui/icons-material/Dangerous";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { updateContractAction } from "../../actions/contractActions";
import { convertStringToDate } from "../../actions/actions";

export const getContractDataMap = () => {
  return {
    fieldData: [
      {
        icon: <TextSnippetSharpIcon sx={{ fontSize: 60, mr: 2, mt: 2, color: "text.primary" }} />,
        fields: [
          {
            hideOnForm: true,
            name: "id",
            label: "contract.id",
            helperText: "contract.idHelperText",
            validation: {},
          },
          {
            type: "date",
            name: "startDate",
            label: "contract.startDate",
            helperText: "contract.startDateHelperText",
            validation: { mandatory: true },
          },
          {
            type: "date",
            name: "endDate",
            label: "contract.endDate",
            helperText: "contract.endDateHelperText",
            validation: {},
          },
          {
            type: "text",
            name: "conditions",
            label: "contract.conditions",
            helperText: "contract.conditionsHelperText",
            validation: { limit: 240, mandatory: true },
          },
        ],
      },
    ],
    actions: [
      {
        name: "terminate",
        label: "contract.terminate",
        icon: <DangerousIcon fontSize="medium" />,
        statuses: [1, 2, 3],
        action: async (object) => {
          object.isSuspended = false;
          object.endDate = new Date().toISOString().split("T")[0];
          await updateContractAction(object);
        },
      },
      {
        name: "suspend",
        label: "contract.suspend",
        icon: <PauseCircleFilledIcon fontSize="medium" />,
        statuses: [1, 3],
        action: async (object) => {
          object.isSuspended = true;
          await updateContractAction(object);
        },
      },
      {
        name: "reinstate",
        label: "contract.reinstate",
        icon: <PlayCircleFilledWhiteIcon fontSize="medium" />,
        statuses: [2],
        action: async (object) => {
          object.isSuspended = false;
          await updateContractAction(object);
        },
      },
      {
        name: "createInvoice",
        label: "contract.createInvoice",
        icon: <MonetizationOnIcon fontSize="medium" />,
        statuses: [1, 2, 3],
        navAction: (object) => {
          return "/create/invoice/"+object.customerId;
        },
      },
    ],
  };
};

export const getContractStatus = (contract) => {
  if (!contract.startDate) {
    return 3;
  }
  if (contract.isSuspended) {
    return 2;
  }

  let startDate = convertStringToDate(contract.startDate);
  let endDate = convertStringToDate(contract.endDate);
  let today = new Date();

  if (startDate <= today) {
    if (endDate > today || endDate == null) {
      return 1;
    }
  }

  return 0;
};

