import * as React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import GenericButtonIcon from "../genericButtonIcon";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import PanToolIcon from "@mui/icons-material/PanTool";
import Typography from "@mui/material/Typography";
import { DialogContent } from "@mui/material";
import { useMediaQuery } from "@mui/material";

export default function ConfirmationDialog(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const { onClose, open, onConfirm } = props;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onClose();
    onConfirm();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen={isSmallScreen}
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
          color: "text.primary",
          boxShadow: "none",
          maxWidth: 800,
          width: 550,
          borderRadius: 0,
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={4} alignItems="center" justify="center">
          <Grid item xs={0} sm={1}></Grid>
          <Grid item xs={12} sm={4}>
            <PanToolIcon sx={{ fontSize: 130 }} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <Grid container item>
              <Grid item xs={12}>
                <Typography component="span" variant="h4" sx={{ verticalAlign: "middle", display: "inline" }}>
                  {props.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="span" variant="body2" sx={{ verticalAlign: "middle", display: "inline" }}>
                  {props.text}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericButtonIcon buttonText={t("confirmDialog.close")} onClick={(event) => handleClose(event)} orientation="left">
              <ArrowCircleLeftIcon />
            </GenericButtonIcon>
          </Grid>
          <Grid item xs={12} sm={6}>
            <GenericButtonIcon
              buttonText={t("confirmDialog.delete")}
              onClick={(event) => handleConfirm(event)}
              orientation="left"
              color="error"
            >
              <DeleteForeverIcon />
            </GenericButtonIcon>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
