import * as React from "react";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import PaidIcon from "@mui/icons-material/Paid";
import { BlobStream } from "swissqrbill";
import { updateInvoiceAction } from "../../actions/invoiceActions";
import { convertStringToDate } from "../../actions/actions";
import { getCustomerByIdAction } from "../../actions/customerActions";
import i18n from "i18next";
import { getConfigAction } from "../../actions/configActions";
import { PDF } from "swissqrbill/lib/browser/bundle/index.js";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";

export const invoiceItemOptions = [
  { displayOrder: 0, name: "pastDueSurcharge", label: "invoice.items.pastDueSurcharge" },
  { displayOrder: 1, name: "initialPayment", label: "invoice.items.initialPayment" },
  { displayOrder: 2, name: "lostHardware", label: "invoice.items.lostHardware" },
  { displayOrder: 3, name: "damagedHardware", label: "invoice.items.damagedHardware" },
  { displayOrder: 4, name: "feeMonth", label: "invoice.items.feeMonth" },
  { displayOrder: 5, name: "feeMonthDiscount", label: "invoice.items.feeMonthDiscount" },
  { displayOrder: 6, name: "hardwareFeeMonth", label: "invoice.items.hardwareFeeMonth" },
  { displayOrder: 7, name: "hardwareFeeDiscount", label: "invoice.items.hardwareFeeDiscount" },
  { displayOrder: 8, name: "simCardMonth", label: "invoice.items.simCardMonth" },
  { displayOrder: 9, name: "simCardDiscount", label: "invoice.items.simCardDiscount" },
  { displayOrder: 10, name: "iPadMonth", label: "invoice.items.iPadMonth" },
  { displayOrder: 11, name: "iPadDiscount", label: "invoice.items.iPadDiscount" },
];

export const getInvoiceDataMap = () => {
  return {
    fieldData: [
      {
        icon: <MonetizationOnIcon sx={{ fontSize: 60, mr: 2, mt: 2, color: "text.primary" }} />,
        fields: [
          {
            hideOnForm: true,
            name: "nr",
            type: "index",
            label: "invoice.nr",
            helperText: "invoice.nrHelperText",
            validation: {},
          },
          {
            type: "date",
            name: "issueDate",
            label: "invoice.issueDate",
            helperText: "invoice.issueDateHelperText",
            validation: { mandatory: true },
          },
          {
            type: "date",
            name: "dueDate",
            label: "invoice.dueDate",
            helperText: "invoice.dueDateHelperText",
            validation: { mandatory: true },
          },
          {
            type: "date",
            name: "payDate",
            label: "invoice.payDate",
            helperText: "invoice.payDateHelperText",
            validation: {},
          },
          {
            type: "date",
            name: "revokeDate",
            label: "invoice.revokeDate",
            helperText: "invoice.revokeDateHelperText",
            validation: {},
          },
          {
            hideOnForm: true,
            name: "payRemark",
            type: "object",
            validation: {},
          },
          {
            hideOnForm: true,
            type: "amount",
            name: "amount",
            label: "invoice.amount",
            helperText: "invoice.amountHelperText",
            validation: {},
            calculateValue: (invoice) => {
              let amount = 0;
              for (let invoiceItem in invoice.table) {
                let result =
                  (invoice.table[invoiceItem].vat + 1.0) * invoice.table[invoiceItem].unitPrice * invoice.table[invoiceItem].quantity;
                amount += result;
              }
              return Math.round((amount + Number.EPSILON) * 100) / 100;
            },
          },
        ],
      },
    ],
    table: {
      label: "invoice.items",
      helperText: "invoice.itemsHelperText",
      columns: [
        {
          name: "description",
          label: "invoice.items.description",
          type: "select",
          options: invoiceItemOptions,
          validation: { mandatory: true },
        },
        { name: "quantity", label: "invoice.items.quantity", type: "number", validation: { mandatory: true, numeric: true } },
        { name: "unitPrice", label: "invoice.items.unitPrice", type: "amount", validation: { mandatory: true, numeric: true } },
        {
          name: "amount",
          label: "invoice.items.amount",
          type: "amount",
          calculateValue: (invoiceItem) => {
            if (!invoiceItem.unitPrice || !invoiceItem.quantity) {
              return "-";
            }
            let result = invoiceItem.unitPrice * invoiceItem.quantity;
            return isNaN(result) ? "-" : Math.round((result + Number.EPSILON) * 100) / 100;
          },
          readOnly: true,
        },
        {
          name: "vat",
          label: "invoice.items.vat",
          type: "percentage",
          readOnly: true,
          default: 0.077,
        },
        {
          name: "vatAmount",
          label: "invoice.items.vatAmount",
          type: "amount",
          calculateValue: (invoiceItem) => {
            if (!invoiceItem.vat || !invoiceItem.unitPrice || !invoiceItem.quantity) {
              return "-";
            }
            let result = invoiceItem.vat * invoiceItem.unitPrice * invoiceItem.quantity;
            return isNaN(result) ? "-" : Math.round((result + Number.EPSILON) * 100) / 100;
          },
          readOnly: true,
        },
      ],
      additionalRows: [
        {
          columns: [
            {},
            {},
            {},
            {},
            { label: "invoice.sum" },
            {
              calculateValue: (table) => {
                let sum = 0;
                if (table) {
                  table.forEach((invoiceItem, index) => {
                    sum += invoiceItem.unitPrice * invoiceItem.quantity;
                  });
                  return isNaN(sum) || sum === 0
                    ? "- CHF"
                    : (Math.round((sum + Number.EPSILON) * 100) / 100).toLocaleString(getLocale(), { minimumFractionDigits: 2 }) + " CHF";
                }
                return "- CHF";
              },
            },
          ],
        },
        {
          columns: [
            {},
            {},
            {},
            {},
            { label: "invoice.vatAmount" },
            {
              calculateValue: (table) => {
                let vatAmount = 0;
                if (table) {
                  table.forEach((invoiceItem, index) => {
                    vatAmount += invoiceItem.unitPrice * invoiceItem.quantity * invoiceItem.vat;
                  });

                  return isNaN(vatAmount) || vatAmount === 0
                    ? "- CHF"
                    : (Math.round((vatAmount + Number.EPSILON) * 100) / 100).toLocaleString(getLocale(), { minimumFractionDigits: 2 }) +
                        " CHF";
                }
                return "- CHF";
              },
            },
          ],
        },
        {
          highlighted: true,
          columns: [
            {},
            {},
            {},
            {},
            { label: "invoice.total" },
            {
              calculateValue: (table) => {
                let total = 0;
                if (table) {
                  table.forEach((invoiceItem, index) => {
                    total += invoiceItem.unitPrice * invoiceItem.quantity * (invoiceItem.vat + 1.0);
                  });
                  return isNaN(total) || total === 0
                    ? "- CHF"
                    : (Math.round((total + Number.EPSILON) * 100) / 100).toLocaleString(getLocale(), { minimumFractionDigits: 2 }) + " CHF";
                }
                return "- CHF";
              },
            },
          ],
        },
      ],
    },
    actions: [
      {
        name: "revoke",
        label: "invoice.revoke",
        icon: <CancelIcon fontSize="medium" />,
        statuses: [2, 3],
        action: async (object) => {
          object.revokeDate = new Date().toISOString().split("T")[0];
          delete object["payDate"];
          await updateInvoiceAction(object);
        },
      },
      {
        name: "paid",
        label: "invoice.paid",
        icon: <CheckCircleIcon fontSize="medium" />,
        statuses: [2, 3],
        action: async (object) => {
          object.payDate = new Date().toISOString().split("T")[0];
          await updateInvoiceAction(object);
        },
      },
      {
        name: "view",
        label: "invoice.view",
        icon: <PaidIcon fontSize="medium" />,
        statuses: [0, 1, 2, 3],
        navAction: (object) => {
          return "/view/invoice/" + object.id;
        },
      },
      {
        name: "showBill",
        label: "invoice.showBill",
        icon: <DownloadForOfflineIcon fontSize="medium" />,
        statuses: [1, 2, 3],
        action: async (invoice) => {
          await generateDoc(invoice);
        },
      },
    ],
    functions: {
      getViewModeStatusMessage: (invoice) => {
        let status = getInvoiceStatus(invoice);
        if (status === 0) {
          return {
            type: "info",
            label: i18n.t("invoice.revokedStatusMessage") + " " + invoice.revokeDate,
            icon: <InfoIcon sx={{ mr: 1, fontSize: 18, verticalAlign: "middle" }} />,
          };
        } else if (status === 1) {
          return {
            type: "info",
            label: i18n.t("invoice.paidStatusMessage") + " " + invoice.payDate,
            icon: <InfoIcon sx={{ mr: 1, fontSize: 18, verticalAlign: "middle" }} />,
          };
        } else if (status === 2) {
          return {
            type: "info",
            label: i18n.t("invoice.openStatusMessage") + " " + invoice.dueDate,
            icon: <InfoIcon sx={{ mr: 1, fontSize: 18, verticalAlign: "middle" }} />,
          };
        } else if (status === 3) {
          return {
            type: "error",
            label: i18n.t("invoice.pastDueStatusMessage") + " " + invoice.dueDate,
            icon: <WarningIcon sx={{ mr: 1, fontSize: 18, verticalAlign: "middle" }} />,
          };
        }
        return {};
      },
    },
  };
};

export const getInvoiceStatus = (invoice) => {
  if (invoice.revokeDate) {
    return 0;
  }

  if (!invoice.dueDate) {
    return 3;
  }

  let dueDate = convertStringToDate(invoice.dueDate);
  let payDate = convertStringToDate(invoice.payDate);
  let today = new Date();

  if (payDate) {
    return 1;
  }

  if (dueDate < today) {
    return 3;
  } else {
    return 2;
  }
};

export const getLanguage = () => {
  let lang = i18n.language || (typeof window !== "undefined" && window.localStorage.i18nextLng) || "DE";
  lang = lang.toUpperCase();
  if (lang === "FR" || lang === "DE" || lang === "EN" || lang === "IT") {
    return lang;
  }
  return "DE";
};

export const getLocale = () => {
  let lang = i18n.language || (typeof window !== "undefined" && window.localStorage.i18nextLng) || "DE";
  lang = lang.toUpperCase();
  if (lang === "EN") {
    return "en-US";
  }
  if (lang === "HU") {
    return "hu-HU";
  }
  return "de-CH";
};

const mm2pt = (millimeters) => {
  return millimeters * 0.83465;
};

export const generateDoc = async (invoice) => {
  await getConfigAction().then((config) => {
    getCustomerByIdAction(invoice.customerId).then((result) => {
      let vat = 0;
      let sum = 0;
      if (invoice.table) {
        invoice.table.forEach((invoiceItem, index) => {
          vat = invoiceItem.vat;
          sum += invoiceItem.unitPrice * invoiceItem.quantity;
        });
      }

      const data = {
        currency: "CHF",
        amount: Math.round((sum * (vat + 1.0) + Number.EPSILON) * 100) / 100, //this is terrible
        message: invoice.id,
        creditor: {
          name: config.companyName,
          address: config.companyAddress,
          buildingNumber: config.companyBuildingNumber,
          zip: config.companyZip,
          city: config.companyCity,
          account: config.companyAccount,
          country: config.companyCountry,
        },
        debtor: {
          name: result.name,
          address: result.companyAddress.street,
          buildingNumber: result.companyAddress.number,
          zip: result.companyAddress.zip,
          city: result.companyAddress.city,
          country: result.companyAddress.countryCode.slice(-2), //this is terrible
        },
      };

      const stream = new BlobStream();
      const pdf = new PDF(data, stream, { autoGenerate: false, size: "A4", scissors: true, language: getLanguage() });

      //-- Add logo

      const logoPart1 =
        "M26.943 47.34C35.079 47.176 34.078 41.832 33.961 40.809 33.82 39.578 33.2 36.952 33.142 36.275 33.085 35.599 32.983 35.236 33.463 34.744 33.647 34.555 34.133 34.355 35.543 33.206 35.843 32.962 38.936 34.184 41.353 34.33 43.769 34.475 46.687 32.468 45.87 31.534 44.926 30.454 45.065 30.057 43.625 29.325 42.185 28.594 39.812 28.569 39.995 28.224 40.178 27.88 40.844 26.915 41.714 24.472 42.585 22.029 42.764 18.221 42.592 17.463 42.419 16.704 42.122 16.667 41.752 17.217 41.381 17.768 40.989 19.709 39.451 22.306 37.913 24.902 34.184 25.662 34.184 25.662 32.611 25.992 26.966 26.056 26.966 26.056L27.035 26.056C27.035 26.056 21.39 25.992 19.817 25.662 19.817 25.662 16.087 24.902 14.549 22.306 13.011 19.709 12.619 17.768 12.248 17.217 11.878 16.667 11.582 16.704 11.409 17.463 11.236 18.221 11.416 22.029 12.286 24.472 13.156 26.915 13.822 27.88 14.005 28.224 14.189 28.569 11.816 28.594 10.375 29.325 8.935 30.057 9.074 30.454 8.13 31.534 7.313 32.468 10.231 34.475 12.648 34.33 15.065 34.184 18.158 32.962 18.458 33.206 19.868 34.355 20.353 34.555 20.537 34.744 21.017 35.236 20.916 35.599 20.858 36.275 20.801 36.952 20.181 39.578 20.039 40.809 19.922 41.832 18.921 47.176 27.058 47.34";
      const logoPart2 =
        "M26.579 10.829 28.825 10.829 28.829 14.344 32.344 14.344 32.344 17.86 28.832 17.861 28.824 21.375 26.579 21.37 27.422 21.37 25.177 21.375 25.169 17.861 21.656 17.86 21.656 14.344 25.172 14.344 25.175 10.829 27.422 10.829";
      const logoPart3 = "M55 50V10H57V50";
      pdf.addPath(logoPart1, mm2pt(40), mm2pt(14)).fillColor("#000000").fill();
      pdf.addPath(logoPart2, mm2pt(40), mm2pt(14)).fillColor("#000000").fill();
      pdf.addPath(logoPart3, mm2pt(40), mm2pt(14)).fillColor("#000000").fill();

      pdf.fontSize(26).font("Helvetica").fillColor("#000000").text("GULYA.CH", 102, 33);
      pdf.moveDown();

      //-- Add creditor address

      pdf.fontSize(8).font("Helvetica-Bold").fillColor("#000000");
      pdf.text(
        config.companyName +
          " - " +
          config.companyAddress +
          " " +
          config.companyBuildingNumber +
          " - " +
          config.companyZip +
          " " +
          config.companyCity,
        mm2pt(45),
        mm2pt(85),
        {
          width: mm2pt(300),
          height: mm2pt(20),
          align: "left",
        }
      );

      //-- Add debtor address

      pdf.fontSize(12);
      pdf.font("Helvetica-Bold");
      pdf.text(
        result.name +
          "\n" +
          result.companyAddress.street +
          " " +
          result.companyAddress.number +
          "\n" +
          result.companyAddress.zip +
          " " +
          result.companyAddress.city,
        mm2pt(45),
        mm2pt(130),
        {
          width: mm2pt(200),
          height: mm2pt(100),
          align: "left",
        }
      );

      //-- Add title

      pdf.fontSize(14);
      pdf.font("Helvetica-Bold");
      pdf.text(i18n.t("invoice.paymentSlip.title") + invoice.nr, mm2pt(45), mm2pt(210), {
        width: mm2pt(400),
        align: "left",
      });

      //-- Add table

      const table = {
        width: mm2pt(630),
        x: mm2pt(40),
        rows: [
          {
            height: 20,
            fillColor: "#ECF0F1",
            columns: [
              {
                text: i18n.t("invoice.paymentSlip.tableNr"),
                width: mm2pt(25),
              },
              {
                text: i18n.t("invoice.paymentSlip.tableDescription"),
              },
              {
                text: i18n.t("invoice.paymentSlip.tableQuantity"),
                width: mm2pt(75),
              },
              {
                text: i18n.t("invoice.paymentSlip.tableUnitPrice"),
                width: mm2pt(120),
              },
              {
                text: i18n.t("invoice.paymentSlip.tableAmount"),
                width: mm2pt(120),
              },
            ],
          },
        ],
      };

      if (invoice.table) {
        invoice.table.forEach((invoiceItem, index) => {
          let row = { columns: [] };
          row.columns.push({ text: index + 1 + ".", width: mm2pt(25) });
          row.columns.push({ text: i18n.t(invoiceItem.description) });
          row.columns.push({ text: invoiceItem.quantity, width: mm2pt(75) });
          row.columns.push({
            text: (invoiceItem.unitPrice * 1.0).toLocaleString(getLocale(), { minimumFractionDigits: 2 }) + " CHF",
            width: mm2pt(120),
          });
          row.columns.push({
            text:
              (Math.round((invoiceItem.unitPrice * invoiceItem.quantity + Number.EPSILON) * 100) / 100).toLocaleString(getLocale(), {
                minimumFractionDigits: 2,
              }) + " CHF",
            width: mm2pt(120),
          });
          table.rows.push(row);
        });

        table.rows.push({
          height: 40,
          columns: [
            {
              text: "",
              width: mm2pt(25),
            },
            {
              text: "",
            },
            {
              text: "",
              width: mm2pt(75),
            },
            {
              text: i18n.t("invoice.paymentSlip.tableSum"),
              font: "Helvetica-Bold",
              width: mm2pt(120),
            },
            {
              text: (Math.round((sum + Number.EPSILON) * 100) / 100).toLocaleString(getLocale(), { minimumFractionDigits: 2 }) + " CHF",
              font: "Helvetica-Bold",
              width: mm2pt(120),
            },
          ],
        });

        table.rows.push({
          columns: [
            {
              text: "",
              width: mm2pt(25),
            },
            {
              text: "",
            },
            {
              text: "",
              width: mm2pt(75),
            },
            {
              text: i18n.t("invoice.paymentSlip.tableVat"),
              width: mm2pt(120),
            },
            {
              text: vat * 100.0 + " %",
              width: mm2pt(120),
            },
          ],
        });

        table.rows.push({
          columns: [
            {
              text: "",
              width: mm2pt(55),
            },
            {
              text: "",
            },
            {
              text: "",
              width: mm2pt(75),
            },
            {
              text: i18n.t("invoice.paymentSlip.tableVatAmount"),
              width: mm2pt(120),
            },
            {
              text:
                (Math.round((sum * vat + Number.EPSILON) * 100) / 100).toLocaleString(getLocale(), { minimumFractionDigits: 2 }) + " CHF",
              width: mm2pt(120),
            },
          ],
        });

        table.rows.push({
          columns: [
            {
              height: 40,
              text: "",
              width: mm2pt(55),
            },
            {
              text: "",
            },
            {
              text: "",
              width: mm2pt(75),
            },
            {
              text: i18n.t("invoice.paymentSlip.tableTotal"),
              width: mm2pt(120),
              font: "Helvetica-Bold",
            },
            {
              text:
                (Math.round((sum * (vat + 1.0) + Number.EPSILON) * 100) / 100).toLocaleString(getLocale(), { minimumFractionDigits: 2 }) +
                " CHF",
              width: mm2pt(120),
              font: "Helvetica-Bold",
            },
          ],
        });
      }

      pdf.addTable(table);

      let invoiceDate = new Date(invoice.issueDate);
      let invoiceDueDate = new Date(invoice.dueDate);

      pdf.fontSize(11);
      pdf.font("Helvetica");
      pdf.text(
        config.companyCity +
          ", " +
          invoiceDate.getDate() +
          "." +
          (invoiceDate.getMonth() + 1) +
          "." +
          invoiceDate.getFullYear() +
          ". " +
          i18n.t("invoice.paymentSlip.dueTimeWarning") +
          invoiceDueDate.getDate() +
          "." +
          (invoiceDueDate.getMonth() + 1) +
          "." +
          invoiceDueDate.getFullYear() +
          ".",
        mm2pt(370),
        mm2pt(620),
        {
          width: mm2pt(300),
          align: "right",
        }
      );

      pdf.addQRBill();
      pdf.end();

      pdf.on("finish", () => {
        window.location.href = stream.toBlobURL("application/pdf");
      });
    });
  });
};
