import * as React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import GenericButtonIcon from "../genericButtonIcon";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

export default function AssignmentViewBottomBar(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line

  return (
    <Grid
      container
      spacing={0}
      sx={{ width: "100%", mt: "auto", backgroundColor: "grey.A400" }}
    >
      <Grid item xs={0} md={7}></Grid>
      <Grid item xs={12} md={5} sx={{mb:"1px"}}>
        <GenericButtonIcon
          buttonText={t("generic.goBack")}
          buttonNav={props.navBack}
          orientation="left"
          color="primary"
        >
          <ArrowCircleLeftIcon />
        </GenericButtonIcon>
      </Grid>
    </Grid>
  );
}
