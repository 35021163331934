import * as React from "react";
import CasinoIcon from "@mui/icons-material/Casino";

export const plainGroupObject = {
  id: null,
  customerId: null,
  name: null,
  color: null,
  members: null,
};

export const getGroupDataMap = () => {
  return {
    fieldData: [
      {
        icon: "groupAvatar",
        fields: [
          {
            name: "name",
            label: "group.name",
            helperText: "group.nameHelperText",
            validation: { mandatory: true, limit: 30 },
          },
          {
            type: "button",
            name: "color",
            label: "groupEditMode.generateColor",
            icon: <CasinoIcon />,
          },
        ],
      },
    ],
  };
};
