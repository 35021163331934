import * as React from "react";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import CallIcon from "@mui/icons-material/Call";
import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";

export const getCustomerDataMap = () => {
  return {
    fieldData: [
      {
        icon: <AssignmentIndIcon sx={{ fontSize: 60, mr: 2, mt: 2, color: "text.primary" }} />,
        fields: [
          {
            name: "name",
            label: "customerEditMode.name",
            helperText: "customerEditMode.nameHelper",
            validation: { mandatory: true, limit: 30 },
          },
        ],
      },
      {
        icon: <CallIcon sx={{ fontSize: 60, mr: 2, mt: 2, color: "text.primary" }} />,
        fields: [
          {
            name: "contactPerson.firstName",
            label: "customerEditMode.contactPersonFirstName",
            helperText: "customerEditMode.contactPersonFirstNameHelper",
            validation: { mandatory: true, limit: 20 },
          },
          {
            name: "contactPerson.lastName",
            label: "customerEditMode.contactPersonLastName",
            helperText: "customerEditMode.contactPersonLastNameHelper",
            validation: { mandatory: true, limit: 20 },
          },
          {
            name: "contactPerson.email",
            label: "customerEditMode.contactPersonEmail",
            helperText: "customerEditMode.contactPersonEmailHelper",
            validation: { mandatory: true, email: true, limit: 30 },
          },
          {
            name: "contactPerson.phone",
            label: "customerEditMode.contactPersonPhone",
            helperText: "customerEditMode.contactPersonPhoneHelper",
            validation: { mandatory: true, phone: true, limit: 20 },
          },
        ],
      },
      {
        icon: <SettingsAccessibilityIcon sx={{ fontSize: 60, mr: 2, mt: 2, color: "text.primary" }} />,
        fields: [
          {
            type: "checkbox",
            name: "owner.noOwnerData",
            label: "customerEditMode.noOwnerData",
            helperText: "customerEditMode.noOwnerDataHelper",
            validation: { mandatory: false },
            default: true
          },          
          {
            name: "owner.firstName",
            label: "customerEditMode.ownerFirstName",
            helperText: "customerEditMode.ownerFirstNameHelper",
            validation: { limit: 20 },
            displayDependency: "owner.noOwnerData"
          },
          {
            name: "owner.lastName",
            label: "customerEditMode.ownerLastName",
            helperText: "customerEditMode.ownerLastNameHelper",
            validation: { limit: 20 },
            displayDependency: "owner.noOwnerData"
          },
          {
            name: "owner.email",
            label: "customerEditMode.ownerEmail",
            helperText: "customerEditMode.ownerEmailHelper",
            validation: { email: true, limit: 30 },
            displayDependency: "owner.noOwnerData"
          },
          {
            name: "owner.phone",
            label: "customerEditMode.ownerPhone",
            helperText: "customerEditMode.ownerPhoneHelper",
            validation: { phone: true, limit: 20 },
            displayDependency: "owner.noOwnerData"
          },
        ],
      },
      {
        icon: <MailIcon sx={{ fontSize: 60, mr: 2, mt: 2, color: "text.primary" }} />,
        fields: [
          {
            name: "mailingAddress.addressLine1",
            label: "customerEditMode.maAddressLine1",
            helperText: "customerEditMode.maAddressLine1Helper",
            validation: { mandatory: true, limit: 30 },
          },
          {
            name: "mailingAddress.addressline2",
            label: "customerEditMode.maAddressLine2",
            helperText: "customerEditMode.maAddressLine2Helper",
            validation: { limit: 30 },
          },
          {
            type: "select",
            options: ["countryCode.ch"],
            name: "mailingAddress.countryCode",
            label: "customerEditMode.maCountryCode",
            helperText: "customerEditMode.maCountryCodeHelper",
            validation: { mandatory: true },
          },
          {
            name: "mailingAddress.city",
            label: "customerEditMode.maCity",
            helperText: "customerEditMode.maCityHelper",
            validation: { mandatory: true, limit: 30 },
          },
          {
            name: "mailingAddress.zip",
            label: "customerEditMode.maZip",
            helperText: "customerEditMode.maZipHelper",
            validation: { mandatory: true, limit: 4, numeric: true },
          },
          {
            name: "mailingAddress.street",
            label: "customerEditMode.maStreet",
            helperText: "customerEditMode.maStreetHelper",
            validation: { mandatory: true, limit: 30 },
          },
          {
            name: "mailingAddress.number",
            label: "customerEditMode.maNumber",
            helperText: "customerEditMode.maNumberHelper",
            validation: { mandatory: true, limit: 20 },
          },
        ],
      },
      {
        icon: <HomeIcon sx={{ fontSize: 60, mr: 2, mt: 2, color: "text.primary" }} />,
        fields: [
          {
            type: "checkbox",
            name: "companyAddress.caSameAsMa",
            label: "customerEditMode.caSameAsMa",
            helperText: "customerEditMode.caSameAsMaHelper",
            validation: { mandatory: false },
            default: true
          },   
          {
            type: "select",
            options: ["countryCode.ch"],
            name: "companyAddress.countryCode",
            label: "customerEditMode.caCountryCode",
            helperText: "customerEditMode.caCountryCodeHelper",
            validation: { mandatory: true },
            displayDependency: "companyAddress.caSameAsMa"
          },
          {
            name: "companyAddress.city",
            label: "customerEditMode.caCity",
            helperText: "customerEditMode.caCityHelper",
            validation: { mandatory: true, limit: 30 },
            displayDependency: "companyAddress.caSameAsMa"
          },
          {
            name: "companyAddress.zip",
            label: "customerEditMode.caZip",
            helperText: "customerEditMode.caZipHelper",
            validation: { mandatory: true, limit: 4, numeric: true },
            displayDependency: "companyAddress.caSameAsMa"
          },
          {
            name: "companyAddress.street",
            label: "customerEditMode.caStreet",
            helperText: "customerEditMode.caStreetHelper",
            validation: { mandatory: true, limit: 30 },
            displayDependency: "companyAddress.caSameAsMa"
          },
          {
            name: "companyAddress.number",
            label: "customerEditMode.caNumber",
            helperText: "customerEditMode.caNumberHelper",
            validation: { mandatory: true, limit: 20 },
            displayDependency: "companyAddress.caSameAsMa"
          },
        ],
      },
      
    ],
  };
};
