import { doc, getDocs, collection, getDoc, updateDoc, query, addDoc, orderBy, where } from "firebase/firestore";
import { db } from "..";
import { convertStringToDate } from "./actions";
import { invoiceItemOptions } from "../components/invoices/invoiceDataMap";

export const updateInvoiceAction = async (invoice) => {
  const custRef = doc(db, "customers", invoice.customerId);
  const ref = doc(db, "invoices", invoice.id);
  invoice.customerId = custRef;
  await updateDoc(ref, invoice);
  invoice.customerId = custRef.id;
};

export const createInvoiceAction = async (invoice) => {
  invoice.nr = await getNewInvoiceNumber();
  const custRef = doc(db, "customers", invoice.customerId);
  const ref = collection(db, "invoices");
  delete invoice.id;
  invoice.customerId = custRef;
  await addDoc(ref, invoice);
};

export const getInvoiceByIdAction = async (id) => {
  const ref = doc(db, "invoices", id);
  const invoice = await getDoc(ref);
  if (invoice.exists()) {
    let data = invoice.data();
    data.id = invoice.id;
    data.customerId = invoice.data().customerId.id;
    return sortInvoiceItemsByDisplayOrder(data);
  }
  return null;
};

const sortInvoiceItemsByDisplayOrder = (invoice) => {
  if (invoice.table) {
    let sortMap = invoice.table.map((item) => {
      let displayOrder = 0;
      invoiceItemOptions.forEach((option) => {
        if (item.description === option.label) {
          displayOrder = option.displayOrder;
        }
      });
      return { displayOrder: displayOrder, item };
    });
    sortMap.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1);

    let resultTable = sortMap.map((item) => {
      return item.item;
    });
    invoice.table = resultTable;
  }
  return invoice;
};

export const getNewInvoiceNumber = async () => {
  const q = query(collection(db, "invoices"), orderBy("nr", "desc"));
  const queryResult = await getDocs(q);
  if (queryResult.empty) {
    return 0;
  }
  return queryResult.docs[0].data().nr + 1;
};

export const getAllInvoicesAction = async () => {
  let queryResultArray = [];
  const q = query(collection(db, "invoices"), orderBy("dueDate", "desc"));
  const queryResult = await getDocs(q);
  queryResult.forEach((invoice) => {
    let result = invoice.data();
    result.id = invoice.id;
    result.customerId = invoice.data().customerId.id;
    queryResultArray.push(sortInvoiceItemsByDisplayOrder(result));
  });

  return queryResultArray;
};

export const getInvoicesStatus = async (customerId) => {
  const custRef = doc(db, "customers", customerId);
  const ref = collection(db, "invoices");
  const q = query(ref, where("customerId", "==", custRef));
  const queryResult = await getDocs(q);
  let hasUnpaidInvoices = false;
  let hasOpenInvoices = false;
  let hasData = false;
  queryResult.forEach((invoice) => {
    hasData = true;
    let invoiceData = invoice.data();
    let dueDate = convertStringToDate(invoiceData.dueDate);
    if (new Date() > dueDate && invoiceData.payDate == null && !invoiceData.revokeDate) {
      hasUnpaidInvoices = true;
    } else if (new Date() <= dueDate && invoiceData.payDate == null && !invoiceData.revokeDate) {
      hasOpenInvoices = true;
    }
  });
  return hasUnpaidInvoices ? 3 : hasOpenInvoices ? 2 : hasData ? 1 : 0;
};
