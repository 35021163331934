export const themeDark = {
  palette: {
    mode: "dark",
    primary: {
      dark: "#bb812a",
      main: "#8A5F1F",
      light: "#bb812a",
    },
    secondary: {
      dark: "#777777",
      main: "#565656",
      light: "#777777",
    },
    error: {
      dark: "#c51114",
      main: "#960d0f",
      light: "#c51114",
    },
    success: {
      main: "#5A7DA5",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
      disabled: "#666666",
      divider: "#ffffff",
    },
    background: {
      paper: "#292929",
      default: "#292929",
      light: "#565656",
    },
    divider: "#292929",
    action: {
      selectedOpacity: 0.5,
    },
    grey: {
      50: "#292929",
      A100: "#2E2E2E",
      A200: "#383838",
      A400: "#404040",
      A700: "#666666",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: "none",
          shadows: "none",
          height: 40,
          width: "100%",
          color:"#ffffff"
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: "#383838",
          "&:hover": {
            backgroundColor: "#666666",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "2px solid #383838",
        },
        head: {
          backgroundColor: "#404040",
          borderBottom: "5px solid #383838",
          fontWeight: "bold",
          fontSize: 16,
        },
        body: {
          backgroundColor: "#404040",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.MuiTableRow-hover": {
            backgroundColor: "#666666", //TODO not working
            cursor: "pointer",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#565656",
          verticalAlign: "middle",
          display: "inline-flex",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#292929",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          textAlign: "left",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color: "white",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          "&::-webkit-calendar-picker-indicator": {
            filter: "invert(1)",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&$checked": {
            color: "white",
          },
        },
      },
    },
  },
};

export const themeBright = {
  palette: {
    mode: "light",
    primary: {
      dark: "#b88942",
      main: "#bb812a",
      light: "#b88942",
    },
    secondary: {
      dark: "#727272",
      main: "#888888", 
      light: "#727272",
    },
    error: {
      dark: "#cc5356",
      main: "#cc3336",
      light: "#cc5356",
    },
    success: {
      main: "#7b97b7",
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
      disabled: "#909090",
      divider: "#000000",
    },
    background: {
      paper: "#A0A0A0",
      default: "#A0A0A0",
      light: "#959595",
    },
    divider: "#A0A0A0",
    action: {
      selectedOpacity: 0.5,
    },
    grey: {
      50: "#A0A0A0",
      A100: "#B0B0B0",
      A200: "#C0C0C0",
      A400: "#D0D0D0",
      A700: "#E0E0E0",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: "none",
          shadows: "none",
          height: 40,
          width: "100%",
          color:"#000000"
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "black",
          backgroundColor: "#C0C0C0",
          "&:hover": {
            backgroundColor: "#A0A0A0",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "2px solid #C0C0C0",
        },
        head: {
          backgroundColor: "#C2C2C2",
          borderBottom: "5px solid #C0C0C0",
          fontWeight: "bold",
          fontSize: 16,
        },
        body: {
          backgroundColor: "#C2C2C2",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.MuiTableRow-hover": {
            backgroundColor: "#A0A0A0", //TODO not working
            cursor: "pointer",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#959595",
          verticalAlign: "middle",
          display: "inline-flex",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#A0A0A0",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          textAlign: "left",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color: "black",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          "&::-webkit-calendar-picker-indicator": {
            filter: "invert(1)",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&$checked": {
            color: "black",
          },
        },
      },
    },
  },
};

export const themeLogin = {
  palette: {
    mode: "dark",
    background: {
      default: "#8A5F1F",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#FFFFFF",
    },
    primary: {
      dark: "#666666",
      main: "#8A5F1F",
      light: "#666666",
    },
    secondary: {
      dark: "#888888",
      main: "#FFFFFF",
      light: "#888888",
    },
    error: {
      dark: "#c51114",
      main: "#960d0f",
      light: "#c51114",
    },
    gulyagold: {
      main: "#8A5F1F",
    },
  },
  typography: {
    textAlign: "center",
    fontFamily: ["Futura"].join(","),
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          textTransform: "lowercase",
          fontSize: 16,
          marginTop: 15,
          marginBottom: 15,
        },
      },
    },
  },
};
