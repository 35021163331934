import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Menu from "../menu";
import FilterList from "../filterList";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GenericButtonIcon from "../genericButtonIcon";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { Typography } from "@mui/material";
import { Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TableViewBottomBar from "../bottomBars/tableViewBottomBar";
import AllItemsDetailButton from "../allItemsDetailButton";
import { getAllObjectsOfEntityAction } from "../../../actions/actions";
import { Backdrop } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Grid } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useMediaQuery } from "@mui/material";
import { getAuth } from "firebase/auth";

export default function GenericTablePage(props) {
  const {
    pageTitle,
    pageSubTitle,
    addLabel,
    addUrl,
    searchLabel,
    detailButton,
    dataMap,
    goBackText,
    goBackNav,
    manageText,
    manageNav,
    filterEntityName,
    tableEntityName,
    getObjectStatus,
  } = props;
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selected, setSelected] = useState();
  const [filterText, setFilterText] = useState("");
  const [allObjects, setAllObjects] = useState([]);
  const [allTableObjects, setAllTableObjects] = useState([]);
  const [isBackdropOpen, setBackdropOpen] = useState(true);
  const [mobileModeShowDetails, toggleMobileModeShowDetails] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [isAdmin, setAdmin] = useState(false);
  const [currentUserCustomerId, setCurrentUserCustomerId] = useState();

  const useStyles = makeStyles((theme) => ({
    root: {
      textAlign: "center",
      fontFamily: ["Futura"].join(","),
      minHeight: "100vh",
      maxWidth: "100vw",
      backgroundColor: theme.palette.background.default,
    },
    listView: {
      background: theme.palette.grey.A100,
      paddingRight: "1px",
      paddingLeft: "1px",
      height: isSmallScreen || isMediumScreen ? "calc(100vh - 38px)" : "100vh",
      width: "100%",
    },
    mainView: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey.A200,
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    getAuth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        setAdmin(token.claims.admin);
        setCurrentUserCustomerId(token.claims.customerId);
      })
      .catch((e) => {
        navigate("/error/500/GTP-004");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  const handleSelectionChange = (node, index, triggeredByInit) => {
    if (!triggeredByInit) {
      toggleMobileModeShowDetails(true);
    }
    setSelectedIndex(index);
    setSelected(node);
    navigate("/" + tableEntityName + "/" + node.id);
  };

  const load = () => {
    getAllObjectsOfEntityAction(filterEntityName)
      .then((result) => {
        setAllObjects(result);
      })
      .catch((e) => {
        navigate("/error/500/GTP-000-" + filterEntityName.toUpperCase().replace("/", "-"));
      });

    getAllObjectsOfEntityAction(tableEntityName)
      .then((result) => {
        setAllTableObjects(result);
        setBackdropOpen(false);
      })
      .catch((e) => {
        navigate("/error/500/GTP-001-" + tableEntityName.toUpperCase().replace("/", "-"));
      });
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilteredItems = () => {
    return allObjects.filter((node, i) => {
      return node.name.toLowerCase().includes(filterText.toLowerCase());
    });
  };

  useEffect(() => {
    if (!id && allObjects.length !== 0) {
      handleSelectionChange(allObjects[0], 0, true);
    }

    if (id && currentUserCustomerId && !isAdmin && id !== currentUserCustomerId) {
      navigate("/error/403/GTP-003-" + tableEntityName.toUpperCase().replace("/", "-"));
      return;
    }

    if (id === "all" && allObjects.length !== 0) {
      handleSelectionChange(allObjects[0], 0, true);
    } else if (allObjects.length !== 0) {
      let index = 0;
      let results = [];
      getFilteredItems().forEach((node, i) => {
        if (node.id === id) {
          index = i;
          results.push(node);
        }
      });
      if (results.length === 1) {
        handleSelectionChange(results[0], index + 1, ["customers/contracts", "customers/users"].includes(tableEntityName) ? false : true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allObjects]);

  useEffect(() => {
    document.title = "GULYA.CH - " + t(pageSubTitle) + (selected && selected.name ? " (" + selected.name + ")" : "");
  }, [pageSubTitle, t, selected]);

  const handleFilterChange = (filterText) => {
    setFilterText(filterText);
  };

  const generateDetailButtons = (nodes) => {
    let filteredItems = getFilteredItems();
    if (filteredItems.length === 0) {
      return "";
    }

    let elements = [];

    let dummyNode = <AllItemsDetailButton />;
    elements.push(
      React.cloneElement(dummyNode, {
        key: "xxdummy",
        index: 0,
        selected: selectedIndex === 0,
        onClick: (node, index) => handleSelectionChange(node, index),
      })
    );

    //injecting detail button with internal props
    elements.push(
      filteredItems.map((node, index) =>
        React.cloneElement(detailButton, {
          key: node.id,
          data: node,
          index: index + 1,
          selected: selectedIndex === index + 1,
          onClick: (node, index) => handleSelectionChange(node, index),
        })
      )
    );

    return elements;
  };

  const generateTableHead = () => {
    let cells = dataMap().fieldData.map((section, index) => {
      return section.fields.map((field, fieldIndex) => {
        if (field.type && field.type === "text") {
          return (
            <TableCell key={"hd" + index + fieldIndex} align="left" sx={{ fontSize: 14 }}>
              {t(field.label)}
            </TableCell>
          );
        }
        if (field.type && field.type === "table") {
          return "";
        } else if (field.type && field.type === "object") {
          return "";
        }
        return (
          <TableCell key={"hd" + index + fieldIndex} align="center" sx={{ fontSize: 14 }}>
            {t(field.label)}
          </TableCell>
        );
      });
    });
    if (dataMap().actions) {
      cells.push(
        <TableCell key={"hdr"} align="center" sx={{ fontSize: 14 }}>
          {t("tableView.actionsHeader")}
        </TableCell>
      );
    }
    return <TableRow key="hdrrow">{cells}</TableRow>;
  };

  const getBackroundColor = (object) => {
    if (!getObjectStatus) {
      return;
    }
    let status = getObjectStatus(object);
    switch (status) {
      case 0: //gray
        return;
      case 1: //gold
        return "primary.main";
      case 2: //blue
        return "success.main";
      case 3: //red
        return "error.main";
      default:
        return;
    }
  };

  const isActionVisible = (object, statuses) => {
    if (!isAdmin) {
      return false;
    }
    if (!getObjectStatus) {
      return true;
    }
    if (!statuses) {
      return true;
    }

    let status = getObjectStatus(object);
    return statuses.includes(status);
  };

  const generateTableBody = (isMobile) => {
    let tableData;
    if (selected.id === "all") {
      tableData = allTableObjects;
    } else {
      tableData = allTableObjects.filter((node) => node.customerId === selected.id);
    }

    if (tableData.length === 0) {
      let columnCount = 0;
      dataMap().fieldData.forEach((section) => {
        section.fields.forEach(() => {
          columnCount++;
        });
      });
      if (dataMap().actions) {
        columnCount++;
      }

      return (
        <TableRow key="errrow">
          <TableCell key={"err"} colSpan={columnCount} align="center">
            <Box
              sx={{
                mt: 5,
                mr: 2,
                ml: 2,
                color: "grey.A700",
              }}
            >
              <InfoOutlinedIcon sx={{ fontSize: 200 }} />
            </Box>
            <Box sx={{ m: 2, color: "grey.A700", fontSize: 30 }}>{t("generic.filterNoItems")}</Box>
          </TableCell>
        </TableRow>
      );
    }

    return tableData.map((object, tbIndex) => {
      let cells = dataMap().fieldData.map((section, index) => {
        return section.fields.map((field, fieldIndex) => {
          let toReturn;
          if (field.type && field.type === "date") {
            if (object[field.name]) {
              toReturn = (
                <TableCell key={field.name + tbIndex + index} align="center" sx={{ backgroundColor: getBackroundColor(object) }}>
                  {object[field.name]}
                </TableCell>
              );
            }
          }
          if (field.type && field.type === "text") {
            if (object[field.name]) {
              toReturn = (
                <TableCell
                  key={field.name + tbIndex + index}
                  align="left"
                  sx={
                    isMobile
                      ? { backgroundColor: getBackroundColor(object), width: "100%" }
                      : { backgroundColor: getBackroundColor(object) }
                  }
                >
                  {object[field.name]}
                </TableCell>
              );
            }
          }
          if (field.name === "id") {
            if (isMobile) {
              toReturn = (
                <TableCell key={field.name + tbIndex + index} align="center" sx={{ backgroundColor: getBackroundColor(object) }}>
                  {object[field.name]}
                </TableCell>
              );
            } else {
              toReturn = (
                <Tooltip title={t(object[field.name])} placement="top">
                  <TableCell key={field.name + tbIndex + index} align="left" sx={{ backgroundColor: getBackroundColor(object) }}>
                    {object[field.name] ? object[field.name].substring(0, 6) + "..." : ""}
                  </TableCell>
                </Tooltip>
              );
            }
          }
          if (field.type && field.type === "table") {
            return "";
          } else if (field.type && field.type === "object") {
            return "";
          }
          if (field.type && field.type === "amount") {
            toReturn = (
              <TableCell
                key={field.name + tbIndex + index}
                align="right"
                sx={
                  isMobile ? { backgroundColor: getBackroundColor(object), width: "100%" } : { backgroundColor: getBackroundColor(object) }
                }
              >
                {(object[field.name] ? t(object[field.name]) : field.calculateValue(object)).toLocaleString(getLocale(), {
                  minimumFractionDigits: 2,
                }) + " CHF"}
              </TableCell>
            );
          }

          if (!toReturn) {
            toReturn = (
              <TableCell
                key={field.name + tbIndex + index}
                align="center"
                sx={
                  isMobile ? { backgroundColor: getBackroundColor(object), width: "100%" } : { backgroundColor: getBackroundColor(object) }
                }
              >
                {t(object[field.name])}
              </TableCell>
            );
          }

          if (isMobile) {
            toReturn = (
              <TableRow key={"rw" + field.name + tbIndex + index}>
                <TableCell
                  key={"hd" + field.name + tbIndex + index}
                  align="left"
                  sx={{ fontSize: 14, backgroundColor: getBackroundColor(object), borderBottom: "none", minWidth:"30%"}}
                >
                  {t(field.label)}:
                </TableCell>
                {toReturn}
              </TableRow>
            );
          }

          return toReturn;
        });
      });

      if (dataMap().actions) {
        let actionButtons = dataMap().actions.map((action, index) => {
          if (!isActionVisible(object, action.statuses)) {
            return "";
          }
          return (
            <Tooltip title={t(action.label)} placement="bottom">
              <IconButton
                onClick={(event) => {
                  if (action.action) {
                    setBackdropOpen(true);
                    action
                      .action(object)
                      .then(() => {
                        setBackdropOpen(false);
                        load();
                      })
                      .catch((e) => {
                        navigate("/error/500/GTP-002");
                      });
                  } else if (action.navAction) {
                    navigate(action.navAction(object));
                  }
                }}
                sx={{ color: "text.primary"}}
              >
                {action.icon}
              </IconButton>
            </Tooltip>
          );
        });

        let toPush = (
          <TableCell key={"act" + tbIndex} align="center" sx={{ backgroundColor: getBackroundColor(object) }}>
            <Box display="flex" flexDirection="row" justifyContent="center">
              {actionButtons}
            </Box>
          </TableCell>
        );

        if (isMobile) {
          toPush = (
            <TableRow key={"rw" + "act" + tbIndex}>
              <TableCell key={"actblank" + tbIndex} sx={{ backgroundColor: getBackroundColor(object) }} />
              {toPush}
            </TableRow>
          );
        }

        cells.push(toPush);
      }
      return (
        <TableRow key={"row" + tbIndex} hover>
          {cells}
        </TableRow>
      );
    });
  };

  const getLocale = () => {
    let lang = i18n.language || (typeof window !== "undefined" && window.localStorage.i18nextLng) || "DE";
    lang = lang.toUpperCase();
    if (lang === "EN") {
      return "en-US";
    }
    if (lang === "HU") {
      return "hu-HU";
    }
    return "de-CH";
  };

  if (!selected) {
    handleSelectionChange({ id: "all" }, 0, true);
    return;
  }
  if (isAdmin) {
    return (
      <Grid container display="flex" flexDirection="row" className={classes.root}>
        <Grid item container display="flex" flexDirection="column" xs={12} sm={12} md={4} lg={3} xl={3}>
          <Box
            flexDirection="column"
            sx={{
              height: 36,
              display: { xs: mobileModeShowDetails ? "none" : "flex", md: "none" },
              backgroundColor: "primary.main",
              mb: "1px",
              mr: "1px",
              color: "text.primary",
            }}
          >
            <Typography component={"span"} variant="h6" gutterBottom sx={{ mt: "3px" }}>
              {t(pageTitle)}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            sx={{ width: "100%", display: { xs: mobileModeShowDetails ? "none" : "flex", md: "flex" } }}
          >
            <Box display="flex" flexDirection="column" sx={{ maxWidth: 75 }}>
              <Menu />
            </Box>
            <Box display="flex" flexDirection="column" className={classes.listView}>
              <GenericButtonIcon buttonText={t(addLabel)} buttonNav={addUrl} orientation="left" height="small">
                <AddCircleIcon />
              </GenericButtonIcon>
              <Divider />
              <FilterList onSelectionChange={handleSelectionChange} onFilterChange={handleFilterChange} searchLabel={t(searchLabel)}>
                {generateDetailButtons()}
              </FilterList>
            </Box>
          </Box>
        </Grid>
        <Grid item container display="flex" flexDirection="column" xs={12} sm={12} md={8} lg={8} xl={6} className={classes.mainView}>
          <Box sx={{ display: { xs: mobileModeShowDetails ? "block" : "none", md: "block" } }}>
            <Grid item container display="flex" flexDirection="column" sx={{ minHeight: "100vh", width: "100%" }}>
              <Typography component={"span"} sx={{ color: "text.primary", fontSize: 29, textAlign: "left", ml: 1, mb: 4 }}>
                {t(pageSubTitle)}
              </Typography>
              <Box sx={{ maxWidth: "100vw", display: { xs: "none", md: "flex" } }}>
                <TableContainer size="small">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>{generateTableHead()}</TableHead>
                    <TableBody>{generateTableBody(false)}</TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ maxWidth: "100vw", display: { xs: "flex", md: "none" } }}>
                <TableContainer size="small">
                  <Table aria-label="sticky table">
                    <TableBody>{generateTableBody(true)}</TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <TableViewBottomBar
                abortText={t(goBackText)}
                abortNav={goBackNav ? goBackNav + selected.id : null}
                saveText={t(manageText)}
                saveNav={manageNav ? manageNav + selected.id : "null"}
                isSaveDisabled={id === "all" ? true : false}
              />
              <Box sx={{ display: { xs: mobileModeShowDetails ? "block" : "none", md: "none" } }}>
                <GenericButtonIcon
                  buttonText={t("mobileMode.backToList")}
                  onClick={(event) => {
                    toggleMobileModeShowDetails(false);
                    window.scrollTo(0, 0);
                  }}
                  orientation="left"
                  color="success"
                >
                  <MenuOpenIcon />
                </GenericButtonIcon>
              </Box>
              <Backdrop sx={{ color: "text.primary", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isBackdropOpen}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container display="flex" flexDirection="row" className={classes.root}>
        <Grid item container display="flex" flexDirection="column" xs={12} sm={12} md={12} lg={11} xl={9}>
          <Box
            flexDirection="column"
            sx={{
              height: 36,
              display: { xs: "flex", md: "none" },
              backgroundColor: "primary.main",
              mb: "1px",
              mr: "1px",
              color: "text.primary",
            }}
          >
            <Typography component={"span"} variant="h6" gutterBottom sx={{ mt: "3px" }}>
              {t(pageTitle)}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" sx={{ width: "100%", display: "flex" }}>
            <Box display="flex" flexDirection="column" sx={{ maxWidth: 75 }}>
              <Menu />
            </Box>
            <Grid
              item
              container
              display="flex"
              flexDirection="column"
              sx={{ minHeight: "100vh", width: "100%" }}
              className={classes.mainView}
            >
              <Typography component={"span"} sx={{ color: "text.primary", fontSize: 29, textAlign: "left", ml: 1, mb: 4 }}>
                {t(pageSubTitle)}
              </Typography>
              <Box sx={{ maxWidth: "100vw", display: { xs: "none", md: "flex" } }}>
                <TableContainer size="small">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>{generateTableHead()}</TableHead>
                    <TableBody>{generateTableBody(false)}</TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ maxWidth: "100vw", display: { xs: "flex", md: "none" } }}>
                <TableContainer size="small">
                  <Table aria-label="sticky table">
                    <TableBody>{generateTableBody(true)}</TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Backdrop sx={{ color: "text.primary", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isBackdropOpen}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
