import * as React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import SingleRowDetailIconColorStripe from "../common/detailViewComponents/singleRowDetailIconColorStripe";
import DoubleRowDetailIconButton from "../common/detailViewComponents/doubleRowDetailIconButton";
import DoubleRowDetailIconButtonMap from "../common/detailViewComponents/doubleRowDetailIconButtonMap";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import DetailViewBottomBar from "../common/bottomBars/detailViewBottomBar";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ConfirmationDialog from "../common/dialogs/confirmationDialog";
import { useState } from "react";
import { ReactComponent as GulyaLogo } from "../../assets/logo.svg";
import { ReactComponent as GulyaLogoBlack } from "../../assets/logoBlack.svg";
import { SvgIcon } from "@mui/material";
import { useNavigate } from "react-router";
import { useMediaQuery } from "@mui/material";

export default function GroupDetails(props) {
  const { data } = props;
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const [isDialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");

  let beaconCount = 0;

  if (data.members) {
    const filteredItems = data.members.filter((node, i) => {
      return node.hasBeacon === true;
    });
    beaconCount = filteredItems.length;
  }

  const getDeleteConfirmation = () => {
    setDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setDialogOpen(false);
  };

  const handleNavigate = (target) => {
    navigate(target, { state: { isRedirect: true } });
  };

  return (
    <React.Fragment key="GDET">
      <Typography component={"span"} sx={{ fontSize: 29, textAlign: "left", ml: 1, mb: 4 }}>
        {t("groupDetails.subTitle")}
      </Typography>
      <SingleRowDetailIconColorStripe
        text={t("groupDetails.groupPrefix") + data.name + t("groupDetails.groupSuffix")}
        stripeColor={data.color}
      >
        <GroupWorkIcon />
      </SingleRowDetailIconColorStripe>
      <DoubleRowDetailIconButton
        buttonText={t("groupDetails.manageMembers")}
        onClick={() => handleNavigate("/groups/members/" + data.id)}
        buttonIcon={<ArrowCircleRightIcon />}
        text={
          !data.members
            ? t("groupDetails.noMembers")
            : data.members.length === 0
            ? t("groupDetails.noMembers")
            : data.members.length === 1
            ? data.members.length + t("groupDetails.memberCountSigular")
            : data.members.length + t("groupDetails.memberCountPlural")
        }
        statusIcon={beaconCount === 0 ? <InfoOutlinedIcon /> : <NotificationsActiveIcon />}
        statusText={
          beaconCount === 0
            ? t("groupDetails.noBeacons")
            : beaconCount === 1
            ? beaconCount + t("groupDetails.oneBeacon")
            : beaconCount + t("groupDetails.multipleBeacons")
        }
        stripeColor={data.color}
      >
        <SvgIcon>{isDarkModeEnabled ? <GulyaLogo /> : <GulyaLogoBlack />}</SvgIcon>
      </DoubleRowDetailIconButton>
      <DoubleRowDetailIconButtonMap
        locationData={data.lastLocation}
        //         locationData={{ latitude: 33.03123, longitude: 33.00321, altitude: 1234 }}
      />
      <DetailViewBottomBar
        deleteText={t("groupDetails.delete")}
        onDeleteClick={getDeleteConfirmation}
        editText={t("groupDetails.edit")}
        editNav={"/edit/group/" + data.id}
      />
      <ConfirmationDialog
        title={t("groupDetails.confirmDeleteTitle")}
        text={t("groupDetails.confirmDeleteText")}
        open={isDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={() => props.onDelete()}
      />
    </React.Fragment>
  );
}
