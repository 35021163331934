import * as React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import GenericButtonIcon from "../genericButtonIcon";
import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  listItemText: {
    color: theme.palette.text.primary,
    verticalAlign: "middle",
    display: "inline-flex",
  },
  listItemPassiveText: {
    color: theme.palette.text.disabled,
    verticalAlign: "middle",
    display: "inline-flex",
  },
}));

export default function MultiRowDetailIconButton(props) {
  const classes = useStyles();

  const renderProps = () => {
    if (!props.children) {
      return "";
    }

    let renderedItems = props.children.map((text, index) => {
      if (!text || text === "") {
        return "";
      }

      return (
        <Grid container item key={"GTP" + index} xs={1} alignItems="left">
          <Typography
            key={"TP" + index}
            component={"span"}
            className={props.isPassive ? classes.listItemPassiveText : classes.listItemText}
            variant="h7"
            gutterBottom
          >
            {text}
          </Typography>
        </Grid>
      );
    });

    return renderedItems;
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      sx={{ backgroundColor: "grey.A400", border: 1, borderColor: "grey.A200" }}
      key={Math.floor(Math.random() * 100000)}
    >
      <Grid item xs={2} sm={1} sx={{ mt: 2 }}>
        {React.cloneElement(props.icon, {
          style: { width: 56, height: 56, fontSize: 56 },
        })}
      </Grid>
      <Grid container item xs={8} sm={5} sx={{ mt: 1, mb: 1 }}>
        <Grid container direction="column" spacing={0} sx={{ width: "100%" }}>
          {renderProps()}
        </Grid>
      </Grid>
      <Grid item xs={0} sm={1}></Grid>
      <Grid item xs={1} sm={5} sx={{ mt: 2, mb: 2, paddingRight: 2 }}>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <GenericButtonIcon
            key={"GBI"}
            buttonText={props.buttonText}
            buttonNav={props.buttonNav}
            onClick={(event) => props.onClick(event)}
            orientation="right"
          >
            {props.buttonIcon}
          </GenericButtonIcon>
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          {props.buttonNav ? (
            <IconButton key={"GIB1"} component={NavLink} to={props.buttonNav} disabled={props.disabled}>
              {props.buttonIcon
                ? React.cloneElement(props.buttonIcon, {
                    style: { width: 36, height: 36, fontSize: 36 },
                  })
                : ""}
            </IconButton>
          ) : (
            <IconButton ey={"GIB2"} onClick={props.onClick} disabled={props.disabled}>
              {props.buttonIcon
                ? React.cloneElement(props.buttonIcon, {
                    style: { width: 36, height: 36, fontSize: 36 },
                  })
                : ""}
            </IconButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
