import * as React from "react";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockResetIcon from "@mui/icons-material/LockReset";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  deleteUserAction,
  kickUserOutAction,
  reinstateUserAction,
  resetPasswordToDefaultAction,
  suspendUserAction
} from "../../actions/userInfoActions";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const getUserDataMap = () => {
  return {
    fieldData: [
      {
        icon: <ContactPageIcon sx={{ fontSize: 60, mr: 2, mt: 2, color: "text.primary" }} />,
        fields: [
          {
            hideOnForm: true,
            name: "id",
            label: "user.id",
            helperText: "user.idHelperText",
            validation: {},
          },
          {
            name: "fullName",
            label: "user.fullName",
            helperText: "user.fullNameHelperText",
            validation: { mandatory: true },
          },
          {
            readOnlyOnEdit: true,
            name: "email",
            label: "user.email",
            helperText: "user.emailHelperText",
            validation: { email: true, mandatory: true },
          },
          {
            type: "select",
            options: ["languageCode.de", "languageCode.en", "languageCode.hu"],
            name: "language",
            label: "user.language",
            helperText: "user.languageHelperText",
            validation: { mandatory: true },
          },
          {
            hideOnForm: true,
            type: "date",
            name: "lastLogin",
            label: "user.lastLogin",
            helperText: "user.lastLoginHelperText",
            validation: {},
          },
        ],
      },
    ],
    actions: [
      {
        name: "block",
        label: "user.block",
        icon: <NoAccountsIcon fontSize="medium" />,
        statuses: [1],
        action: async (object) => {
          object.isSuspended = true;
          await suspendUserAction(object);
        },
      },
      {
        name: "unblock",
        label: "user.unblock",
        icon: <AccountCircleIcon fontSize="medium" />,
        statuses: [2],
        action: async (object) => {
          object.isSuspended = false;
          await reinstateUserAction(object);
        },
      },
      {
        name: "edit",
        label: "user.edit",
        icon: <EditIcon fontSize="medium" />,
        statuses: [1, 2, 3],
        navAction: (object) => {
          return "/edit/user/" + object.id;
        },
      },
      {
        name: "reset",
        label: "user.reset",
        icon: <LockResetIcon fontSize="medium" />,
        action: async (object) => {
          await resetPasswordToDefaultAction(object.id);
        },
      },
      {
        name: "reauthenticate",
        label: "user.reauthenticate",
        icon: <ExitToAppIcon fontSize="medium" />,
        statuses: [1, 2, 3],
        action: async (object) => {
          await kickUserOutAction(object.id);
        },
      },
      {
        name: "delete",
        label: "user.delete",
        icon: <DeleteForeverIcon fontSize="medium" />,
        action: async (object) => {
          await deleteUserAction(object);
        },
      },
    ],
  };
};

export const getUserStatus = (user) => {
  if (user.isSuspended) {
    return 2;
  }

  return 1;
};
