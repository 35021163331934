
export const plainAnimalObject = {
  id: null,
  customerId: null,
  name: null,
  abbreviation: null,
  group: null,
  device: null,
};

export const getAnimalDataMap = () => {
  return {
    fieldData: [
      {
        icon: "animalAvatar",
        fields: [
          {
            name: "name",
            label: "animal.name",
            helperText: "animal.nameHelperText",
            validation: { mandatory: true, limit: 30 },
          },
          {
            name: "abbreviation",
            label: "animal.abbreviation",
            helperText: "animal.abbreviationHelperText",
            validation: { mandatory: true, limit: 3 },
          },
        ],
      },
    ],
  };
};
