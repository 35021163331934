import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { themeLogin } from "../../theme";
import { ReactComponent as GulyaLogo } from "../../assets/logo.svg";
import { getAuth, updatePassword, signInWithEmailAndPassword } from "firebase/auth";
import { useState, useEffect } from "react";
import { updateEmailVerifiedAction } from "../../actions/userInfoActions";

function Copyright(props) {
  return (
    <Typography component={"span"} variant="body2" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://gulya.ch/">
        gulya.ch
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  inputField: {
    textAlign: "center",
    fontSize: "12pt",
    "& label.Mui-focused": {},
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: 1,
        borderColor: "white",
        borderRadius: 15,
      },
    },
  },
  langSelector: {
    maxHeight: "15pt",
    maxWidth: "20pt",
    fontSize: "10pt",
    color: "white",
    "&&": {
      marginRight: 10,
      textTransform: "lowercase",
      borderRadius: 10,
    },
  },
}));

export default function PwChangePage(props) {
  const { t, i18n } = useTranslation(); // eslint-disable-line
  const theme = createTheme(themeLogin);
  const navigate = useNavigate();
  const [pw1ErrorText, setPw1ErrorText] = useState();
  const [pw1, setPw1] = useState();
  const [pw2ErrorText, setPw2ErrorText] = useState();
  const classes = useStyles();

  useEffect(() => {
    document.title = "GULYA.CH - " + t("pwChange.pageTitle");
  }, [t]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const isPw1Valid = (password) => {
    if (!password || password.trim() === "") {
      setPw1ErrorText("pwChange.pwMandatory");
      return false;
    }
    if (password.length < 8) {
      setPw1ErrorText("pwChange.pwTooShort");
      return false;
    }
    setPw1(password);
    setPw1ErrorText(null);
    return true;
  };

  const isPw2Valid = (password2) => {
    if (!password2 || password2.trim() === "") {
      setPw2ErrorText("pwChange.pwMandatory");
      return false;
    }
    if (password2 !== pw1) {
      setPw2ErrorText("pwChange.pwMismatch");
      return false;
    }
    setPw2ErrorText(null);
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let isPw1 = isPw1Valid(data.get("password1"));
    let isPw2 = isPw2Valid(data.get("password1"), data.get("password2"));
    if (!(isPw1 && isPw2)) {
      return;
    } else {
      updatePassword(getAuth().currentUser, pw1)
        .then(() => {
          updateEmailVerifiedAction(getAuth().currentUser.uid, true).then(() => {
            getAuth().signOut();
            signInWithEmailAndPassword(getAuth(), getAuth().currentUser.email, pw1).then(() => {
              navigate("/");
            });
          });
        })
        .catch((error) => {
          getAuth().signOut();
          navigate("/login/fail/technicalPwChange");
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main">
        <Grid container justifyContent="flex-end">
          <Button className={classes.langSelector} variant="contained" onClick={() => changeLanguage("de")}>
            de
          </Button>
          <Button className={classes.langSelector} variant="contained" onClick={() => changeLanguage("en")}>
            en
          </Button>
          <Button className={classes.langSelector} variant="contained" onClick={() => changeLanguage("hu")}>
            hu
          </Button>
        </Grid>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <GulyaLogo style={{ maxHeight: "30vh" }} />
          <Typography
            variant="h5"
            sx={{
              mt: 2,
            }}
          >
            {t("pwChange.welcome")}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              className={classes.inputField}
              color="secondary"
              margin="normal"
              required
              fullWidth
              variant="outlined"
              id="password1"
              type="password"
              inputProps={{
                min: 0,
                style: {
                  textAlign: "center",
                  fontSize: 16,
                },
              }}
              label={t("pwChange.password1")}
              name="password1"
              onBlur={(event) => isPw1Valid(event.target.value)}
              error={pw1ErrorText != null}
              helperText={t(pw1ErrorText)}
              autoFocus
              size="small"
            />
            <TextField
              className={classes.inputField}
              color="secondary"
              margin="normal"
              required
              fullWidth
              variant="outlined"
              name="password2"
              label={t("pwChange.password2")}
              type="password"
              id="password2"
              inputProps={{
                min: 0,
                style: {
                  textAlign: "center",
                  fontSize: 16,
                },
              }}
              onBlur={(event) => isPw2Valid(event.target.value)}
              onChange={(event) => isPw2Valid(event.target.value)}
              error={pw2ErrorText != null}
              helperText={t(pw2ErrorText)}
              size="small"
            />
            <Button color="secondary" type="submit" fullWidth variant="contained" sx={{ color: "gulyagold.main" }}>
              {t("pwChange.pwChange")}
            </Button>
            <Grid container justifyContent="center">
              <Copyright sx={{ mt: 0, mb: 4 }} />
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
