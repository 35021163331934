import * as React from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";


const useStyles = makeStyles((theme) => ({
  listItemText: {
    color: theme.palette.text.primary,
    verticalAlign: "middle",
    display: "inline-flex",
  },
  listItemPassiveText: {
    color: theme.palette.text.disabled,
    verticalAlign: "middle",
    display: "inline-flex",
  },
}));

export default function SingleRowDetailIconColorStripe(props) {
  const classes = useStyles();

  return (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          sx={{ backgroundColor: "grey.A400", border:1, borderColor:"grey.A200"}}
          key={Math.floor(Math.random() * 100000)}
        >
          <Grid item xs={2} sm={1}>
            {React.cloneElement(props.children, {
              style: { width: 56, height: 56, fontSize: 56 },
            })}
          </Grid>
          <Grid container item xs={8} sm={5} alignItems="left" sx={{mt:1}}>
            <Typography
            component={"span"}
              className={
                props.isPassive
                  ? classes.listItemPassiveText
                  : classes.listItemText
              }
              variant="h6"
              gutterBottom
            >
              {props.text}
            </Typography>
          </Grid>
          <Grid item xs={0} sm={1}>
          </Grid>
          <Grid item xs={2} sm={5} sx={{mt:2, mb:2, paddingRight:2}}>
            <Box 
              sx={{background:props.stripeColor, height:56}}
            >

            </Box>
          </Grid>
        </Grid>
  );
}
