import * as React from "react";
import { useEffect, useRef, useState } from "react";
// we will use make styles for styling components, you can use another solutions (like css, sass or cssonjs
import { makeStyles } from "@mui/styles";

//https://dev.to/alex1998dmit/how-to-create-map-like-in-airbnb-with-react-and-google-maps-28i3
//https://www.npmjs.com/package/@googlemaps/js-api-loader
//https://github.com/googlemaps/js-api-loader
//https://developers.google.com/maps/documentation/javascript/overview
// map wrapper styles

function Map(props) {
  let { center, zoom, height } = props;
  const ref = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState();

  const useStyles = makeStyles({
    map: {
      height: height,
    },
  });

  const classes = useStyles();

  const onSuccess = (position) => {
    center = { lat: position.coords.latitude, lng: position.coords.longitude };
    console.log("Latitude is :", position.coords.latitude);
    console.log("Longitude is :", position.coords.longitude);
  };
  //lat: 55.753559, lng: 37.609218

  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(onSuccess); //https://reactnative.dev/docs/0.63/geolocation
  }

  useEffect(() => {
    // we need to save google-map object for adding markers and routes in future
    if (ref.current) {
      // here will connect map frame to div element in DOM by using ref hook
      let createdMap = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        disableDefaultUI: true,
        clickableIcons: false,
        mapTypeId: "hybrid", //can be "terrain"
      });
      setMap(createdMap);
    }
  }, [center, zoom]);

  // map will be connect to this div block
  return <div ref={ref} id="map" className={classes.map} />;
}

export default Map;
