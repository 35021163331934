import * as React from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

export default function EditItem(props) {
  const { icon, viewMode } = props;
  const mapProps = (children) => {
    let result = [];

    if (viewMode) {
      let nodeList = [];
      if (Array.isArray(children)) {
        children.forEach((node, index) => {
          nodeList.push(node);
        });
      } else if (children) {
        nodeList.push(children);
      }

      result.push(
        <React.Fragment key={0 + "rf"}>
          <Grid item justifyContent="flex-start" xs={3} sm={2} md={1}>
            {icon}
          </Grid>
          <Grid item align="left" xs={8} sm={9} md={5} sx={{ mt: 0, mb: 0 }}>
            {nodeList}
          </Grid>
          <Grid item xs={1} sm={1} md={6}></Grid>
        </React.Fragment>
      );
    } else {
      if (Array.isArray(children)) {
        children.forEach((node, index) => {
          if (index === 0) {
            result.push(
              <React.Fragment key={index + "rf"}>
                <Grid item xs={3} sm={2} md={1}>
                  {icon}
                </Grid>
                <Grid item align="left" xs={8} sm={9} md={5} sx={{ mt: 0, mb: 0 }}>
                  {node}
                </Grid>
                <Grid item xs={1} sm={1} md={6}></Grid>
              </React.Fragment>
            );
          } else {
            result.push(
              <React.Fragment key={index + "rf"}>
                <Grid item xs={3} sm={2} md={1}></Grid>
                <Grid item align="left" xs={8} sm={9} md={5} sx={{ mt: 0, mb: 0 }}>
                  {node}
                </Grid>
                <Grid item xs={1} sm={1} md={6}></Grid>
              </React.Fragment>
            );
          }
        });
      } else if (children) {
        result.push(
          <React.Fragment key={0 + "rf"}>
            <Grid item xs={3} sm={2} md={1}>
              {icon}
            </Grid>
            <Grid item align="left" xs={8} sm={9} md={5} sx={{ mt: 0, mb: 0 }}>
              {children}
            </Grid>
            <Grid item xs={1} sm={1} md={6}></Grid>
          </React.Fragment>
        );
      }
    }

    return result;
  };
  //{cloneElement(node, { className: classes.textField })}

  return (
    <Box>
      <Grid container alignItems={viewMode?"flex-start":"center"} sx={{ width: "100%", backgroundColor: "grey.A200", height: "100%" }}>
        {mapProps(props.children)}
      </Grid>
    </Box>
  );
}
