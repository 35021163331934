import * as React from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { TextField } from "@mui/material";
import { MenuItem } from "@mui/material";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";

export default function GenericEditModeTable(props) {
  const { dataMap, object, validityMap, createDummyRow, viewMode, isFieldHidden, setModified, validateField, setValidityMap, setObject } =
    props;
  const { t, i18n } = useTranslation(); // eslint-disable-line

  if (!dataMap().table) {
    return "";
  }

  const getLocale = () => {
    let lang = i18n.language || (typeof window !== "undefined" && window.localStorage.i18nextLng) || "DE";
    lang = lang.toUpperCase();
    if (lang === "EN") {
      return "en-US";
    }
    if (lang === "HU") {
      return "hu-HU";
    }
    return "de-CH";
  };

  const getAmountAsText = (row, rindex, column) => {
    let value = (
      (row[column.name]
        ? row[column.name]
        : column.default
        ? column.default
        : column.calculateValue
        ? column.calculateValue(row)
        : object.table[rindex][column.name]) * 1.0
    );

    if (isNaN(value)) {
      return "- CHF";
    }

    return value.toLocaleString(getLocale(), { minimumFractionDigits: 2 }) + " CHF";
  };

  const generateTableHead = (columns) => {
    let cells = [];
    columns.forEach((column, index) => {
      cells.push(
        <TableCell key={"hdr" + index} align="center" sx={{ fontSize: 14, padding: "0px 16px", backgroundColor: "grey.A200" }}>
          {column.validation && column.validation.mandatory ? t(column.label) + "*" : t(column.label)}
        </TableCell>
      );
    });
    return <TableRow key="hdrrow">{cells}</TableRow>;
  };

  const createReadOnlyTableCell = (cells, cindex, rindex, column, row) => {
    if (!column.type) {
      return;
    }
    let align = "center";
    let child;

    if (column.type === "index") {
      child = rindex + 1;
    } else if (column.type === "select") {
      align = "left";
      child = t(row[column.name]);
    } else if (column.type === "number") {
      child = row[column.name];
    } else if (column.type === "amount") {
      align = "right";
      child = getAmountAsText(row, rindex, column);
    } else if (column.type === "percentage") {
      child = row[column.name] * 100 + "%";
    } else {
      return;
    }

    cells.push(
      <TableCell key={"rw" + cindex + rindex} align={align} sx={{ fontSize: 14, padding: "0px 16px", backgroundColor: "grey.A200" }}>
        {child}
      </TableCell>
    );
  };

  const createTableCell = (columns, cells, cindex, rindex, column, row) => {
    if (!column.type) {
      return;
    }
    let opts = {};

    if (column.type === "amount") {
      opts.InputProps = { endAdornment: "CHF", style: { fontSize: 14 } };
    } else if (column.type === "number") {
      opts.InputProps = { style: { fontSize: 14 } };
    } else if (column.type === "select") {
      opts.select = true;
      opts.InputProps = { style: { fontSize: 14 } };
    } else {
      return;
    }

    cells.push(
      <TableCell key={"rw" + cindex + rindex} align="center" sx={{ fontSize: 14, padding: "0px 16px", backgroundColor: "grey.A200" }}>
        <TextField
          required={column.validation.mandatory ? column.validation.mandatory : false}
          name={column.name}
          value={object.table[rindex][column.name]}
          helperText={t(column.helperText)}
          variant="standard"
          onChange={(event) => handleTableChange(event.target.value, column.validation, column.name, rindex, columns)}
          onBlur={(event) => handleTableBlur(event.target.value, column.validation, column.name, rindex, columns)}
          error={validityMap.table[rindex][column.name] == null ? false : !validityMap.table[rindex][column.name]}
          key={column.name}
          fullWidth
          {...opts}
        >
          {column.options
            ? column.options.map((option) => (
                <MenuItem key={option.name} value={option.label}>
                  {t(option.label)}
                </MenuItem>
              ))
            : ""}
        </TextField>
      </TableCell>
    );
  };

  const generateTableBody = (columns, additionalRows) => {
    let result = [];

    if (!object.table) {
      object.table = [];
    }

    if (object.table.length === 0) {
      object.table.push(createDummyRow(columns));
    }

    object.table.forEach((row, rindex) => {
      let cells = [];
      columns.forEach((column, cindex) => {
        if (column.readOnly || viewMode) {
          createReadOnlyTableCell(cells, cindex, rindex, column, row);
        } else {
          createTableCell(columns, cells, cindex, rindex, column, row);
        }
      });
      result.push(<TableRow key={"tbrow" + rindex}>{cells}</TableRow>);
    });
    result.push(<Divider sx={{ borderColor: "grey.A100" }} />);
    if (additionalRows) {
      additionalRows.forEach((arow, arindex) => {
        let cells = [];
        if (arow.highlighted) {
          result.push(<Divider sx={{ borderColor: "grey.A100" }} />);
        }
        arow.columns.forEach((acol, acindex) => {
          if (acol.label) {
            cells.push(
              <TableCell
                key={"arw" + acindex + arindex}
                align="left"
                sx={{
                  fontSize: arow.highlighted ? 16 : 14,
                  fontWeight: arow.highlighted ? "bold" : "normal",
                  padding: "0px 16px",
                  backgroundColor: "grey.A200",
                }}
              >
                {t(acol.label)}
              </TableCell>
            );
          } else if (acol.calculateValue) {
            cells.push(
              <TableCell
                key={"arw" + acindex + arindex}
                align="right"
                sx={{
                  fontSize: arow.highlighted ? 16 : 14,
                  fontWeight: arow.highlighted ? "bold" : "normal",
                  padding: "0px 16px",
                  backgroundColor: "grey.A200",
                }}
              >
                {acol.calculateValue(object.table)}
              </TableCell>
            );
          } else {
            cells.push(
              <TableCell key={"arw" + acindex + arindex} sx={{ backgroundColor: "grey.A200" }}>
                {""}
              </TableCell>
            );
          }
        });
        result.push(<TableRow key={"adrow" + arindex}>{cells}</TableRow>);
      });
    }

    return result;
  };

  const handleTableChange = (targetValue, validationRules, fieldName, index, columns) => {
    setModified(true);
    handleTableBlur(targetValue, validationRules, fieldName, index, columns);
  };

  const handleTableBlur = (targetValue, validationRules, fieldName, index, columns) => {
    let isFieldValid = isFieldHidden(fieldName) || validateField(targetValue, validationRules);
    validityMap.table[index][fieldName] = isFieldValid;
    setValidityMap((prevState) => ({
      ...prevState,
      table: validityMap.table,
    }));

    if (object.table[index][fieldName] === targetValue) {
      return;
    }

    if (object.table[index].isDummy) {
      object.table[index].isDummy = false;
      object.table.push(createDummyRow(columns));
    }
    object.table[index][fieldName] = targetValue;
    setObject((prevState) => ({
      ...prevState,
      table: object.table,
    }));
  };

  return (
    <React.Fragment key={"GEMSTB"}>
      <Box display="flex" flexDirection="row" sx={{ backgroundColor: "grey.A200", width: "100%" }}>
        <Typography sx={{ ml: 2, mt: 1, mb: 1, fontSize: 20, color: "text.primary" }} align="left">
          {t(dataMap().table.label)}
        </Typography>
      </Box>
      <TableContainer size="small">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>{generateTableHead(dataMap().table.columns)}</TableHead>
          <Divider sx={{ borderColor: "grey.A100" }} />
          <TableBody>{generateTableBody(dataMap().table.columns, dataMap().table.additionalRows)}</TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
